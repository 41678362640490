import { createContext, useContext, useState } from "react";

const TempFixContext = createContext();

function TempFixProvider({ children }) {
  const [globalData, setGlobalData] = useState({});

  // useEffect(() => {
  //   console.log("globalData:", globalData);
  // }, [globalData]);

  // This is being used in TypeSearch.js
  return (
    <TempFixContext.Provider value={{ globalData, setGlobalData }}>
      {children}
    </TempFixContext.Provider>
  );
}

function useTempFix() {
  const context = useContext(TempFixContext);
  if (context === undefined)
    throw new Error("useTempFix context was used outside of TempFixProvider");
  return context;
}

export { TempFixProvider, useTempFix };
