import API_ENV from "@config";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Hooks from "../../Hooks";

export default function Dropdown({
  id,
  type,
  render,
  firstValue,
  onChange,
  url,
}) {
  const [options, changeOptions] = useState([{ value: "", label: "No value" }]);
  const [containerData, changeContainerData] = useState(0);
  const [dropDownContents, updateContents] = useState();
  const [containerText, updateContainerText] = useState();

  useEffect(() => {
    if (id.includes("contract")) {
      if (document.querySelector("#propertyInput")) {
        const callback = function () {
          const propertyId = document
            .querySelector("#propertyInput")
            .getAttribute("data");
          function getFunction(data) {
            if (data && data.length) {
              let array = [];
              data.forEach((contract) => {
                array.push({ value: contract.id, label: contract.name });
              });
              changeOptions(array);
            } else {
              changeOptions([
                { value: 0, label: "No contracts for this property" },
              ]);
            }
          }
          Hooks.getData(
            `${API_ENV}/contract/properties/${propertyId}?show_expired_contract=true`,
            getFunction
          );
        };

        const observer = new MutationObserver(callback);
        observer.observe(document.querySelector("#propertyInput"), {
          attributes: true,
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (
      [
        "mot",
        "isvan",
        "compulsory_flag",
        "primary",
        "chargeable",
        "completeflag",
        "allowedtoread",
        "allowedtoupdate",
        "allowedtocreate",
        "allowedtodelete",
        "smsupdate",
        "hassubjobs",
      ].includes(type)
    ) {
      let array = [];

      const choices = [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ];

      if (
        firstValue &&
        firstValue.value &&
        typeof firstValue.value === "boolean"
      ) {
        firstValue.value = firstValue.value.toString();
      }

      if (firstValue) {
        switch (firstValue.value) {
          case "Y":
          case "y":
          case "1":
          case 1:
          case "true":
          case true:
            if (type === "mot") {
              array.push({ value: "y", label: "Yes" });
            } else {
              array.push({ value: "true", label: "Yes" });
            }
            break;
          case "N":
          case "n":
          case "0":
          case "false":
          case false:
          case undefined:
          case 0:
          case null:
            if (type === "mot") {
              array.push({ value: "n", label: "No" });
            } else {
              array.push({ value: "false", label: "No" });
            }
            break;
          default:
            break;
        }
      }

      choices.forEach((item) => {
        if (array[0] && array[0].label) {
          if (item.label !== array[0].label) {
            array.push(item);
          }
        } else {
          array.push(item);
        }
      });

      changeOptions(array);
    } else if (type === "scheduler") {
      let array = [];

      const choices = [
        { value: "M", label: "Month(s)" },
        { value: "Y", label: "Year(s)" },
        { value: "W", label: "Week(s)" },
        { value: "D", label: "Day(s)" },
      ];

      if (firstValue && firstValue.length) {
        switch (firstValue.toLowerCase()) {
          case "y":
            array.push({ value: "Y", label: "Year(s)" });
            break;
          case "m":
            array.push({ value: "M", label: "Month(s)" });
            break;
          case "w":
            array.push({ value: "W", label: "Week(s)" });
            break;
          case "d":
            array.push({ value: "D", label: "Day(s)" });
            break;
          default:
            break;
        }
      }

      choices.forEach((item) => {
        if (array[0] && array[0].label) {
          if (item.label !== array[0].label) {
            array.push(item);
          }
        } else {
          array.push(item);
        }
      });

      changeOptions(array);
    } else if (type === "invoiced") {
      if (firstValue === false) {
        changeOptions([
          { value: false, label: "No" },
          { value: true, label: "Yes" },
        ]);
      } else {
        changeOptions([
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]);
      }
    } else if (type === "priority") {
      let array = [];
      const choices = [
        { value: 1, label: "Low" },
        { value: 2, label: "Medium" },
        { value: 3, label: "High" },
      ];

      switch (firstValue) {
        case 0:
          array.push({ value: 1, label: "Low" });
          break;
        case 1:
          array.push({ value: 2, label: "Medium" });
          break;
        case 2:
          array.push({ value: 3, label: "High" });
          break;
        default:
          break;
      }

      choices.forEach((item) => {
        if (array[0] && array[0].label) {
          if (item.label !== array[0].label) {
            array.push(item);
          }
        } else {
          array.push(item);
        }
      });
      changeOptions(array);
    } else {
      let id = 0,
        clientId = 0,
        contractId = 0;

      try {
        id = Hooks.getSidePanelData().id;
      } catch (e) {}

      try {
        clientId = Hooks.getSidePanelData().clientRefId;
      } catch (e) {}

      try {
        contractId = Hooks.getSidePanelData().contractId;
      } catch (e) {}

      // console.log("id: ", id);

      const types = {
        engineer: `${API_ENV}/staff`,
        defaultEngineer: `${API_ENV}/staff`,
        contractengineer: `${API_ENV}/contractsAndEngineers?contractIds=${contractId}`,
        contract: `${API_ENV}/contracts`,
        jobcontract: `${API_ENV}/contracts?ClientId=${clientId}`,
        jobstate: `${API_ENV}/jobState`,
        jobtype: `${API_ENV}/jobType`,
        jobtypeschedule: `${API_ENV}/contractsAndJobType?contractIds=${id}&showarchive=false`,
        client: `${API_ENV}/clients`,
        equipmenttype: `${API_ENV}/equipmentType/property/${id}`,
        visitstate: `${API_ENV}/visitState`,
        stafftype: `${API_ENV}/staffType`,
        equipmentcategory: `${API_ENV}/equipmentCategory`,
        addequipmenttype: `${API_ENV}/equipmentType/property/${id}`,
        cancelreason: `${API_ENV}/cancelReason`,
        cancelreasonjob: `${API_ENV}/cancelReason/contract/${contractId}`,
        qcstatus: `${API_ENV}/qcStatus`,
        warehouse: `${API_ENV}/warehouses`,
        parts: `${API_ENV}/parts`,
        answertype: `${API_ENV}/answerType`,
        settype: `${API_ENV}/inspectionType`,
        contracttoproperty: `${API_ENV}/contract/client/${clientId}`,
        inspectionset: `${API_ENV}/inspectionSet/ForAddOnly`,
        equipmentsubcategory: `${API_ENV}/EquipmentSubCategory`,
        jobcategory: `${API_ENV}/jobCategory`,
        nextvisitstate: `${API_ENV}/visitState`,
        currentvisitstate: `${API_ENV}/visitState`,
        partstate: `${API_ENV}/jobPartState`,
        targetwarehouse: `${API_ENV}/warehouses?isVan=false`,
        jobpartstate: `${API_ENV}/jobPartState`,
        fittedfromvan: `${API_ENV}/warehouses?isVan=true`,
        allocatedby: `${API_ENV}/staff`,
        apigroup: `${API_ENV}/ApiGroup`,
        role: `${API_ENV}/role`,
      };

      let fetchUrl;
      if (url) {
        fetchUrl = url;
      } else if (type === "nextjobstate" || type === "currentjobstate") {
        fetchUrl = types.jobstate;
      } else if (type === "cancelreasonjob") {
        // console.log("contractId", contractId);
        if (!contractId) {
          return;
        } else {
          fetchUrl = types[type];
        }
      } else {
        fetchUrl = types[type];
      }

      if (fetchUrl) {
        function getFunction(data) {
          updateContents(data);

          if (data && data.length) {
            function getObject(type, entry) {
              switch (type) {
                case "contractengineer":
                  return {
                    value: entry.engineer.id,
                    label:
                      entry.engineer.forename + " " + entry.engineer.surname,
                  };
                case "engineer":
                case "allocatedby":
                  return {
                    value: entry.id,
                    label: entry.forename + " " + entry.surname,
                  };
                case "visitstate":
                case "jobstate":
                case "jobtype":
                case "jobtypeschedule":
                case "stafftype":
                case "qcstatus":
                case "nextjobstate":
                case "currentjobstate":
                case "jobcategory":
                case "currentvisitstate":
                case "nextvisitstate":
                  return { value: entry.id, label: entry.description };
                case "equipmenttype":
                case "addequipmenttype":
                  return { value: entry.id, label: entry.model };
                case "client":
                case "equipmentcategory":
                case "contract":
                case "jobcontract":
                case "warehouse":
                case "settype":
                case "contracttoproperty":
                case "equipmentsubcategory":
                case "partstate":
                case "targetwarehouse":
                case "jobpartstate":
                case "fittedfromvan":
                case "role":
                  return { value: entry.id, label: entry.name };
                case "cancelreason":
                case "cancelreasonjob":
                  return { value: entry.id, label: entry.reason };
                case "parts":
                  return { value: entry.id, label: entry.partno };
                case "answertype":
                  return { value: entry.id, label: entry.desc };
                case "inspectionset":
                  return { value: entry.id, label: entry.longDisplayName };
                case "apigroup":
                  return { value: entry.id, label: entry.groupName };
                default:
                  break;
              }
            }

            let array = [];

            if (firstValue && typeof firstValue === "object") {
              array.push(firstValue);
            } else {
              array.push({ value: 0, label: "No Value" });
            }

            if (Array.isArray(data)) {
              data.forEach((entry) => {
                if (
                  firstValue &&
                  getObject(type, entry).label !== firstValue.label
                ) {
                  if (type === "scheduler") {
                  } else {
                    array.push(getObject(type, entry));
                  }
                } else if (!firstValue) {
                  array.push(getObject(type, entry));
                }
              });
            }

            changeOptions(array);
          } else {
            if (firstValue) {
              changeOptions([firstValue]);
            } else {
              changeOptions([{ value: 0, label: "No Value" }]);
            }
          }
        }
        // console.log("Dropdown.js - fetchUrl: ", fetchUrl);
        Hooks.getData(fetchUrl, getFunction);
      }
    }
  }, [type, render, firstValue, url]);

  const [selectedValue, changeValue] = useState(options[0]);

  useEffect(() => {
    if (options && options.length) {
      changeValue(options[0]);
      changeContainerData(options[0].value || 0);
    }
  }, [options]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
    }),
    control: () => ({
      backgroundColor: "white",
      height: "25px",
      width: "95%",
      marginLeft: "7px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      border: "0px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  if (id) {
    id = id.replace("?", "");
  }

  function changeMakeModel(value) {
    if (type === "equipmenttype") {
      dropDownContents.forEach((item) => {
        if (value.label === item.model) {
          document.querySelector("#makeInput").textContent = item.make;
          document.querySelector("#modelInput").textContent = item.model;
        }
      });
    }
  }

  return (
    <div
      id={`${id}Container`}
      className="sidePanelSelectContainer"
      data={containerData.toString()}
      text={containerText}
    >
      <Select
        id={id}
        styles={customStyles}
        className="sidePanelSelect"
        options={options}
        value={selectedValue}
        onChange={(data) => {
          changeMakeModel(data);
          changeValue(data);
          changeContainerData(data.value);
          updateContainerText(data.label);

          if (onChange) {
            type === "settype"
              ? onChange(Hooks.conditionalString(data.label))
              : onChange();
          }
        }}
      />
    </div>
  );
}
