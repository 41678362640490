import API_ENV from "@config";
import React, { useEffect, useRef, useState } from "react";
import Hooks from "../../../Hooks";
import Swal from "sweetalert2";

import SearchField from "../PurchaseRequests/SearchField";
import NewDropdown from "../../generic/NewDropdown";
import DateTimePicker from "../../generic/DateTimePicker";
import { HiChevronDoubleDown } from "react-icons/hi2";

export default function OrderDetailsitem({ type, data, searchClicked }) {
  const [formContent, updateContent] = useState();
  const [title, updateTitle] = useState();
  const [locationUrl, updateStockLocations] = useState(
    `${API_ENV}/stockLocation?WarehouseID=${data?.destinationWarehouseId}`
  );
  const [footerContent, updateFooter] = useState();

  const content = useRef(null);
  const footer = useRef(null);
  const container = useRef(null);

  const checkbox = useRef(null);
  const warehouse = useRef(null);
  const stockLocationT = useRef(null);
  const orderQuantity = useRef(null);
  const partSearch = useRef(null);
  const jobSearch = useRef(null);
  const deliveryDate = useRef(null);
  const description = useRef(null);
  const unitPrice = useRef(null);

  function warehouseChanged(warehouse) {
    updateStockLocations(
      `${API_ENV}/stockLocation?WarehouseID=${warehouse?.id}`
    );
  }

  function poDetailPriceClick(type) {
    const partId = document
      .querySelector("#partsSearch")
      .getAttribute("dataid");
    const supplierId = data?.purchaseOrder?.supplierRefId;
    let url =
      `${API_ENV}/partsAndSupplier/FindCostQtybyPartSupplier/`;

    if (type === "last") {
      url = `${API_ENV}/poDetail/GetLatestPoPrice/`;
    }

    if (!partId) {
      return;
    }

    Hooks.getData(`${url}${partId}/${supplierId}`, (data) => {
      if (typeof data === "object") {
        const unitPrice = data?.unitPrice || data?.unitCost;
        if (!unitPrice) {
          return;
        }

        document.querySelector(".unitPriceInput").value = unitPrice;
      } else {
        document.querySelector(".unitPriceInput").value = "-";
      }
    });
  }

  useEffect(() => {
    function row(key, value) {
      if (!key) {
        return <></>;
      }

      let input,
        fullWidth = "";
      switch (key) {
        case "Warehouse":
          input = (
            <input
              type="text"
              value={data?.destinationWarehouse?.name || ""}
              className="readOnly"
              disabled
            />
          );
          break;
        case "Override":
          input = <input type="checkbox" />;
          break;
        case "Unit Price":
          input = (
            <div className="unitPriceRow">
              <input ref={unitPrice} className="unitPriceInput" type="text" />
              <div
                className="poDetailButton"
                onClick={() => {
                  poDetailPriceClick("last");
                }}
              >
                Last PO
              </div>
              <div
                className="poDetailButton"
                onClick={() => {
                  poDetailPriceClick("default");
                }}
              >
                Default
              </div>
            </div>
          );
          break;
        case "Part Number":
          input = (
            <SearchField
              ref={partSearch}
              id="partsSearch"
              searchClicked={(e) => {
                searchClicked(partSearch.current, "parts", container.current);
              }}
              defaultText={data?.parts.partno || "Search for part"}
              dataId={data?.parts?.id}
            />
          );
          break;
        case "State Message":
          fullWidth = "fullWidth";
          input = (
            <input
              type="text"
              value={data?.checkValidMessage}
              className="readOnly"
              disabled
            />
          );
          break;
        case "Overriden Validation":
          input = (
            <div className="checkboxInput">
              <input
                type="checkbox"
                ref={checkbox}
                defaultChecked={data?.checkValidFlag}
              />
            </div>
          );
          break;
        case "Order Quantity":
        case "Recieved Quantity":
          input = (
            <input
              ref={orderQuantity}
              type="number"
              defaultValue={value}
              min={1}
            />
          );
          break;
        case "Default Received Warehouse":
          input = (
            <NewDropdown
              ref={warehouse}
              type="preferredwarehouse"
              topValue={{ value: value?.id, label: value?.name }}
              onChange={(e) => {
                warehouseChanged(e.target);
              }}
            />
          );
          break;
        case "Received Stock Location":
          input = (
            <NewDropdown
              ref={stockLocationT}
              type="stocklocation"
              topValue={{
                value: data?.destinationStockLocationId,
                label: value,
              }}
              customUrl={locationUrl}
            />
          );
          break;
        case "Target Job":
          input = (
            <SearchField
              ref={jobSearch}
              id="targetJobSearch"
              placeholder="Target Job"
              defaultText={value}
              searchClicked={(e) => {
                searchClicked(
                  e.target.parentElement.querySelector("input"),
                  "targetjob",
                  container.current
                );
              }}
            />
          );
          break;
        case "Total Cost":
          input = (
            <input type="text" value={value} className="readOnly" disabled />
          );
          break;
        case "Delivery Date":
          input = <DateTimePicker ref={deliveryDate} value={value} />;
          break;
        case "Target Date":
          input = <input type="date" />;
          break;
        case "Description":
          input = <input ref={description} type="text" defaultValue={value} />;
          break;
        default:
          input = (
            <input type="text" value={value} className="readOnly" disabled />
          );
          break;
      }

      return (
        <div
          className={`viewItem ${fullWidth}`}
          key={`${type}_${key}_${value}`}
        >
          <div className="viewItemKey">{key}</div>
          <div className={`viewItemInput ${Hooks.conditionalString(key)}Input`}>
            {input}
          </div>
        </div>
      );
    }
    const slObject = data?.destinationStockLocation;

    const stockLocation = `${slObject?.aisle} ${slObject?.level} ${slObject?.rack}`;
    if (type === "delivery") {
      updateTitle(
        `Purchase #${data?.purchaseOrder?.id} - Line ${data?.detailNo} - ${data?.parts?.partno} x ${data?.quantityMatched}`
      );
      updateContent(
        <div className="poDetailsView" ref={content}>
          {row("Default Received Warehouse", data?.destinationWarehouse)}
          {row("Received Stock Location", stockLocation)}
          {row("PR ID", data?.purchaseOrder?.id)}
          {row("Job ID", data?.poDetail?.targetJobId || "Not found")}
          {row("DN Detail State", data?.dnDetailState?.name)}
          {row("Description", data?.desc)}
          {row("Override", "")}
          {row("Import Message", data?.checkValidMessage)}
        </div>
      );
      updateFooter(
        <div className="poDetailsFooter" ref={footer}>
          <div className="updatePr" onClick={updateDelivery}>
            Apply Changes
          </div>
          <div className="remove" onClick={removePr}>
            Remove from Delivery
          </div>
        </div>
      );
    } else {
      const purchaseRequest = data?.purchaseRequestId
        ? ` Purchase Request #${data?.purchaseRequestId} - `
        : " ";
      updateTitle(
        `${data?.lineNo})${purchaseRequest}${data?.parts?.partno} x${data?.orderQuantity}`
      );

      updateContent(
        <div className="poDetailsView" ref={content}>
          {row("Part Number", data?.parts?.partno)}
          {row("Unit Price", data?.unitPrice)}
          {row(
            "Total Cost",
            parseInt(data?.unitPrice) * parseInt(data?.orderQuantity)
          )}
          {row("State", data?.poDetailState?.name)}
          {row("State Message", data?.checkValidMessage)}
          {row("Overriden Validation", data?.checkValidFlag)}
          {row("Purchase Req Id", data?.purchaseRequestId)}
          {row(
            "Raised By",
            data?.purchaseOrder?.raisedByStaff?.forename +
              " " +
              data?.purchaseOrder?.raisedByStaff?.surname
          )}
          {row("Order Quantity", data?.orderQuantity)}
          {row("Warehouse", data?.destinationWarehouse)}
          {row("Stock Location", stockLocation)}
          {row("Target Job", data?.targetJobId)}
          {row("Delivery Date", data?.deliveryDate)}
        </div>
      );
      updateFooter(
        <div className="poDetailsFooter" ref={footer}>
          <div className="updatePr" onClick={updatePr}>
            Apply Changes
          </div>
          <div className="remove" onClick={removePr}>
            Remove from PO
          </div>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data, searchClicked, locationUrl]);

  function updateDelivery() {
    const id = data?.id;
    if (!id) {
      return;
    }

    Swal.fire({
      title: "Modify Delivery Detail?",
      text: "Are you sure that you wish to modify this Delivery Detail?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data);
        const url = `${API_ENV}/dnDetail/${data?.id}`;
        Hooks.getData(url, (dnDetail) => {
          if (!dnDetail || !container.current) {
            return;
          }

          dnDetail.destinationStockLocationId =
            stockLocationT.current.getAttribute("data") || 0;
          dnDetail.destinationWarehouseId =
            warehouse.current.getAttribute("data") || 0;
          dnDetail.desc = description.current.value;
          dnDetail.checkValidFlag = container.current.querySelector(
            ".overrideInput input"
          ).checked;
          Hooks.sendData(dnDetail, url, "PUT");
        });
      }
    });
  }

  function updatePr() {
    Swal.fire({
      title: "Modify Purchase Order Detail?",
      text: "Are you sure that you wish to modify this Purchase Order Detail?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${API_ENV}/poDetail/${data?.id}`;
        Hooks.getData(url, (poDetail) => {
          if (!poDetail || !container.current) {
            return;
          }

          poDetail.checkValidFlag = checkbox.current.checked;
          poDetail.deliveryDate = new Date(
            document.querySelector(".deliverydateInput input").value
          ).toISOString();
          poDetail.partsRefId = partSearch.current.getAttribute("dataid") || 0;
          poDetail.orderQuantity = orderQuantity.current.value || 0;
          poDetail.destinationWarehouseId =
            warehouse.current.getAttribute("data") || 0;
          poDetail.targetJobId =
            jobSearch.current.value ||
            jobSearch.current.getAttribute("placeholder") ||
            0;
          poDetail.destinationStockLocationId =
            stockLocationT.current.getAttribute("data") || 0;

          // Validate inputs
          if (!poDetail?.targetJobId) {
            Hooks.displayError("Please select a job");
            return;
          }

          Hooks.sendData(poDetail, url, "PUT");
        });
      }
    });
  }

  function removePr() {
    const popupText = {
      delivery: {
        title: "Remove Delivery Detail?",
        text: "Are you sure that you wish to remove this Detail from this Delivery?",
        url: `${API_ENV}/dnDetail/${data?.id}`,
      },
      purchaseorder: {
        title: "Remove Purchase Request?",
        text: "Are you sure that you wish to remove this Purchase Request from this Purchase Order?",
        url: `${API_ENV}/poDetail/${data?.id}`,
      },
    };

    Swal.fire({
      title: popupText[type]?.title,
      text: popupText[type]?.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!data || !container.current) {
          return;
        }

        Hooks.sendData("", popupText[type]?.url, "DELETE", (response) => {
          if (response.ok) {
            container.current.remove();
          }
        });
      }
    });
  }

  return (
    <div className="poDetailsItem" ref={container}>
      <div className="poDetailsHeader">
        <div>{title}</div>
        <HiChevronDoubleDown />
      </div>

      {formContent}
      {footerContent}
    </div>
  );
}
