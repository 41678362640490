import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import DropdownListOldNonRHF from "@ui/DropdownListOldNonRHF";

const initialFormValues = {
  partsCategory: "",
  partsSubCategory1: "",
  partsSubCategory2: "",
  partsSubCategory3: "",
  partsSubCategory4: "",
  depedencies: {
    depedency1: null,
    depedency2: null,
  },
};

const initialDropdownValues = {
  partsCategoryDD: [],
  partsSubCategory1DD: [],
  partsSubCategory2DD: [],
  partsSubCategory3DD: [],
  partsSubCategory4DD: [],
};

export default function AddPartSubCategory({
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [formData, setFormData] = useState(initialFormValues);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);

  // UseEffect Hooks
  // Dropdown Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/partsCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        partsCategoryDD: data,
      }));
    });
    Hooks.getData(
      `${API_ENV}/partsSubCategory1`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory1DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory2`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory2DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory3`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory3DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory4`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory4DD: data,
        }));
      }
    );
  }, []);

  // HANDLE Functions
  const handleButton1 = useCallback(async () => {
    let dataToSend = {};

    function thenFunction() {
      refreshTable();
      cleanURL();
    }

    Hooks.sendData(
      dataToSend,
      `${API_ENV}/`,
      "PUT",
      thenFunction,
      (data) => {
        // console.log(data);
      }
    );
  }, [refreshTable, cleanURL]);

  const handleButton2 = useCallback(async () => {}, []);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Button1", click: handleButton1 },
      { text: "Button2", click: handleButton2 },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, closeFunction, handleButton1, handleButton2]);

  // function handleModalClose() {
  //   setPopupContent();
  // }

  return (
    <>
      <div className="formContainer">
        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Category"}
            dropdownData={dropdownData.partsCategoryDD}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        <div className="formRow"></div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 1"}
            dropdownData={dropdownData.partsSubCategory1DD}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        <div className="formRow">
          <button className="formRowButton">Update</button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 2"}
            dropdownData={dropdownData.partsSubCategory2DD}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        <div className="formRow">
          <button className="formRowButton">Update</button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 3"}
            dropdownData={dropdownData.partsSubCategory3DD}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        <div className="formRow">
          <button className="formRowButton">Update</button>
        </div>

        <div className="formRow">
          <DropdownListOldNonRHF
            label={"Parts Sub Category 4"}
            dropdownData={dropdownData.partsSubCategory4DD}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        <div className="formRow">
          <button className="formRowButton">Update</button>
        </div>
      </div>
    </>
  );
}
