import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import InspectionQuestions from "features/forms/Inspections/InspectionSets/InspectionQuestions";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    name: yup.string().required("This field is required"),
    compulsory_flag: yup.bool().required("This field is required"),
    inspectionTypeRefId: yup.string().required("This field is required"),
    equipmentCategoryRefId: yup.string().when("inspectionTypeRefId", {
      is: "3",
      then: () => yup.string().required("This field is required"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

export default function ViewInspectionSet({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  addInspectionQuestion,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      compulsory_flag: "",
      inspectionTypeRefId: "",
      equipmentCategoryRefId: "",
    },
  });

  const inspectionTypeRefId = watch("inspectionTypeRefId");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  useEffect(() => {
    console.log("data:", stateData);
    // console.log("dropdownData:", dropdownData);
  }, [stateData]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) =>
      console.log("Form Values: ", values)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/inspectionType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
    Hooks.getData(`${API_ENV}/equipmentCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
  }, []);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        name: stateData.name || "",
        compulsory_flag: stateData.compulsory_flag,
        inspectionTypeRefId: stateData.inspectionTypeRefId || "",
        equipmentCategoryRefId: stateData.equipmentCategoryRefId || 0,
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      console.log("dataSubmit", data);
      // console.log("apiUrl", apiUrl);
      Hooks.sendData(
        data,
        `${API_ENV}/inspectionSet/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL]
  );

  // needs to have SidePanel.js > addInspectionQuestion function moved in here at some point
  const handleAddQuestion = useCallback(
    async (data) => {
      addInspectionQuestion();
    },
    [addInspectionQuestion]
  );

  const handleUpdateJob = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      console.log("handleUpdateJob Data: ", data);

      Hooks.sendData(
        data,
        `${API_ENV}/inspectionSet/ChangeForInspectionSet/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Add Question", click: handleAddQuestion },
      { text: "Update To Current Job", click: handleSubmit(handleUpdateJob) },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleAddQuestion,
    handleUpdateJob,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Compulsory"}
            name="compulsory_flag"
            control={control}
            errors={errors}
            dropdownData={[
              { id: true, name: "Yes" },
              { id: false, name: "No" },
            ]}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Set Type"}
            name="inspectionTypeRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>

        {inspectionTypeRefId == "3" && (
          <SidePanelFormRow>
            <DropdownList
              label={"Equipment Category"}
              name="equipmentCategoryRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
            />
          </SidePanelFormRow>
        )}

        <InspectionQuestions questions={stateData.inspectionQuestion} />
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
