import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import Hooks from "../../../../Hooks";
import { HiMiniMinus, HiMiniPlus } from "react-icons/hi2";

const initialFormValues = {
  partsCategoryId: "",
  partsSubCategory1Id: "",
  partsSubCategory2Id: "",
  partsSubCategory3Id: "",
  partsSubCategory4Id: "",
};

const initialDropdownValues = {
  mainCategories: [],
  subList1: [],
  subList2: [],
  subList3: [],
  subList4: [],
  partsList: [],
};

export default function ContractPartListStandalone({
  parentSetFormData,
  parentFormData,
  partsViaSearch,
}) {
  const [formData, setFormData] = useState(initialFormValues);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);
  const [isLoading, setIsLoading] = useState({
    mainCategories: false,
    subList1: false,
    subList2: false,
    subList3: false,
    subList4: false,
    partsList: false,
  });
  const [lastClickedId, setLastClickedId] = useState({
    partsCategoryId: "",
    partsSubCategory1Id: "",
    partsSubCategory2Id: "",
    partsSubCategory3Id: "",
    partsSubCategory4Id: "",
  });

  // useEffect(() => {
  //   console.log("formData:", formData);
  //   console.log("dropdownData:", dropdownData);
  // }, [formData, dropdownData]);

  const fetchData = useCallback(
    (url, updateField) => {
      // console.log("updateField", updateField);
      // Set only the relevant list to loading
      setIsLoading((prevLoading) => ({ ...prevLoading, [updateField]: true }));
      try {
        Hooks.getData(url, (data) => {
          // console.log("Data:", data);

          setDropdownData((prevData) => ({
            ...prevData,
            [updateField]: data,
          }));

          // Set the loading state back to false once the data is fetched
          setIsLoading((prevLoading) => ({
            ...prevLoading,
            [updateField]: false,
          }));
        });
      } catch (error) {
        console.error(`Error fetching ${updateField}:`, error);
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [updateField]: false,
        }));
      }
    },
    [setDropdownData]
  );

  useEffect(() => {
    if (partsViaSearch) {
      setDropdownData((prevData) => ({
        ...prevData,
        partsList: partsViaSearch,
      }));
    }
  }, [partsViaSearch]);

  // UseEffect Hooks
  useEffect(() => {
    fetchData(
      `${API_ENV}/parts?PartsCategoryID=1`,
      "partsList"
    );
  }, [fetchData]);

  useEffect(() => {
    fetchData(
      `${API_ENV}/partsCategory`,
      "mainCategories"
    );
  }, [fetchData]);

  useEffect(() => {
    if (formData.partsCategoryId) {
      // Generate next list
      fetchData(
        `${API_ENV}/partsSubCategory1?PartsCategoryID=${formData.partsCategoryId}`,
        "subList1"
      );
      // Find parts from this category ID
      fetchData(
        `${API_ENV}/parts?PartsCategoryID=${formData.partsCategoryId}`,
        "partsList"
      );
    }
  }, [formData.partsCategoryId, fetchData]);

  useEffect(() => {
    if (formData.partsSubCategory1Id) {
      fetchData(
        `${API_ENV}/partsSubCategory2?PartsSubCategory1ID=${formData.partsSubCategory1Id}`,
        "subList2"
      );
      fetchData(
        `${API_ENV}/parts?PartsSubCategory1ID=${formData.partsSubCategory1Id}`,
        "partsList"
      );
    }
  }, [formData.partsSubCategory1Id, fetchData]);

  useEffect(() => {
    if (formData.partsSubCategory2Id) {
      fetchData(
        `${API_ENV}/partsSubCategory3?PartsSubCategory2ID=${formData.partsSubCategory2Id}`,
        "subList3"
      );
      fetchData(
        `${API_ENV}/parts?PartsSubCategory2ID=${formData.partsSubCategory2Id}`,
        "partsList"
      );
    }
  }, [formData.partsSubCategory2Id, fetchData]);

  useEffect(() => {
    if (formData.partsSubCategory3Id) {
      fetchData(
        `${API_ENV}/partsSubCategory4?PartsSubCategory3ID=${formData.partsSubCategory3Id}`,
        "subList4"
      );
      fetchData(
        `${API_ENV}/parts?PartsSubCategory3ID=${formData.partsSubCategory3Id}`,
        "partsList"
      );
    }
  }, [formData.partsSubCategory3Id, fetchData]);

  useEffect(() => {
    if (formData.partsSubCategory4Id) {
      // Only find parts as SubCat4 is highest level
      fetchData(
        `${API_ENV}/parts?PartsSubCategory3ID=${formData.partsSubCategory4Id}`,
        "partsList"
      );
    }
  }, [formData.partsSubCategory4Id, fetchData]);

  const handleCategoryClick = useCallback(
    (e, id, field) => {
      // Stop handleCategoryClick being called multiple times when child li is clicked
      e.stopPropagation();

      // Find id & set each lists id inside lastClickedId - Need this for parent lists closing & not re-opening when clicked for a second time
      const clickedId = e.target.id;
      const [categoryName, itemId] = clickedId.split("|");
      setLastClickedId((prevLastClickedId) => ({
        ...prevLastClickedId,
        [categoryName]: itemId,
      }));

      // Update formData & clear
      const newFormData = { ...formData, [field]: id };
      Object.keys(initialFormValues).forEach((key) => {
        if (key > field) {
          newFormData[key] = "";
        }
      });
      setFormData(newFormData);

      // Determine the level of the clicked item and resets child lists when parent is clicked
      const levelClicked = Object.keys(initialFormValues).indexOf(field);
      const newDropdownData = { ...dropdownData };
      Object.keys(initialDropdownValues).forEach((key, index) => {
        if (index > levelClicked) {
          newDropdownData[key] = [];
        }
      });
      setDropdownData(newDropdownData);

      // Check to re-fetch data for a parent list as otherwise a second click would close the list & it wouldnt open with subsequent clicks
      if (itemId === lastClickedId[categoryName]) {
        switch (categoryName) {
          case "partsCategoryId":
            fetchData(
              `${API_ENV}/partsSubCategory1?PartsCategoryID=${itemId}`,
              "subList1"
            );
            fetchData(
              `${API_ENV}/parts?PartsCategoryID=${itemId}`,
              "partsList"
            );
            break;
          case "partsSubCategory1Id":
            fetchData(
              `${API_ENV}/partsSubCategory2?PartsSubCategory1ID=${itemId}`,
              "subList2"
            );
            fetchData(
              `${API_ENV}/parts?PartsSubCategory1ID=${itemId}`,
              "partsList"
            );
            break;
          case "partsSubCategory2Id":
            fetchData(
              `${API_ENV}/partsSubCategory3?PartsSubCategory2ID=${itemId}`,
              "subList3"
            );
            fetchData(
              `${API_ENV}/parts?PartsSubCategory2ID=${itemId}`,
              "partsList"
            );
            break;
          case "partsSubCategory3Id":
            fetchData(
              `${API_ENV}/partsSubCategory4?PartsSubCategory3ID=${itemId}`,
              "subList4"
            );
            fetchData(
              `${API_ENV}/parts?PartsSubCategory3ID=${itemId}`,
              "partsList"
            );
            break;
          case "partsSubCategory4Id":
            return;
          default:
            console.error(
              "Something went wrong with handleCategoryClick's API calls"
            );
            console.log("itemId", itemId);
            console.log("categoryName", itemId);
        }
      }
    },
    [
      formData,
      setFormData,
      setDropdownData,
      dropdownData,
      lastClickedId,
      fetchData,
    ]
  );

  function handlePartsClick(item) {
    console.log(item);
    parentSetFormData((prevData) => ({
      ...prevData,
      partId: item.id,
      partNo: item.partno,
      description: item.description,
      mainCategory: item.partsCategory.name,
      subcategory1: item.partsSubCategory1.name,
      subcategory2: item.partsSubCategory2.name,
      subcategory3: item.partsSubCategory3.name,
      subcategory4: item.partsSubCategory4.name,
    }));
  }

  return (
    <div className="partsTreeContainer">
      <div className="partsTreeHeader">
        <div>Part List</div>
      </div>
      <div className="categoryAndPartsContainer">
        <div className="partsCategories">
          <h2 className="title">Categories</h2>

          <ul className="categoryTree">
            {/* mainCategories */}
            {isLoading.mainCategories ? (
              <div className="subList">Loading...</div>
            ) : (
              Array.isArray(dropdownData.mainCategories) &&
              dropdownData.mainCategories.map((mainItem, index) => (
                <li
                  key={mainItem.id}
                  id={`partsCategoryId|${mainItem.id}`}
                  className={`listElement ${
                    mainItem.withLeaf ? "withLeaf" : "noLeaf"
                  } ${
                    mainItem.id === formData.partsCategoryId ? "selected" : ""
                  }`}
                  onClick={(e) =>
                    handleCategoryClick(e, mainItem.id, "partsCategoryId")
                  }
                >
                  <div
                    id={`partsCategoryId|${mainItem.id}`}
                    className="categoryContent"
                  >
                    <div
                      id={`partsCategoryId|${mainItem.id}`}
                      className="subItemName"
                    >
                      {mainItem.name}
                    </div>

                    <div
                      id={`partsCategoryId|${mainItem.id}`}
                      className="icons"
                    >
                      {mainItem.withLeaf &&
                        (mainItem.id === formData.partsCategoryId ? (
                          <div className="minus">
                            <HiMiniMinus
                              id={`partsCategoryId|${mainItem.id}`}
                            />
                          </div>
                        ) : (
                          <div className="plus">
                            <HiMiniPlus id={`partsCategoryId|${mainItem.id}`} />
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* subList1 */}
                  {formData.partsCategoryId === mainItem.id &&
                    (isLoading.subList1 ? (
                      <div className="subList">Loading...</div>
                    ) : (
                      Array.isArray(dropdownData.subList1) &&
                      dropdownData.subList1.map((subItem1, index) => (
                        <ul key={subItem1.id} className="subList">
                          <li
                            id={`partsSubCategory1Id|${subItem1.id}`}
                            className={`listElement ${
                              subItem1.withLeaf ? "withLeaf" : "noLeaf"
                            } ${
                              subItem1.id === formData.partsSubCategory1Id
                                ? "selected"
                                : ""
                            }`}
                            onClick={(e) =>
                              handleCategoryClick(
                                e,
                                subItem1.id,
                                "partsSubCategory1Id"
                              )
                            }
                          >
                            <div
                              id={`partsSubCategory1Id|${subItem1.id}`}
                              className="categoryContent"
                            >
                              <div
                                id={`partsSubCategory1Id|${subItem1.id}`}
                                className="subItemName"
                              >
                                {subItem1.name}
                              </div>

                              <div
                                id={`partsSubCategory1Id|${subItem1.id}`}
                                className="icons"
                              >
                                {subItem1.withLeaf &&
                                  (subItem1.id ===
                                  formData.partsSubCategory1Id ? (
                                    <div className="minus">
                                      <HiMiniMinus
                                        id={`partsSubCategory1Id|${mainItem.id}`}
                                      />
                                    </div>
                                  ) : (
                                    <div className="plus">
                                      <HiMiniPlus
                                        id={`partsSubCategory1Id|${mainItem.id}`}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>

                            {/* subList2 */}
                            {formData.partsSubCategory1Id === subItem1.id &&
                              (isLoading.subList2 ? (
                                <div className="subList">Loading...</div>
                              ) : (
                                Array.isArray(dropdownData.subList2) &&
                                dropdownData.subList2.map((subItem2, index) => (
                                  <ul key={subItem2.id} className="subList">
                                    <li
                                      id={`partsSubCategory2Id|${subItem2.id}`}
                                      className={`listElement ${
                                        subItem2.withLeaf
                                          ? "withLeaf"
                                          : "noLeaf"
                                      } ${
                                        subItem2.id ===
                                        formData.partsSubCategory2Id
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={(e) =>
                                        handleCategoryClick(
                                          e,
                                          subItem2.id,
                                          "partsSubCategory2Id"
                                        )
                                      }
                                    >
                                      <div
                                        id={`partsSubCategory2Id|${subItem2.id}`}
                                        className="categoryContent"
                                      >
                                        <div
                                          id={`partsSubCategory2Id|${subItem2.id}`}
                                          className="subItemName"
                                        >
                                          {subItem2.name}
                                        </div>

                                        <div
                                          id={`partsSubCategory2Id|${subItem2.id}`}
                                          className="icons"
                                        >
                                          {subItem2.withLeaf &&
                                            (subItem2.id ===
                                            formData.partsSubCategory2Id ? (
                                              <div className="minus">
                                                <HiMiniMinus
                                                  id={`partsSubCategory2Id|${mainItem.id}`}
                                                />
                                              </div>
                                            ) : (
                                              <div className="plus">
                                                <HiMiniPlus
                                                  id={`partsSubCategory2Id|${mainItem.id}`}
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </div>

                                      {/* subList3 */}
                                      {formData.partsSubCategory2Id ===
                                        subItem2.id &&
                                        (isLoading.subList3 ? (
                                          <div className="subList">
                                            Loading...
                                          </div>
                                        ) : (
                                          Array.isArray(
                                            dropdownData.subList3
                                          ) &&
                                          dropdownData.subList3.map(
                                            (subItem3, index) => (
                                              <ul
                                                key={subItem3.id}
                                                className="subList"
                                              >
                                                <li
                                                  id={`partsSubCategory3Id|${subItem3.id}`}
                                                  className={`listElement ${
                                                    subItem3.withLeaf
                                                      ? "withLeaf"
                                                      : "noLeaf"
                                                  } ${
                                                    subItem3.id ===
                                                    formData.partsSubCategory3Id
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                  onClick={(e) =>
                                                    handleCategoryClick(
                                                      e,
                                                      subItem3.id,
                                                      "partsSubCategory3Id"
                                                    )
                                                  }
                                                >
                                                  <div
                                                    id={`partsSubCategory3Id|${subItem3.id}`}
                                                    className="categoryContent"
                                                  >
                                                    <div
                                                      id={`partsSubCategory3Id|${subItem3.id}`}
                                                      className="subItemName"
                                                    >
                                                      {subItem3.name}
                                                    </div>

                                                    <div
                                                      id={`partsSubCategory3Id|${subItem3.id}`}
                                                      className="icons"
                                                    >
                                                      {subItem3.withLeaf &&
                                                        (subItem3.id ===
                                                        formData.partsSubCategory3Id ? (
                                                          <div className="minus">
                                                            <HiMiniMinus
                                                              id={`partsSubCategory3Id|${mainItem.id}`}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className="plus">
                                                            <HiMiniPlus
                                                              id={`partsSubCategory3Id|${mainItem.id}`}
                                                            />
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </div>

                                                  {/* subList4 */}
                                                  {formData.partsSubCategory3Id ===
                                                    subItem3.id &&
                                                    (isLoading.subList4 ? (
                                                      <div className="subList">
                                                        Loading...
                                                      </div>
                                                    ) : (
                                                      Array.isArray(
                                                        dropdownData.subList4
                                                      ) &&
                                                      dropdownData.subList4.map(
                                                        (subItem4, index) => (
                                                          <ul
                                                            key={subItem4.id}
                                                            className="subList"
                                                          >
                                                            <li
                                                              id={`partsSubCategory4Id|${subItem4.id}`}
                                                              className={`listElement ${
                                                                subItem4.withLeaf
                                                                  ? "withLeaf"
                                                                  : "noLeaf"
                                                              } ${
                                                                subItem4.id ===
                                                                formData.partsSubCategory4Id
                                                                  ? "selected"
                                                                  : ""
                                                              }`}
                                                              onClick={(e) =>
                                                                handleCategoryClick(
                                                                  e,
                                                                  subItem4.id,
                                                                  "partsSubCategory4Id"
                                                                )
                                                              }
                                                            >
                                                              {subItem4.name}
                                                            </li>
                                                          </ul>
                                                        )
                                                      )
                                                    ))}
                                                </li>
                                              </ul>
                                            )
                                          )
                                        ))}
                                    </li>
                                  </ul>
                                ))
                              ))}
                          </li>
                        </ul>
                      ))
                    ))}
                </li>
              ))
            )}
          </ul>
        </div>

        <div className="partsDisplay">
          <h2 className="title">Parts</h2>

          {Array.isArray(dropdownData.partsList) &&
          dropdownData.partsList.length > 0 ? (
            dropdownData.partsList.map((item, index) => (
              <ul key={index} className="partsList">
                <li
                  className={`listElement  ${
                    parentFormData.partId === item.id ? "selected" : ""
                  }`}
                  onClick={() => handlePartsClick(item)}
                >
                  {item.partno}
                </li>
              </ul>
            ))
          ) : (
            <p className="partsError">
              No Parts found!
              <br />
              Please try another category from the list above.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
