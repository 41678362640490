import "../styles/ui/Navigation.scss";

import { useState } from "react";
import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiOutlineArrowTrendingUp,
  HiOutlineCalendarDays,
  HiOutlineChartPie,
  HiOutlineCheckCircle,
  HiOutlineCircleStack,
  HiOutlineCog6Tooth,
  HiOutlineCurrencyPound,
  HiOutlineTruck,
  HiOutlineTv,
  HiOutlineUser,
  HiOutlineWallet,
} from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import { navData } from "../data/NavData";

// Icon mapping: Add icons in here
const ICONS = {
  HiOutlineChartPie: HiOutlineChartPie,
  HiOutlineCalendarDays: HiOutlineCalendarDays,
  HiOutlineCircleStack: HiOutlineCircleStack,
  HiOutlineTruck: HiOutlineTruck,
  HiOutlineWallet: HiOutlineWallet,
  HiOutlineCurrencyPound: HiOutlineCurrencyPound,
  HiOutlineArrowTrendingUp: HiOutlineArrowTrendingUp,
  HiOutlineCheckCircle: HiOutlineCheckCircle,
  HiOutlineTv: HiOutlineTv,
  HiOutlineUser: HiOutlineUser,
  HiOutlineCog6Tooth: HiOutlineCog6Tooth,
};

const NavGroup = ({ label, icon, iconSize = 25, children, level }) => {
  const [isOpen, setIsOpen] = useState(false);
  const IconComponent = ICONS[icon];
  const style = { paddingLeft: `${level}rem` };

  return (
    <li style={style}>
      <button
        className={`navGroup ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="navGroupIcon">
          {IconComponent && <IconComponent size={iconSize} />}
        </div>

        <span className="navGroupLabel">{label}</span>

        <div className="navGroupChevron">
          {isOpen ? <HiChevronDoubleUp /> : <HiChevronDoubleDown />}
        </div>
      </button>
      {isOpen && <ul className="navListChild">{children}</ul>}
    </li>
  );
};

const NavLinkItem = ({
  urlPath,
  label,
  icon,
  iconSize = 25,
  level,
  onItemClicked,
  isOpen,
}) => {
  const IconComponent = ICONS[icon]; // Dynamically selects the icon component

  // Adjust the style as necessary
  const style = { paddingLeft: `${level}rem` }; // Calculates padding based on level

  return (
    <li
      // className={"navLink"}
      style={style}
      onClick={() => onItemClicked(urlPath)}
    >
      <NavLink
        to={urlPath}
        className={({ isActive }) => (isActive ? "navLink active" : "navLink")}
        // className={({ isActive }) => (isActive ? "active" : "")}
      >
        {IconComponent && (
          <div className="navLinkIcon">
            <IconComponent size={iconSize} />
          </div>
        )}

        <span className="navLinkLabel">{label}</span>
      </NavLink>
    </li>
  );
};

export default function Navigation() {
  const [currentLink, setCurrentLink] = useState(null);

  const handleItemClick = (urlPath) => {
    // Toggle functionality: close if the same item is clicked again
    if (currentLink !== urlPath) {
      setCurrentLink(urlPath);
    }
  };

  const renderLinks = (contents, level = 0) =>
    contents.map((item, index) => {
      if (item.type === "group") {
        return (
          <NavGroup
            label={item.label}
            icon={item.icon}
            iconSize={item.iconSize}
            level={item.level}
            key={index}
          >
            {renderLinks(item.contents, item.level + 1)}
          </NavGroup>
        );
      } else {
        return (
          <NavLinkItem
            key={index}
            urlPath={item.urlPath}
            label={item.label}
            icon={item.icon}
            iconSize={item.iconSize}
            level={item.level}
            isOpen={currentLink === item.urlPath}
            onItemClicked={handleItemClick}
          />
        );
      }
    });

  return (
    <nav className="navContainer">
      <ul className="navList">{renderLinks(navData)}</ul>
    </nav>
  );
}
