import "../styles/ui/CollapsibleGroup.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi2";
import HR from "./HR";

const CollapsibleGroup = ({
  title,
  isOpenStatus = true,
  children,
  lineBreak = true,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenStatus);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="collapsibleGroup">
        <div className="collapsibleGroupTitle" onClick={toggleOpen}>
          {title}{" "}
          <div className="collapsibleGroupChevron">
            {isOpen ? <HiChevronDoubleUp /> : <HiChevronDoubleDown />}
          </div>
        </div>

        {isOpen && <div className="collapsibleGroupContent">{children}</div>}
      </div>

      {lineBreak && <HR />}
    </>
  );
};

CollapsibleGroup.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CollapsibleGroup;
