import "../styles/ui/SidePanelFormRow.scss";

export default function SidePanelFormRow({
  label,
  error,
  children,
  position,
  type = "half",
}) {
  return (
    <div className={`sidePanelFormRow ${type}`}>
      {label && <label htmlFor={children.props.id}>{label}</label>}
      {children && children}
      {error && <span className="sidePanelFormRowError">{error}</span>}
    </div>
  );
}
