import API_ENV from "@config";
import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    docTypeName: yup.string().required("This field is required"),
  })
  .required();

export default function AddObjectDocumentType({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      docTypeName: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log(data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(data, `${API_ENV}/objectDocType`, "POST", thenFunction);
    },
    [refreshTable, cleanURL, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Document Type Name"}
            name="docTypeName"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
