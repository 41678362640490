import API_ENV from "@config";
import React, { useEffect, useRef, useState } from "react";
import Hooks from "../../../../Hooks";
import SelectionList from "./SelectionList";

export default function ChooseWarehouse({ stockLocation, warehouseValue }) {
  const [locations, updateLocations] = useState([]);
  const [warehouses, updateWarehouses] = useState();

  const warehouseDrop = useRef(null);

  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/warehouses?isVan=false`,
      (warehouses) => {
        if (!warehouses || !warehouses.length) {
          return;
        }

        const array = [
          <option value={warehouseValue.value || 0}>
            {warehouseValue.name || "Select Warehouse"}
          </option>,
        ];
        warehouses.forEach((warehouse) => {
          if (warehouse.name !== warehouseValue.name) {
            array.push(<option value={warehouse.id}>{warehouse.name}</option>);
          }
        });
        updateWarehouses(array);
      }
    );

    if (warehouseValue?.value) {
      loadStockLocations(warehouseValue?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseValue]);

  function loadStockLocations(warehouseId) {
    function stockLocationItem(location) {
      let className = "listItem";

      if (stockLocation && stockLocation.id) {
        if (stockLocation.id === location.id) {
          className = "listItem selectedLocation";
        }
      }

      return (
        <div
          className={className}
          key={location?.id}
          dataid={location?.id}
          onClick={(e) => {
            stockClicked(e.target);
          }}
        >
          {`${location?.aisle} | ${location?.level} | ${location?.rack}`}
        </div>
      );
    }

    Hooks.getData(
      `${API_ENV}/stockLocation?WarehouseID=${warehouseId}`,
      (stockLocations) => {
        if (!stockLocations || !stockLocations.length) {
          return <div className="listItem">No locations found.</div>;
        }

        const array = [];
        stockLocations.forEach((location) => {
          array.push(stockLocationItem(location));
        });

        updateLocations(array);
      }
    );
  }

  function stockClicked(target) {
    if (!target && !document.querySelector(".locationItem")) {
      return;
    }

    document.querySelectorAll(".listItem").forEach((item, index) => {
      if (index > 0) {
        item.className = "listItem";
      }
    });

    target.classList.add("selectedLocation");
  }

  function warehouseSelected() {
    const warehouseId = warehouseDrop?.current?.value;
    if (!warehouseId) {
      return;
    }
    loadStockLocations(warehouseId);
  }

  return (
    <div id="chooseWarehouse">
      <div id="warehouseSelect">
        <select onChange={warehouseSelected} ref={warehouseDrop}>
          {warehouses}
        </select>
      </div>

      <div className="arrow">➔</div>

      <SelectionList title="Stock Locations" children={locations} />
    </div>
  );
}
