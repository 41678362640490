import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import ContentList from "../../MultiContentForm/Components/ContentList";
import AddressInput from "../../AddressInput";
import Form from "../../../../ui/Form";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import InputOldNonRHF from "@ui/InputOldNonRHF";

export default function PartsAndSuppliers({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [formData, setFormData] = useState({
    dependency: {},
    name: "",
    partNo: "",
    unitCost: "",
    email: "",
    inventoryQty: "",
  });

  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const { data } = state;

  // useEffect(() => {
  //   // console.log("state:", state);
  //   console.log("data:", data);
  //   console.log("formData:", formData);
  // }, [state, data, formData]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   Hooks.getData(`${API_ENV}/runReportType`, (data) => {
  //     // console.log(data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // Fill form via state
  useEffect(() => {
    if (data[0]) {
      setFormData((prevData) => ({
        ...prevData,
        name: data[0].supplier.name,
        partNo: data[0].parts.partno,
        unitCost: data[0].unitCost,
        email: data[0].supplier.email,
        inventoryQty: data[0].inventoryQty,
        dependency: data[0].supplier,
      }));
    }
  }, [data]);

  // HANDLE Functions
  const handleButton1 = useCallback(async () => {
    let dataToSend = {};

    function thenFunction() {
      refreshTable();
      cleanURL();
    }

    Hooks.sendData(
      dataToSend,
      `${API_ENV}/`,
      "PUT",
      thenFunction,
      (data) => {
        // console.log(data);
      }
    );
  }, [refreshTable, cleanURL]);

  const handleButton2 = useCallback(async () => {}, []);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Button1", click: handleButton1 },
      { text: "Button2", click: handleButton2 },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, closeFunction, handleButton1, handleButton2]);

  // function handleModalClose() {
  //   setPopupContent();
  // }

  // function targetClicked(event) {
  //   const eventData = event.target.getAttribute("data");
  //   if (eventData && eventData.length) {
  //     const sidePanelId = Hooks.getSidePanelData().id;

  //     Hooks.getData(
  //       `${API_ENV}/partsAndSupplier?PartsID=${sidePanelId}`,
  //       (data) => {
  //         data.forEach((item) => {
  //           if (item.id === JSON.parse(eventData).id) {
  //             setFormData((prevData) => ({
  //               ...prevData,
  //               name: item.supplier.name,
  //               partNo: item.parts.partno,
  //               unitCost: item.unitCost,
  //               email: item.supplier.email,
  //               dependency: item,
  //             }));
  //           }
  //         });
  //       }
  //     );
  //   }
  // }

  function targetClicked(event, item) {
    // Directly use the item object, no need to access DOM attributes
    const sidePanelId = Hooks.getSidePanelData().id;

    Hooks.getData(
      `${API_ENV}/partsAndSupplier?PartsID=${sidePanelId}`,
      (data) => {
        // No need to loop through data if you are already passing the correct item object
        // Assuming the item passed is what you need
        setFormData((prevData) => ({
          ...prevData,
          name: item.supplier.name,
          partNo: item.parts.partno,
          unitCost: item.unitCost,
          email: item.supplier.email,
          dependency: item,
        }));
      }
    );
  }

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <div className="contentListContainer">
          <ContentList
            data={data}
            type={"partsupplier"}
            clicked={targetClicked}
          />
        </div>

        {formData.name && (
          <>
            <SidePanelFormRow>
              <InputOldNonRHF
                label={"Supplier Name"}
                type={"text"}
                formData={formData}
                setFormData={setFormData}
                readOnly={true}
                customKeyName={"name"}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <InputOldNonRHF
                label={"Email"}
                type={"text"}
                formData={formData}
                setFormData={setFormData}
                customKeyName={"email"}
                readOnly={true}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <InputOldNonRHF
                label={"Part No"}
                type={"text"}
                formData={formData}
                setFormData={setFormData}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <InputOldNonRHF
                label={"Unit Cost (£)"}
                type={"text"}
                formData={formData}
                setFormData={setFormData}
                customKeyName={"unitCost"}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <InputOldNonRHF
                label={"Inventory Quantity"}
                type={"text"}
                formData={formData}
                setFormData={setFormData}
                customKeyName={"inventoryQty"}
              />
            </SidePanelFormRow>

            <AddressInput
              data={formData?.dependency?.supplier || data[0]?.supplier}
              readOnly={true}
            />
          </>
        )}
      </div>
    </Form>
  );
}
