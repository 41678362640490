import React from "react";

export default function AddressInput({
  fields = [
    { label: "Address 1", name: "address1" },
    { label: "Address 2", name: "address2" },
    { label: "Address 3", name: "address3" },
    { label: "Address 4", name: "address4" },
    { label: "Town", name: "town" },
    { label: "County", name: "county" },
    { label: "Postcode", name: "postCode" },
    { label: "Phone", name: "phone" },
  ],
  register,
  errors,
  readOnly = false,
}) {
  return (
    <div id="addressContainer">
      {fields.map(({ label, name }) => (
        <React.Fragment key={name}>
          <div className="addressEntry">
            <div>{label}</div>
            <div className="addressInputContainer">
              <input
                id={`${name}Input`}
                {...register(name)}
                type="text"
                readOnly={readOnly}
              />
            </div>
          </div>

          {errors[name] && (
            <>
              <div className="addressEntry">
                <span className="fieldError">
                  {label}: {errors[name].message}
                </span>
              </div>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
