import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    unitName: yup.string().required("This field is required"),
    objectDocTypeId: yup.string().required("This field is required"),
    attributeCategoryId: yup.string().required("This field is required"),
    answerTypeRefId: yup.string().required("This field is required"),
  })
  .required();

export default function AddAttributeUnit({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  setFormState,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      unitName: "",
      isAutoLoad: false,
      objectDocTypeId: "",
      attributeCategoryId: "",
      answerTypeRefId: "",
    },
  });
  const objectDocTypeId = watch("objectDocTypeId");

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ObjectDocType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
    if (objectDocTypeId) {
      Hooks.getData(
        `${API_ENV}/attributeCategory?AttributeDocTypeId=${objectDocTypeId}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData2: data,
          }));
        }
      );
    }
    Hooks.getData(`${API_ENV}/answerType?attributeFlag=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData3: data,
      }));
    });
  }, [objectDocTypeId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      // console.log("apiUrl", apiUrl);
      // console.log("modifiedApiUrl", modifiedApiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        "POST",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Unit Name"}
            name="unitName"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Auto Load?"}
            name="isAutoLoad"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Object Document Type"}
            name="objectDocTypeId"
            control={control}
            errors={errors}
            optionName="docTypeName"
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Attribute Category"}
            name="attributeCategoryId"
            control={control}
            errors={errors}
            optionName="catName"
            firstOption="Select Object Document Type"
            dropdownData={dropdownData.dropdownData2}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Answer Type"}
            name="answerTypeRefId"
            control={control}
            errors={errors}
            optionName="desc"
            dropdownData={dropdownData.dropdownData3}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
