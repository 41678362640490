import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    partno: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    partsCategoryId: yup.string().required("This field is required"),
  })
  .required();

export default function AddPart({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
    dropdownData3: [],
    dropdownData4: [],
    dropdownData5: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      partno: "",
      description: "",
      partsCategoryId: "",
      partsSubCategory1Id: "0",
      partsSubCategory2Id: "0",
      partsSubCategory3Id: "0",
      partsSubCategory4Id: "0",
    },
  });
  const partCategory = watch("partCategory");
  const subCategory1 = watch("subCategory1");
  const subCategory2 = watch("subCategory2");
  const subCategory3 = watch("subCategory3");

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/partsCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    if (partCategory) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory1?PartsCategoryID=${partCategory}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData2: data,
          }));
        }
      );
    }

    if (subCategory1) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory2?PartsSubCategory1ID=${subCategory1}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData3: data,
          }));
        }
      );
    }

    if (subCategory2) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory3?PartsSubCategory2ID=${subCategory2}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData4: data,
          }));
        }
      );
    }

    if (subCategory3) {
      Hooks.getData(
        `${API_ENV}/partsSubCategory4?PartsSubCategory3ID=${subCategory3}`,
        (data) => {
          // console.log(data);
          setDropdownData((prevData) => ({
            ...prevData,
            dropdownData5: data,
          }));
        }
      );
    }
  }, [partCategory, subCategory1, subCategory2, subCategory3]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      // console.log("apiUrl", apiUrl);
      // console.log("modifiedApiUrl", modifiedApiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        "POST",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input
            label={"Part Number"}
            name="partno"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Description"}
            name="description"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Part Category"}
            name="partsCategoryId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Subcategory 1"}
            name="partsSubCategory1Id"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            firstOption="None"
            firstOptionValue={0}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Subcategory 2"}
            name="partsSubCategory2Id"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            firstOption="None"
            firstOptionValue={0}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Subcategory 3"}
            name="partsSubCategory3Id"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            firstOption="None"
            firstOptionValue={0}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Subcategory 4"}
            name="partsSubCategory4Id"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            firstOption="None"
            firstOptionValue={0}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
