import API_ENV from "@config";
import React, { useState } from "react";
import Hooks from "../../../Hooks";
import Swal from "sweetalert2";

import Dropdown from "../../generic/Dropdown";

function removeClicked(target) {
  Swal.fire({
    title: "Remove contract?",
    text: "Are you sure you want to remove this contract from the list?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      target.parentElement.remove();
    }
  });
}

function contractRow(data) {
  return (
    <div className="contractRow">
      <div className="contractField">{data.id}</div>
      <div className="contractField">{data.name}</div>
      <div className="contractField">{data.client.name}</div>
      <div
        className="contractField remove"
        onClick={(e) => {
          removeClicked(e.target);
        }}
      >
        Remove
      </div>
    </div>
  );
}

export default function AssignContracts() {
  const [selected, updateSelected] = useState([
    <div className="contractRow">
      <div className="contractField">ID</div>
      <div className="contractField">Name</div>
      <div className="contractField">Client</div>
      <div className="contractField">Remove</div>
    </div>,
  ]);

  function onChange(id) {
    const contractId = document
      .querySelector("#contractInputContainer")
      .getAttribute("data");
    Hooks.getData(
      `${API_ENV}/contracts/${contractId}`,
      (data) => {
        if (data && data.id) {
          let duplicate = false;
          document.querySelectorAll(".contractRow").forEach((row) => {
            if (
              parseInt(row.querySelector(".contractField").textContent) ===
              data.id
            ) {
              duplicate = true;
            }
          });

          if (duplicate) {
            Hooks.displayError("This contract is already in the list.");
          } else {
            updateSelected(selected.concat(contractRow(data)));
          }
        }
      }
    );
  }

  return (
    <div id="assignContracts">
      <div id="addContract">
        <Dropdown
          id="contractInput"
          className="sidePanelSelect"
          type="contract"
        />
        <div id="addButton" onClick={onChange}>
          Add
        </div>
      </div>
      <div id="selectedContracts">{selected}</div>
    </div>
  );
}
