import API_ENV from "@config";
import React, { useState } from "react";
import Hooks from "../../../Hooks";

export default function FilterRow({ text }) {
  const [valueInput, updateInput] = useState(
    <input className="filterRowInput rowBlock" type="text" />
  );

  function loadDropdown(type) {
    const urls = {
      contract: `${API_ENV}/contracts`,
      engineer: `${API_ENV}/staff`,
      jobstate: `${API_ENV}/jobState`,
      jobtype: `${API_ENV}/jobType`,
    };

    if (urls[type]) {
      Hooks.getData(urls[type], (data) => {
        if (data && data.length) {
          let array = [];

          data.forEach((item) => {
            switch (type) {
              case "contract":
                array.push(
                  <option value={item.id} key={`contract_${item.name}`}>
                    {item.name}
                  </option>
                );
                break;
              case "engineer":
                array.push(
                  <option
                    value={item.id}
                    key={`engineer_${item.forename}_${item.surname}`}
                  >{`${item.forename} ${item.surname}`}</option>
                );
                break;
              case "jobstate":
              case "jobtype":
                array.push(
                  <option value={item.id} key={`${type}_${item.description}`}>
                    {item.description}
                  </option>
                );
                break;
              default:
                break;
            }
          });
          updateInput(
            <select className="filterRowInput rowBlock">{array}</select>
          );
        }
      });
    }
  }

  function typeChanged(type) {
    switch (Hooks.conditionalString(type)) {
      case "contract":
      case "engineer":
      case "jobstate":
      case "jobtype":
        loadDropdown(Hooks.conditionalString(type));
        break;
      case "scheduleddate":
      case "targetdate":
        updateInput(<input className="filterRowInput rowBlock" type="date" />);
        break;
      default:
        updateInput(<input className="filterRowInput rowBlock" type="text" />);
        break;
    }
  }
  return (
    <div className="filterRow">
      <div className="rowBlock">{text}</div>
      <select
        className="filterRowType rowBlock"
        onChange={(e) => {
          typeChanged(e.target.value);
        }}
      >
        <option>General</option>
        <option>Job Number</option>
        <option>UPRN</option>
        <option>Contract</option>
        <option>Reference</option>
        <option>Engineer</option>
        <option>Job State</option>
        <option>Job Type</option>
        <option>Scheduled Date</option>
        <option>Target Date</option>
      </select>
      <div className="rowBlock">for</div>
      {valueInput}
    </div>
  );
}
