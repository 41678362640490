import React, { useEffect, useState } from "react";
import "./header.scss";

// Updated CalendarBlock to include a key prop
function CalendarBlock(i, type, date, key) {
  switch (type) {
    case "d":
      return <div key={key} className="headerBlock">{`${i}:00`}</div>;
    case "w":
    case "f":
      const days = {
        0: "Monday",
        1: "Tuesday",
        2: "Wednesday",
        3: "Thursday",
        4: "Friday",
        5: "Saturday",
        6: "Sunday",
      };
      return (
        <div key={key} className="headerBlock">
          {days[i]}
        </div>
      );
    case "4":
    case "m":
      return (
        <div key={key} className="headerBlock">
          {new Date(date.setDate(date.getDate() + 1)).toDateString()}
        </div>
      );
    default:
      return null; // It's good practice to return null for the default case
  }
}

export default function CalendarHeader({ type, date }) {
  const [blocks, updateBlocks] = useState([]);

  useEffect(() => {
    // Added a key for the static 'Engineers' block
    const array = [
      <div key="engineers" className="headerBlock">
        Engineers
      </div>,
    ];
    const blockQuantity = {
      d: 24,
      w: 5,
      f: 7,
      4: 28,
      m: 31,
    };

    for (let i = 0; i < blockQuantity[type]; i++) {
      // Passing i as key because it's unique within this loop
      array.push(CalendarBlock(i, type, date, `block-${i}`));
    }

    if (array.length) {
      updateBlocks(array);
    }
  }, [type, date]);

  return <div id="calendarHeader">{blocks}</div>;
}
