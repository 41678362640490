import Button from "@ui/Button";
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi2";

function AnswerInput({ id, value, onDelete, onChange }) {
  return (
    <div className="questionMultiInput">
      <input
        className="answerInput input small"
        type="text"
        placeholder="Answer"
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
      />
      <Button
        type="danger"
        onClick={(e) => {
          e.preventDefault();
          onDelete(id);
        }}
      >
        <HiTrash />
      </Button>
    </div>
  );
}

export default function InspectionAnswers({ type }) {
  const [answers, setAnswers] = useState([{ id: Date.now(), value: "" }]);

  useEffect(() => {
    setAnswers([{ id: Date.now(), value: "" }]);
  }, [type]);

  const addAnswer = () => {
    setAnswers([...answers, { id: Date.now(), value: "" }]);
  };

  const deleteAnswer = (id) => {
    setAnswers(answers.filter((answer) => answer.id !== id));
  };

  const updateAnswer = (id, value) => {
    setAnswers(
      answers.map((answer) =>
        answer.id === id ? { ...answer, value } : answer
      )
    );
  };

  return (
    <div id="answersContainer">
      <Button
        id="addAnswerButton"
        onClick={(e) => {
          e.preventDefault();
          addAnswer();
        }}
      >
        Add new answer option
      </Button>

      <div id="answersList">
        {answers.map((answer) => (
          <AnswerInput
            key={answer.id}
            id={answer.id}
            value={answer.value}
            onDelete={deleteAnswer}
            onChange={updateAnswer}
          />
        ))}
      </div>
    </div>
  );
}
