import "../styles/ui/FormRow.scss";

export default function FormRow({
  label,
  error,
  children,
  position,
  type = "normal",
}) {
  return (
    <div className={`formRowComponent ${position} ${type}`}>
      {label && <label htmlFor={children.props.id}>{label}</label>}
      {children && children}
      {error && <span>{error}</span>}
    </div>
  );
}
