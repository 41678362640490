import API_ENV from "@config";
import React, { useRef, useState } from "react";
import "./partsearch.scss";
import Hooks from "../../../Hooks";
import { HiMagnifyingGlass } from "react-icons/hi2";

export default function PartSearch({ search }) {
  const partInput = useRef(null);
  const [firstPart, setFirstPart] = useState(null);

  function searchClicked(partNo) {
    if (!search || typeof search !== "function") {
      return;
    }
    if (!partNo) {
      Hooks.getData(`${API_ENV}/parts/search`, (parts) => {
        search(parts);

        Hooks.getData(`${API_ENV}/parts/getpartleaf`, (firstpart) => {
          setFirstPart(firstpart);

          // Move this inside the callback
          search(parts, firstpart); // Use firstpart here
        });
      });
    } else {
      Hooks.getData(
        `${API_ENV}/parts/search?searchstring=${partNo}`,
        (parts) => {
          search(parts, firstPart);
        }
      );
    }
  }

  return (
    <React.Fragment>
      <div className="partSearch">
        <input
          ref={partInput}
          className="partSearchInput"
          type="text"
          placeholder="Search for part..."
        />
        <div
          className="searchIcon"
          onClick={() => {
            searchClicked(partInput?.current?.value);
          }}
        >
          <HiMagnifyingGlass />
        </div>
      </div>
    </React.Fragment>
  );
}
