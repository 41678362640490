import API_ENV from "@config";
import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import SearchBar from "@ui/SearchBar";
import { useParams } from "react-router-dom";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    sorCode: yup.string().required("This field is required"),
    // shortDesc: yup.string().required("This field is required"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function AddSORToContract({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });

  const { tableRowId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sorCode: "",
      shortDesc: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        // cleanURL();
        reset();
      }

      const dataObject = {
        contractId: tableRowId,
        sorId: data.id,
      };

      console.log("handleSubmitForm data:", data);
      console.log("handleSubmitForm dataObject:", dataObject);

      Hooks.sendData(
        dataObject,
        `${API_ENV}/contractsAndSor `,
        "POST",
        thenFunction
      );
    },
    [refreshTable, tableRowId, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SearchBar title={"SOR"} type={"sor"} setValue={setValue} />

          <SidePanelFormRow>
            <Input
              label={"SOR Code"}
              name="sorCode"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Short Description"}
              name="shortDesc"
              readOnly
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow> */}

          {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
        </div>
      </CollapsibleGroup>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
