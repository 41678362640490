import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";
import DateTimePicker from "../../../features/generic/DateTimePicker";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    name: yup.string().required("This field is required"),
    startDate: yup.date().required("This field is required"),
    endDate: yup.date().required("This field is required"),
    clientIdRefId: yup.string().required("This field is required"),
    // equipmentCategory: yup.string().required("This field is required"),
    // jobType: yup.string().required("This field is required"),
    // contactName: yup.string().required("This field is required"),
    // contactEmail: yup.string().required("This field is required"),
    // contactPhone: yup.string().required("This field is required"),
    // contactNotes: yup.string().required("This field is required"),
  })
  .required();

export default function AddContract({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
    dropdownData3: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      startDate: "",
      endDate: "",
      clientIdRefId: "",
      equipmentCategory: "",
      // jobType: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      contactNotes: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/clients`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
    Hooks.getData(`${API_ENV}/equipmentCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
    Hooks.getData(`${API_ENV}/jobType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData3: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      // console.log("apiUrl", apiUrl);
      // console.log("modifiedApiUrl", modifiedApiUrl);
      // console.log("data", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        "POST",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <label>Start Date</label>
          <Controller
            name="startDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimePicker
                classValue="input medium center"
                value={field.value}
                onChange={field.onChange}
                hideTime={false}
                error={fieldState.error}
              />
            )}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <label>End Date</label>
          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimePicker
                classValue="input medium center"
                value={field.value}
                onChange={field.onChange}
                hideTime={false}
                error={fieldState.error}
              />
            )}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Client"}
            name="clientIdRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Equipment Category"}
            name="equipmentCategory"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData2}
          />
        </SidePanelFormRow>

        {/* <SidePanelFormRow>
          <DropdownList
            label={"Job Type"}
            name="jobType"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData3}
            optionName="description"
          />
        </SidePanelFormRow> */}

        <SidePanelFormRow>
          <Input
            label={"Contact Name"}
            name="contactName"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Contact Email"}
            name="contactEmail"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Contact Phone"}
            name="contactPhone"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Contact Notes"}
            name="contactNotes"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
