import React from "react";
import { HiChevronDoubleDown } from "react-icons/hi2";

export default function SubJob({ data }) {
  function headerClicked(target) {
    let display =
      target.parentElement.querySelector(".subJobForm").style.display;
    if (display === "flex") {
      target.parentElement.querySelector(".subJobForm").style.display = "none";
      //   target.querySelector("img").src = DownIcon;
    } else {
      target.parentElement.querySelector(".subJobForm").style.display = "flex";
      //   target.querySelector("img").src = UpIcon;
    }
  }

  return (
    <div className="subJob">
      <div
        className="subJobHeader"
        onClick={(e) => {
          headerClicked(e.target);
        }}
      >
        <div
          onClick={(e) => {
            headerClicked(e.target.parentElement);
          }}
        >{`${data.id} at ${data.property.address1} ${data.property.postCode}`}</div>

        <HiChevronDoubleDown
          onClick={(e) => {
            headerClicked(e.target.parentElement);
          }}
        />
      </div>
      <div className="subJobForm">
        <div className="formEntry">
          <label>Client</label>
          <input defaultValue={data.client.name} readOnly />
        </div>
        <div className="formEntry">
          <label>Contract</label>
          <input defaultValue={data.contract.name} readOnly />
        </div>
        <div className="formEntry">
          <label>Property</label>
          <input defaultValue={data.property.address1} readOnly />
        </div>
        <div className="formEntry">
          <label>Reference</label>
          <input defaultValue={data.reference} readOnly />
        </div>
        <div className="formEntry">
          <label>Engineer</label>
          <input
            defaultValue={`${data.engineer.forename} ${data.engineer.surname}`}
            readOnly
          />
        </div>
        <div className="formEntry">
          <label>Job State</label>
          <input defaultValue={data.jobState.description} readOnly />
        </div>
        <div className="formEntry">
          <label>Priority</label>
          <input defaultValue={data.priority.name} readOnly />
        </div>
        <div className="formEntry">
          <label>Scheduled Date</label>
          <input
            defaultValue={new Date(data.scheduledDate).toDateString()}
            readOnly
          />
        </div>
        <div className="formEntry">
          <label>Target Date</label>
          <input
            defaultValue={new Date(data.targetDate).toDateString()}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}
