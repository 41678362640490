import API_ENV from "@config";
import "../../../styles/features/MultiContentForm.scss";

import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";

import ContentForm from "./Components/ContentForm";
import ContentList from "./Components/ContentList";

export default function MultiContentForm({ type, data }) {
  const [contentList, updateContentList] = useState([]);
  const [contentForm, updateContentForm] = useState({});

  // useEffect(() => {
  //   // console.log("type", type);
  //   // console.log("data from table:", data);
  //   // console.log("contentList", contentList);
  //   console.log("contentForm", contentForm);
  // }, [contentForm]);

  function targetClicked(event, item) {
    // Directly use the passed item object to update the form
    // No need to parse eventData from the event.target.getAttribute
    updateContentForm(item); // Directly updating the form with the clicked item
  }

  useEffect(() => {
    let sidePanelId;

    try {
      sidePanelId = Hooks.getSidePanelData().id;
    } catch (e) {
      // Handle error or log as necessary
      console.error("Error getting side panel data:", e);
      return; // Return early if there's an error getting side panel data
    }

    const urls = {
      assignedjobs: `${API_ENV}/jobs?PropertyId=${sidePanelId}&limit=100`, // Properties
      equipmentdetails: `${API_ENV}/equipment/property/${sidePanelId}`, // Properties
      resident: `${API_ENV}/residents/property/${sidePanelId}`, // Properties
      visit: `${API_ENV}/visits?jobIDs=${sidePanelId}`,
      jobparts: `${API_ENV}/jobPartsUsed?JobId=${sidePanelId}`,
      staffcertifications: `${API_ENV}/certification?EngineerId=${sidePanelId}`,
      staffcontract: `${API_ENV}/contract/engineer/${sidePanelId}`,
      assignedapis: `${API_ENV}/roleToApi?RoleId=${sidePanelId}`,
      scheduling: `${API_ENV}/schedule/contract/${sidePanelId}`,
      supplierparts: `${API_ENV}/partsAndSupplier?SupplierID=${sidePanelId}`,
      stocklocation: `${API_ENV}/stockLocation?WarehouseID=${sidePanelId}`,
      partsupplier: `${API_ENV}/partsAndSupplier?PartsID=${sidePanelId}`,
    };

    Hooks.getData(urls[type], (fetchedData) => {
      // console.log("urls[type]:", urls[type]);
      // console.log("fetchedData from MultiContentForm:", fetchedData);
      if (fetchedData && fetchedData.length > 0) {
        updateContentList(fetchedData);
        updateContentForm(fetchedData[0]); // Automatically select the first item for form
      } else {
        // Explicitly clear states if fetched data is empty or not present
        updateContentList([]);
        updateContentForm({}); // This ensures form is cleared when no data is available
      }
    });
  }, [type, data]);

  return (
    <div id="multiContentContainer">
      <ContentList data={contentList} type={type} clicked={targetClicked} />
      <ContentForm formContents={contentForm} type={type} />
    </div>
  );
}
