import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../Hooks";

export default function RecentJobs({ data }) {
  const [content, updateContent] = useState(
    <div className="noContent">
      No jobs have been created in this timeframe.
    </div>
  );

  function jobRow(jobData) {
    return (
      <div className="jobRow">
        <div className="jobRowCell">{jobData.id}</div>
        <div className="jobRowCell">{jobData.client.name}</div>
        <div className="jobRowCell">{jobData.contract.name}</div>
        <div className="jobRowCell">{jobData.jobType.description}</div>
        <div className="jobRowCell">{jobData.jobState.description}</div>
        <div className="jobRowCell">
          {jobData.engineer.forename + " " + jobData.engineer.surname}
        </div>
        <div className="jobRowCell">
          {jobData.property.address1 + " " + jobData.property.postCode}
        </div>
      </div>
    );
  }

  function loadRows(data) {
    const array = [
      <div className="jobRow">
        <div className="jobRowCell">ID</div>
        <div className="jobRowCell">Client</div>
        <div className="jobRowCell">Name</div>
        <div className="jobRowCell">Job Type</div>
        <div className="jobRowCell">Job State</div>
        <div className="jobRowCell">Engineer</div>
        <div className="jobRowCell">Location</div>
      </div>,
    ];

    data.forEach((job) => {
      array.push(jobRow(job));
    });

    updateContent(array);
  }

  useEffect(() => {
    if (data && data.length && typeof data !== "string") {
      loadRows(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function goButtonClicked() {
    const days = document.querySelector("#daysInput").value;

    if (days) {
      Hooks.getData(
        `${API_ENV}/jobs?DaysMostRecentlyCreated=${days}&limit=50`,
        (data) => {
          if (data && data.length && typeof data !== "string") {
            loadRows(data);
          }
        }
      );
    } else {
      Hooks.displayError(
        "Please enter the number of days ago (int) that shown jobs will have been created from."
      );
    }
  }

  return (
    <div id="recentJobs">
      <div id="recentJobsHeader">
        <div id="recentJobsTitle">Recently Created Jobs</div>
        <div id="recentJobsSearch">
          <input
            id="daysInput"
            placeholder="Days"
            type="number"
            min={1}
            max={100}
          />
          <div id="goButton" onClick={goButtonClicked}>
            Go
          </div>
        </div>
      </div>

      <div id="recentJobsContent">{content}</div>
    </div>
  );
}
