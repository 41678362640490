import API_ENV from "@config";
import "../../styles/features/Table.scss";
// import "../../styles/features/OldTable.scss";

import Hooks from "../../Hooks";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useTableData } from "../../context/TableDataContext";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format, parseISO } from "date-fns";

import { LuChevronsLeftRight } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "./../../ui/Button";
import {
  HiMiniArrowsUpDown,
  HiMiniArrowUp,
  HiMiniArrowDown,
} from "react-icons/hi2";
import ContextMenu from "@ui/ContextMenu";
import { Modal, useModal } from "@ui/Modal";
import AddTagModalForm from "forms - NEW/00. Other/Modal Forms/AddTagModalForm";

const DefaultCell = ({ getValue }) => <>{getValue()}</>;

let pageNum = 1;

const Table = forwardRef(
  (
    {
      type,
      pageTitleDOM,
      sidePanel,
      tableRowClicked,
      url: propUrl,
      lastPage,
      setLocalTableState,
      setSelectedTableRowId,
      setShowSidePanel,
      initialTableURL,
    },
    ref
  ) => {
    const [data, setData] = useState([]);
    const {
      tableHeadings,
      tableData,
      tableKeysData,
      tableContextFormData,
      tableContextError,
      setTableContextError,
    } = useTableData();
    const [error, setError] = useState("");
    const [contextMenu, setContextMenu] = useState(null);

    const [checkedRows, setCheckedRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [subData, setSubData] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    const { openModal } = useModal();

    useEffect(() => {
      pageNum = 1;
    }, [type, propUrl, tableHeadings]); //. tableHeadings here for Report Online Pages to reset pageNum on new report preview.

    let pageTitle = pageTitleDOM.current.textContent;

    useEffect(() => {
      // console.log("Table.js type:", type);
      console.log("Table data:", data);
      // console.log("subData:", subData);
      // console.log("checkedRows:", checkedRows);
      // console.log("tableHeadings:", tableHeadings);
      // console.log("tableKeysData:", tableKeysData);
      // console.log("error:", error);
      // console.log("dataId:", dataId);
      // console.log("propUrl:", propUrl);
      // console.log("initialTableURL:", initialTableURL);
    }, [data]);

    //* Refreshes the table using first URL on page load
    useImperativeHandle(ref, () => ({
      refreshData: (urlModifier = "") => {
        const url = initialTableURL + urlModifier;
        // console.log("url", url);
        getTableData(url);
      },
    }));

    //* Error handling for TableContextAPI Errors
    useEffect(() => {
      if (tableContextError) {
        setError(tableContextError);
      } else {
        setError("");
      }
    }, [tableContextError]);

    //* Set table data to use ContextAPI one
    useEffect(() => {
      if (tableData && tableData.length > 0) {
        if (
          // runreportasonlinecsvxls runreportasonlinepdf
          pageTitle === "Report Online CSV XLS" ||
          pageTitle === "Report Online PDF"
        ) {
          setData(tableData);
        }
      } else {
        setData([]);
      }
    }, [pageTitle, tableData]);

    //* Clears Table Data on new page load
    useEffect(() => {
      if (
        // runreportasonlinecsvxls runreportasonlinepdf
        pageTitle === "Report Online CSV XLS" ||
        pageTitle === "Report Online PDF"
      ) {
        setData([]);
        setTableContextError("");
      }
    }, [location.pathname, pageTitle, setTableContextError]);

    //* Function To get Table Data
    const getTableData = useCallback((apiUrl) => {
      // console.log("getTableData URL:", apiUrl);

      Hooks.getData(
        apiUrl,
        (data) => {
          if (
            Array.isArray(data) &&
            data &&
            data.length &&
            typeof data !== "string"
          ) {
            setData(data);
            setError("");
          } else {
            setError(
              "No data found! Click the 'Refresh' button or try adjusting your search criteria & searching again."
            );
          }
        },
        (error) => {
          setError("An error occurred while fetching data. Please try again.");
        }
      );
    }, []);

    // * This makes the first call of getting the table data
    useEffect(() => {
      // Default loading APIs for the table based on type.
      // By default it will use Hooks.resetKey.
      // URLs with queries are specified seperately.
      // console.log("type", type);
      switch (type) {
        case "archive":
          const urlType = Hooks.conditionalString(pageTitle);

          if (urlType === "definereports") {
            break;
          }

          getTableData(
            `${API_ENV}/${Hooks.resetKey(urlType)}?showarchive=true`
          );
          break;
        case "stock":
          getTableData(`${API_ENV}/stock?isVan=false`);
          break;
        case "stockvan":
          getTableData(`${API_ENV}/stock?isVan=true`);
          break;
        case "stockwarehouse":
          getTableData(`${API_ENV}/stock?isVan=false`);
          break;
        case "properties":
          getTableData(`${API_ENV}/${type}?hideSubProperty=true`);
          break;
        case "jobs":
          getTableData(`${API_ENV}/${type}?hideSubJobs=true`);
          break;
        case "parts":
          getTableData(`${API_ENV}/parts?limit=100&showarchive=false`);
          break;

        // Add pages with manual/dynamic tables here
        // case "runreportasonlinelog":
        case "runreportasonlinecsvxls":
        case "runreportasonlinepdf":
          break;
        case "runreportasfileoremail":
          getTableData(
            `${API_ENV}/runReportProc?RunByStaffId=${Hooks.getCookie("userId")}`
          );
          break;
        case "runcustomprocess":
          getTableData(`${API_ENV}/LetterPrintProcess?showarchive=false`);
          break;
        case "partssubcategory1":
          getTableData(`${API_ENV}/PartsSubCategoryListViews`);
          break;
        default:
          if (propUrl && propUrl.length) {
            getTableData(propUrl.split(" ").join("%20"));
          } else {
            getTableData(`${API_ENV}/${Hooks.resetKey(type)}`);
          }
      }
    }, [pageTitle, propUrl, getTableData, type]);

    // * Lazy Loading
    useEffect(() => {
      // console.log("Tracking Report Online Pages 1: Runs on Preview Click");
      // This updates the entry count value at the bottom of the page whenever new data is loaded.
      if (document.querySelector("#footerText")) {
        document.querySelector("#footerText").textContent =
          "Table Rows: " + document.querySelectorAll(".tableRow").length;
      }

      // Flag to track loading status
      let loading = false;

      // Function to load more data when scrolling
      const loadMoreData = () => {
        // Prevent concurrent loads
        if (loading) return;
        loading = true;

        // Construct the fetchUrl with page and other parameters
        let fetchUrl;

        const trimmedPageTitle = pageTitle.trim();

        if (propUrl) {
          // Update the Page parameter of propUrl's so lazy loading works
          const url = new URL(propUrl);
          url.searchParams.set("Page", pageNum);
          fetchUrl = url.toString();
        } else {
          if (
            // runreportasonlinecsvxls runreportasonlinepdf
            trimmedPageTitle === "Report Online CSV XLS" ||
            trimmedPageTitle === "Report Online PDF"
          ) {
            fetchUrl = `${API_ENV}/runReportList/runonline/runnow/${tableContextFormData.reportId}?Page=${pageNum}&Size=100&runReportFilterId=${tableContextFormData.runReportFilterId}`;
          } else if (trimmedPageTitle === "Report as File or Email") {
            fetchUrl = `${API_ENV}/runReportProc?RunByStaffId=${Hooks.getCookie(
              "userId"
            )}&Page=${pageNum}&Size=100`;
          } else {
            fetchUrl = `${API_ENV}/${Hooks.getCookie(
              "last_page"
            )}?Page=${pageNum}&Size=100`;
          }
        }

        // Fetch and append more data
        Hooks.getData(fetchUrl, (newTableData) => {
          // console.log("2. Lazy load URL:", fetchUrl);
          if (
            newTableData &&
            newTableData.length &&
            typeof newTableData !== "string"
          ) {
            const newValues = newTableData;
            setData([...data, ...newValues]);
          }

          // Reset loading flag
          loading = false;
        });
      };

      // Initialize the Intersection Observer for lazy loading
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // console.log("entry:", entry);
            if (entry.isIntersecting && !loading) {
              pageNum += 1;
              loadMoreData();
            }
          });
        },
        {
          root: document.querySelector(".tableContainer"), //* root: Set the root element as the viewport for intersection calculations
          rootMargin: "3500px", //* rootMargin: Expand the root's bounding box by 3000 pixels on all sides
          threshold: 1, //* threshold: Trigger the callback when 99% of the target is visible within the root
        }
      );

      // Start observing when the component mounts - IT LOADS NEXT PAGE x ROWS FROM LAST ROW EDIT THIS VALUE TO CHANGE WHEN NEXT PAGE SHOULD LOAD
      const tableRowIntersectTrigger = document?.querySelector(
        ".tableRow:nth-last-child(1)"
      );
      if (tableRowIntersectTrigger) {
        intersectionObserver.observe(tableRowIntersectTrigger);
      }

      // Clean up the Intersection Observer when the component unmounts
      return () => {
        intersectionObserver.disconnect();
      };
    }, [
      data,
      pageTitle,
      propUrl,
      tableContextFormData.reportId,
      tableContextFormData.runReportFilterId,
    ]);

    const tableRowClicked2 = useCallback(
      (e, row, subRow) => {
        // console.log("tableRowClicked2");
        // console.log("e", e);
        // console.log("e", e.target.parentElement);
        // console.log("row", row);

        if (
          location.pathname === "/system/report-online-pdf" ||
          location.pathname === "/system/report-online-csv-xls"
        ) {
          return;
        } else if (e.target.id === "checkboxID") {
          return;
        }

        // * If the clicked target is within .checkboxCell, do nothing
        // if (e.target.closest(".checkboxCell")) {
        //   return;
        // }

        // This toggles & untoggles the class .selectedRow when clicking on different table rows
        document.querySelectorAll(".selectedRow").forEach((element) => {
          element.classList.toggle("selectedRow");
        });

        if (
          e.target.parentElement.classList.contains("tableRow") ||
          e.target.parentElement.classList.contains("subRow")
        ) {
          e.target.parentElement.classList.toggle("selectedRow");
        }

        if (
          e.target.className === "rowSelectBox" ||
          e.target.className === "subRowButton"
        ) {
          return;
        }

        function setPathInURL(e, rowId) {
          // console.log("setPathInURL");
          const pathSegments = location.pathname.split("/");
          const lastSegment = pathSegments[pathSegments.length - 1];

          let newPath;
          if (!isNaN(lastSegment)) {
            // If the last segment is a numeric value, replace it with the new dataId
            pathSegments[pathSegments.length - 1] = rowId;
            newPath = pathSegments.join("/");
          } else {
            // If the last segment is not a numeric value, append the new dataId
            newPath = `${location.pathname}/${rowId}`;
          }
          navigate(newPath);
        }

        let id;

        if (Hooks.getCookie("last_page") === "partssubcategory1") {
          id = Number(row.id);
        } else if (subRow) {
          id = Number(row.id);
        } else {
          id = Number(row.original.id);
        }

        // console.log("tableRowClicked2 id:", id);

        if (sidePanel) {
          // setPathInURL(e, row.original.id);
          setPathInURL(e, id);
          sidePanel.current.setAttribute("data", data);
          // sidePanel.current.setAttribute("index", count);
        }

        setShowSidePanel(true);
      },
      [location.pathname, navigate, data, sidePanel, setShowSidePanel]
    );

    const generateTableColumns = useCallback(
      (pageType) => {
        // console.log("generateTableColumns - pageType:", pageType);

        let headers = [];
        let keys = [];

        // report online adjustments
        if (
          pageType === "reportonlinecsvxls" ||
          pageType === "reportonlinepdf"
        ) {
          headers = tableHeadings;
          keys = tableKeysData;
        } else {
          headers = Hooks.tableHeaders(pageType);

          keys = Hooks.tableKeys(pageType);
        }

        // error handling
        if (!tableKeysData) {
          if (!keys || headers.length !== keys.length) {
            console.error(
              "Mismatch in headers and keys length for page:",
              pageType
            );
            return [];
          }
        }

        // Add all keys that need date formatting here
        const dateKeys = [
          "startDate",
          "endDate",
          "scheduledDate",
          "targetDate",
          "actualStartTime",
          "actualEndTime",
          "scheduledStartTime",
          "scheduleStartTime",
          "scheduledEndTime",
          "recordCreated",
          "targrecordModifiedetDate",
          "requestDate",
          "raisedDate",
          "contractStartDate",
          "contractEndDate",
          "reportDate",
          "fixDate",
          "logTime",
        ];

        const pageTitleFormatted = Hooks.conditionalString(pageTitle);

        return headers.map((header, index) => {
          const key = keys[index];

          const accessorFn = (rowData) => {
            // Check to see which key is failing
            // console.log(`Processing key: ${key}`, rowData);
            // console.log("key", key);

            let display = rowData[key];

            switch (key) {
              case "uom":
                display = rowData.uom?.name;
                break;
              case "ticketType":
                display = rowData.ticketType?.name;
                break;
              case "client":
                display = rowData.client?.name;
                break;
              case "ticketNo":
                display = rowData.id;
                break;
              case "jobbooked":
                display = rowData.jobBookedId;
                break;
              case "stockState":
                display = rowData?.stockState?.name;
                break;
              case "aisle":
                display = rowData?.stockLocation?.aisle;
                break;
              case "level":
                display = rowData?.stockLocation?.level;
                break;
              case "rack":
                display = rowData?.stockLocation?.rack;
                break;
              case "responsibleStaff":
                display = `${rowData?.responsibleStaff?.forename} ${rowData?.responsibleStaff?.surname}`;
                break;
              case "partsCategory":
                display = rowData?.partsCategory?.name;
                break;
              case "partsSubCategory1":
                display = rowData?.partsSubCategory1?.name;
                break;
              case "partsSubCategory2":
                display = rowData?.partsSubCategory2?.name;
                break;
              case "partsSubCategory3":
                display = rowData?.partsSubCategory3?.name;
                break;
              case "partsSubCategory4":
                display = rowData?.partsSubCategory4?.name;
                break;
              case "costType":
                display = rowData?.costType?.name;
                break;
              case "subcategory":
                display = `${rowData?.partsCategory?.name}`;
                break;
              case "subcategory1":
                display = rowData?.partsSubCategory1?.name || "";
                break;
              case "subcategory2":
                display = rowData?.partsSubCategory2?.name || "";
                break;
              case "subcategory3":
                display = rowData?.partsSubCategory3?.name || "";
                break;
              case "subcategory4":
                display = rowData?.partsSubCategory4?.name || "";
                break;
              case "requestStaff":
                display = `${rowData?.requestStaff?.forename} ${rowData?.requestStaff?.surname}`;
                break;
              case "targetwarehouse":
                display = rowData?.targetWarehouse?.name;
                break;
              case "stockLocation":
                display = `${rowData?.stockLocation?.aisle} | ${rowData?.stockLocation?.rack} | ${rowData?.stockLocation?.level}`;
                break;
              case "deliveryNoteState":
                display = rowData?.deliveryNoteState?.name;
                break;
              case "raisedByStaff":
                display = `${rowData?.raisedByStaff?.forename} ${rowData?.raisedByStaff?.surname}`;
                break;
              case "purchaseOrderState":
                display = rowData?.purchaseOrderState?.name;
                break;
              case "supplier":
                display = rowData?.supplier?.name;
                break;
              case "purchaseRequestState":
                display = rowData?.purchaseRequestState?.name;
                break;
              case "targetSupplier":
                display = rowData?.targetSupplier?.name;
                break;
              case "targetJob":
                display = rowData?.targetJobId;
                break;
              case "partno":
                display =
                  pageTitleFormatted === "parts"
                    ? rowData?.partno
                    : rowData?.parts?.partno;
                break;
              case "make":
                display = rowData?.make;
                break;
              case "model":
                display = rowData?.model;
                break;
              // case "selected":
              //   display = "checkbox";
              //   break;
              case "property":
                display = rowData?.property?.address1;
                break;
              case "staff":
              case "engineer":
                // Dynamic table fix - a table already existed with the "Engineer" column which recieved data differently
                if (
                  // pageTitleFormatted === "reportonline" ||
                  pageTitleFormatted === "reportonlinecsvxls" ||
                  pageTitleFormatted === "reportonlinepdf"
                ) {
                  display = rowData?.engineer;
                } else {
                  display = `${rowData?.engineer?.forename} ${rowData?.engineer?.surname}`;
                }
                break;
              case "jobNumber":
                display = rowData?.id;
                break;
              case "clientRefId":
                display = rowData.clientRefId ?? rowData.clientIdRefId;
                break;
              case "apiGroup":
                display = rowData?.runReportList?.apiGroup?.groupName;
                break;
              case "apiGroup2":
                display = rowData?.apiGroup?.groupName;
                break;
              case "reportName":
                display = rowData?.runReportList?.name;
                break;
              case "exportFormat":
              case "reportType":
                display = rowData?.runReportType?.name;
                break;
              case "reportRunBy":
                display = `${rowData?.staff?.forename} ${rowData?.staff?.surname}`;
                break;
              case "processState":
                display = rowData?.runProcessState?.name;
                break;
              case "letterName":
                display =
                  rowData?.letterPrintSetup?.letterName || rowData?.letterName;
                break;
              case "runProgramListName":
                display = rowData?.runProgramList?.name;
                break;
              case "queryTable":
                display = rowData?.runProgramList?.queryTable;
                break;
              case "createdBy":
                display = `${rowData?.ticketCreator?.forename} ${rowData?.ticketCreator?.surname} `;
                break;
              case "assignedTo":
                display = `${rowData?.ticketAssignedTo?.forename} ${rowData?.ticketAssignedTo?.surname} `;
                break;
              case "docTypeName":
                display =
                  rowData?.docTypeName ||
                  rowData?.objectDocType?.docTypeName ||
                  rowData?.attributeCategory?.objectDocType?.docTypeName;
                break;
              case "catName":
                display =
                  rowData?.catName || rowData?.attributeCategory?.catName;
                break;
              case "answerType":
                display = rowData?.answerType.desc;
                break;
              case "equipmentCategory":
                display =
                  rowData?.equipmentCategory?.name ||
                  rowData?.equipmentSubCategory1?.equipmentCategory?.name;
                break;

              // case "fixDate":
              //   display = `${rowData?.ticketAssignedTo?.forename} ${rowData?.ticketAssignedTo?.surname} `;
              //   break;

              default:
                if (key === "apiGroup") {
                  display = rowData.apiGroup?.groupName;
                } else if (
                  [
                    "destinationWarehouse",
                    "sourceWarehouse",
                    "equipmentSubCategory",
                    "warehouse",
                    "inspectionType",
                    "priority",
                    "equipmentSubCategory1",
                    "contract",
                    "client",
                  ].includes(key) &&
                  rowData[key]?.name
                ) {
                  display = rowData[key].name;
                } else if (
                  [
                    "jobType",
                    "currJobState",
                    "nextJobState",
                    "currVisitState",
                    "nextVisitState",
                    "jobCategory",
                    "parts",
                    "jobType",
                    "jobState",
                    "staffType",
                  ].includes(key) &&
                  rowData[key]?.description
                ) {
                  display = rowData[key].description;
                }
                break;
            }

            // correctly formats any keys found within dateKeys
            if (dateKeys.includes(key) && display) {
              // console.log(key, display);

              // Formates any junk dates into a dash
              if (display === "0001-01-01T00:00:00") {
                display = "-";
              } else {
                const date = parseISO(display);
                display = format(date, "EEE MMM dd yyyy");
              }
            }

            // Convert boolean values to "Yes" or "No"
            if (display === false) {
              display = "No";
            } else if (display === true) {
              display = "Yes";
            }

            return display || "";
          };

          return {
            accessorKey: key, // * This is the key name of the value we want to disaplay
            header: header, // * This is the header name displayed at the top of the table
            cell: DefaultCell,
            accessorFn: accessorFn,
            canSort: true,
          };
        });
      },
      [pageTitle, tableHeadings, tableKeysData]
    );

    const handleDownload = useCallback(
      async (rowData) => {
        try {
          const apiUrl = `${API_ENV}/runReportList/runonline/downloadpdf/${tableContextFormData.reportId}/${rowData.jobNum}`;
          // console.log(apiUrl);

          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
            },
          });

          if (!response.ok) {
            const errorText = await response.text();
            Hooks.displayError(errorText);
            throw new Error(errorText);
          }

          // Assuming the API directly responds with the file data
          const blob = await response.blob(); // Get the binary data
          const urlObject = URL.createObjectURL(blob);

          const selectElement =
            document.getElementById("reportName").selectedOptions[0].innerText;
          const fileName = `${selectElement}-${rowData.jobNum}.pdf`;

          // Create a temporary link and trigger the download
          const a = document.createElement("a");
          a.href = urlObject;
          a.download = fileName || "downloadedFile.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(urlObject);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      },
      [tableContextFormData.reportId]
    );

    const downloadButtonColumn = useMemo(
      () => ({
        header: "Download", // Column header
        accessorKey: "downloadButton", // Unique key for the column
        cell: ({ row }) => (
          <Button size="medium" onClick={() => handleDownload(row.original)}>
            Download
          </Button>
        ),
      }),
      [handleDownload]
    );

    const handleRightClick = useCallback(
      (e, row) => {
        // console.log("e", e);
        // console.log("row", row);

        e.preventDefault();

        setContextMenu({
          x: e.clientX,
          y: e.clientY,
          options: [
            {
              label: "Tag",
              onClick: () => {
                // console.log("Tag clicked", row);
                openModal("ContextMenuAddTag");
                setContextMenu(null); // Close the context menu
              },
            },
            {
              label: "Create Job",
              onClick: () => {
                console.log("Create Job clicked", row);
                setContextMenu(null); // Close the context menu
              },
            },
            {
              label: "Cancel",
              onClick: () => {
                console.log("Cancel clicked", row);
                setContextMenu(null); // Close the context menu
              },
            },
            {
              label: "Schedule",
              onClick: () => {
                console.log("Schedule clicked", row);
                setContextMenu(null); // Close the context menu
              },
            },
          ],
        });
      },
      [openModal]
    );

    const renderContextMenu = () => {
      if (!contextMenu) return null;
      return (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          options={contextMenu.options}
          onClose={() => setContextMenu(null)}
        />
      );
    };

    const handleExpandClick = useCallback((rowId) => {
      setExpandedRows((prev) => ({
        ...prev,
        [rowId]: !prev[rowId],
      }));
    }, []);

    const loadSubData = useCallback(
      async (rowId, type) => {
        // console.log("rowId", rowId);

        if (!subData[rowId]) {
          try {
            let url;
            if (type === "property") {
              url = `${API_ENV}/properties/subproperties/${rowId}`;
            } else if (type === "job") {
              url = `${API_ENV}/jobs/subjobs/${rowId}`;
            }
            // console.log("url:", url);

            Hooks.getData(url, (data) => {
              // console.log("SubData API Call:", data);

              setSubData((prev) => ({
                ...prev,
                [rowId]: data,
              }));
            });
          } catch (error) {
            console.error("Error fetching sub data:", error);
          }
        }
      },
      [subData]
    );

    const checkboxColumn = useMemo(
      () => ({
        header: "Menu", // Column header
        accessorKey: "tableMenuColumn", // Unique key for the column
        cell: ({ row }) => {
          // console.log("row:", row);

          const hasSubProperty = row.original?.hasSubProperty;
          const hasSubJobs = row.original?.hasSubjobs;

          //* Tracking the IDs of all checked Menu rows
          let rowId;
          if (row.original) {
            rowId = row.original.id;
          } else {
            rowId = row.id;
          }

          //* Handles checkbox state
          const handleCheckboxChange = (e) => {
            setCheckedRows((prev) =>
              prev.includes(rowId)
                ? prev.filter((id) => id !== rowId)
                : [...prev, rowId]
            );
          };

          return (
            <div
              className="tableCell checkboxCell"
              onContextMenu={(e) => handleRightClick(e, row)}
            >
              <input
                id="checkboxID"
                type="checkbox"
                checked={checkedRows.includes(rowId)}
                onChange={handleCheckboxChange}
                onClick={(e) => e.stopPropagation()}
              />

              {(hasSubProperty || hasSubJobs) && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpandClick(rowId);
                    loadSubData(rowId, hasSubProperty ? "property" : "job");
                  }}
                >
                  {expandedRows[rowId] ? "-" : "+"}
                </Button>
              )}
            </div>
          );
        },
      }),
      [
        handleRightClick,
        checkedRows,
        handleExpandClick,
        loadSubData,
        expandedRows,
      ]
    );

    const columns = useMemo(() => {
      // This should generate the existing columns
      let baseColumns = generateTableColumns(
        Hooks.conditionalString(pageTitle)
      );

      // Check if the current page is the specific one needing the download button
      if (location.pathname === "/system/report-online-pdf") {
        // Add the download button as the first column
        return [downloadButtonColumn, ...baseColumns];
      }

      if (pageTitle === "Properties" || pageTitle === "Jobs") {
        return [checkboxColumn, ...baseColumns];
      }

      return baseColumns;
    }, [
      generateTableColumns,
      pageTitle,
      location.pathname,
      downloadButtonColumn,
      checkboxColumn,
    ]);

    const tableInstance = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      enableColumnResizing: true,
      columnResizeMode: "onChange",
    });

    const table = useMemo(() => tableInstance, [tableInstance]);

    // Memoized table body
    const rows = table.getRowModel().rows;

    //. Attempt 2
    const MemoizedTableBody = useMemo(() => {
      return rows.flatMap((row) => {
        const rowId = row.original.id;
        const isExpanded = expandedRows[rowId];
        const isChecked = checkedRows.includes(rowId);

        // console.log("rowId", rowId);
        // console.log("subData[rowId]", subData[rowId]);

        return [
          // Main row
          <tr
            className={`tableRow ${isChecked ? "checked" : ""}`} // Optional class for checked row
            key={row.id}
            onClick={(e) => tableRowClicked2(e, row)}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                className="tableCell"
                key={cell.id}
                style={{ width: cell.column.getSize() }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>,

          // Sub-rows (only if expanded and subData exists)
          isExpanded &&
            subData[rowId] &&
            subData[rowId].map((subRowData, subIndex) => (
              <tr
                className="subRow"
                key={`sub-${row.id}-${subIndex}`}
                onClick={(e) => {
                  // console.log("subRowData", subRowData);
                  tableRowClicked2(e, subRowData, true);
                }}
              >
                {table.getAllColumns().map((column) => {
                  const accessorFn =
                    column.columnDef.accessorFn || ((row) => row[column.id]); // Use accessorFn or default to key

                  // Ensure subRowData is passed correctly
                  const value = accessorFn(subRowData) || "";

                  return (
                    <td
                      className="tableCell"
                      key={`${column.id}-${subIndex}`}
                      style={{ width: column.getSize() }}
                    >
                      {flexRender(column.columnDef.cell, {
                        getValue: () => value,
                        row: subRowData,
                        column,
                      })}
                    </td>
                  );
                })}
              </tr>
            )),
        ];
      });
    }, [rows, expandedRows, subData, tableRowClicked2, table, checkedRows]);

    return (
      <>
        {renderContextMenu()}
        <div className="tableContainer">
          <table className="table">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="headerRow" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const sortingState = header.column.getIsSorted(); // typically returns 'asc', 'desc', or false
                    // console.log("Header:", header);

                    return (
                      <th
                        className="headerCell"
                        key={header.id}
                        style={{ width: header.getSize() }}
                      >
                        <div className="headerCellContent">
                          <div className="titleAndSort">
                            {sortingState === "asc" ||
                            sortingState === "desc" ? (
                              <div className="sortActive">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                            ) : (
                              <div>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </div>
                            )}

                            {header.id !== "tableMenuColumn" && (
                              <div className="sortArrow">
                                {header.column.getCanSort() && (
                                  <>
                                    {sortingState === "asc" ? (
                                      <HiMiniArrowUp
                                        className="sortActive"
                                        size={17}
                                        onClick={header.column.getToggleSortingHandler()}
                                      />
                                    ) : sortingState === "desc" ? (
                                      <HiMiniArrowDown
                                        className="sortActive"
                                        size={17}
                                        onClick={header.column.getToggleSortingHandler()}
                                      />
                                    ) : (
                                      <HiMiniArrowsUpDown
                                        size={17}
                                        onClick={header.column.getToggleSortingHandler()}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          {header.column.getCanResize() && (
                            // Resizing handle
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`resizer ${
                                header.column.getIsResizing()
                                  ? "isResizing"
                                  : ""
                              }`}
                            >
                              <div
                                onMouseDown={header.getResizeHandler()}
                                onTouchStart={header.getResizeHandler()}
                                className={`myResizer ${
                                  header.column.getIsResizing()
                                    ? "isResizing"
                                    : ""
                                }`}
                              >
                                <LuChevronsLeftRight className="resizeHandle" />
                              </div>
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            {/* Optimized - But sorting is broken */}
            <tbody>{MemoizedTableBody}</tbody>

            {/* Old Unoptimized version - Sorting works */}
            {/* <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              className="tableRow"
              key={row.id}
              onClick={(e) => {
                tableRowClicked2(e, row);
                // setSelectedTableRowId(row.original.id);
                // setRowId(row.original.id);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  className="tableCell"
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody> */}
          </table>
          {error && <p className="tableError">{error}</p>}
        </div>

        <Modal name="ContextMenuAddTag" title={"Add Tags"}>
          <AddTagModalForm objectDocIdArray={checkedRows} />
        </Modal>
      </>
    );
  }
);

export default Table;
