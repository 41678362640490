import API_ENV from "@config";
import "./calendar.scss";

import ArrowSelector from "./header/ArrowSelector";
import CalendarHeader from "./header/CalendarHeader";
import React, { useEffect, useRef, useState } from "react";
import Hooks from "../../Hooks";
import CalendarRow from "./Contents/CalendarRow";
import PopupModal from "../generic/PopupModal/PopupModal";
import CalendarFilter from "./header/CalendarFilters/CalendarFilter";
import { HiMagnifyingGlass } from "react-icons/hi2";

export default function Calendar() {
  const [type, updateType] = useState("d");
  const [selectedDate, updateSelectedDate] = useState(new Date());
  const [content, updateContent] = useState("");
  const [popup, updatePopup] = useState();

  const typeSelection = useRef(null);

  useEffect(() => {
    Hooks.getData(`${API_ENV}/staff`, (staff) => {
      if (!staff || !staff.length) {
        return;
      }

      const contentArray = staff.map((staffMember, index) => (
        <CalendarRow
          key={`staff-${staffMember.id}-${index}`} // Assuming `staffMember.id` is unique
          type={type}
          engineer={staffMember}
          date={selectedDate}
        />
      ));
      updateContent(contentArray);
    });
  }, [type, selectedDate]);

  function leftArrowClick() {
    const date = selectedDate;
    updateSelectedDate(new Date(date.setDate(date.getDate() - 1)));
  }

  function rightArrowClick() {
    const date = selectedDate;
    updateSelectedDate(new Date(date.setDate(date.getDate() + 1)));
  }

  function typeChanged() {
    const type = Hooks.conditionalString(typeSelection.current.value);
    if (type === "month") {
      const date = selectedDate;
      updateSelectedDate(new Date(date.setDate(1)));
    } else {
      updateSelectedDate(new Date());
    }

    updateType(type);
  }

  function closePopup() {
    updatePopup();
  }

  function filterSearch() {
    function getListData(element, string) {
      if (!element) {
        return;
      }

      const listItems = element.querySelectorAll(".checkboxItem");
      if (!listItems || !listItems.length) {
        return;
      }

      const array = [];
      listItems.forEach((item) => {
        if (item.querySelector("input").checked) {
          array.push(string + item.getAttribute("dataid"));
        }
      });
      return array;
    }

    // Get Engineers
    const selectedEngineers = getListData(
      document.querySelector(".engineersList"),
      "EngineerIds="
    );
    // Get Contracts
    const selectedContracts = getListData(
      document.querySelector(".contractsList"),
      "ContractIds="
    );
    // Get Job Categories
    const selectedCategories = getListData(
      document.querySelector(".jobcategoriesList"),
      "JobCategoryIds="
    );
    // Get Job Types
    const selectedTypes = getListData(
      document.querySelector(".jobtypesList"),
      "JobTypeIds="
    );

    // Create URL
    const arrays = selectedEngineers.concat(
      selectedContracts,
      selectedCategories,
      selectedTypes
    );

    const scheduled =
      "&ScheduleDateFrom=" + new Date(selectedDate).toISOString();

    const queryType = `&QueryType=${typeSelection.current.value}`;

    const url =
      `${API_ENV}/visitcalendar/querybydate?` +
      arrays.join("&") +
      scheduled +
      queryType;

    // Update State
    Hooks.getData(url, (data) => {
      if (typeof data === "string") {
        Hooks.displayError(data);
      } else {
        const calendarRows = data.map((item, index) => (
          <CalendarRow
            key={`engineer-${item.engineerId}-${index}`} // Assuming `item.engineerId` is unique
            type={type}
            engineer={item.engineerId}
            date={item.scheduleDate}
          />
        ));
        updateContent(calendarRows);
      }
    });
  }

  function filterClicked() {
    updatePopup(
      <CalendarFilter onClose={closePopup} onSearch={filterSearch} />
    );
  }

  return (
    <div id="calendarContainer" className="calendarContainer">
      <PopupModal>{popup}</PopupModal>

      <div id="calendarMainHeader">
        <ArrowSelector
          date={selectedDate}
          type={type}
          leftClick={leftArrowClick}
          rightClick={rightArrowClick}
        />

        <select id="typeSelection" onChange={typeChanged} ref={typeSelection}>
          <option value="D">Day</option>
          <option value="W">Work Week</option>
          <option value="F">Full Week</option>
          <option value="4">Four Weeks</option>
          <option value="M">Month</option>
        </select>

        <div id="dateSearch">
          <input type="date" />
          <HiMagnifyingGlass />
        </div>

        <div id="calendarFilters" onClick={filterClicked}>
          Calendar Filters
        </div>
      </div>

      <div id="calendarHeaderContainer">
        <CalendarHeader type={type} date={selectedDate} />
      </div>

      {content}
    </div>
  );
}
