import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import "./deliveries.scss";

import Hooks from "../../../Hooks";
import OrderDetailsitem from "../PurchaseOrders/OrderDetailsItem";
import DeliveryLines from "../DeliveryLines/DeliveryLines";
import SidePanelRow from "../../sidePanel/SidePanelComponents/SidePanelRow";

export default function ViewDelivery({
  deliveryId,
  searchForPart,
  selectedPart,
  updatePopup,
  refresh,
}) {
  const [deliveryData, updateData] = useState();
  // commenting out "deliveryDetails" as it's not used & to remove unused-vars build warning
  const [, /*deliveryDetails*/ updateDetails] = useState();
  const [deliveryLines, updateLines] = useState({});
  const [selectedLine, updateSelectedtLine] = useState();

  useEffect(() => {
    if (!deliveryId) {
      updateLines([""]);
      return;
    }

    Hooks.getData(
      `${API_ENV}/dnLine?DeliveyNoteId=${deliveryId}`,
      (deliveryLines) => {
        updateSelectedtLine(deliveryLines[0]);
        updateLines(deliveryLines);
      }
    );

    Hooks.getData(
      `${API_ENV}/deliveryNote/${deliveryId}`,
      (deliveryData) => {
        if (!deliveryData || typeof deliveryData !== "object") {
          return;
        }

        updateData(deliveryData);
      }
    );

    Hooks.getData(
      `${API_ENV}/dnDetail?DeliveyNoteId=${deliveryId}`,
      (details) => {
        if (!details || !details.length) {
          updateDetails(<div id="noDetails">No details could be found.</div>);
          return;
        }

        const array = [];
        details.forEach((detail, index) => {
          array.push(
            <OrderDetailsitem
              key={`deliveryDetails_${index}`}
              type="delivery"
              data={detail}
            />
          );
        });
        updateDetails(array);
      }
    );
  }, [deliveryId]);

  return (
    <React.Fragment>
      <SidePanelRow title="Supplier" fullData={deliveryData} />
      <SidePanelRow
        title="Delivery Note Number"
        value={deliveryData?.deliveryNoteNumber}
      />
      <SidePanelRow title="Delivery Date" value={deliveryData?.deliveryDate} />
      <SidePanelRow title="Delivery Note State" fullData={deliveryData} />
      <SidePanelRow title="Warehouse Location" fullData={deliveryData} />

      <DeliveryLines
        refresh={refresh}
        selectedLine={selectedLine}
        changeLine={updateSelectedtLine}
        lines={deliveryLines}
        searchForPart={searchForPart}
        selectedPart={selectedPart}
        updatePopup={updatePopup}
      />
    </React.Fragment>
  );
}
