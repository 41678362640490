import React, { useState, useEffect, useCallback } from "react";
import InputOldNonRHF from "@ui/InputOldNonRHF";

export default function ViewPartCategory({ setFooterState, cleanURL }) {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    depedencies: {
      depedency1: null,
      depedency2: null,
    },
  });

  // HANDLE Functions
  const handleButton1 = useCallback(async () => {}, []);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Button1", click: handleButton1 },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, closeFunction, handleButton1]);

  // function handleModalClose() {
  //   setPopupContent();
  // }

  return (
    <>
      <div className="formContainer">
        <div className="formRow">
          <InputOldNonRHF
            label={"Part Category"}
            type={"text"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>

        {/* <div className="formRow">
          <DropdownList
            label={""}
            dropdownData={dropdownData.partsSubCategory1DD}
            formData={formData}
            setFormData={setFormData}
          />
        </div> */}
      </div>
    </>
  );
}
