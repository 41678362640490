import React from "react";
import Hooks from "../../../Hooks";
import DateTimePicker from "../../generic/DateTimePicker";
import Dropdown from "../../generic/Dropdown";
import API_ENV from "@config";

export default function CancelJobPopup({ data }) {
  // console.log("CancelJobPopup data:", data);

  function confirmClicked(data) {
    data.jobStateRefId = parseInt(
      document.querySelector("#jobstateInputContainer").getAttribute("data")
    );
    data.cancelReasonRefId = parseInt(
      document.querySelector("#cancelReasonInputContainer").getAttribute("data")
    );
    data.cancelDate = new Date(
      document.querySelector("#cancelJobDate").value
    ).toISOString();

    function thenFunction(response) {
      if (response.ok) {
        document.querySelector("#cancelJobPopup").style.display = "none";
        document.querySelector("#sidePanelContainer").style.display = "none";
      }
    }

    Hooks.sendData(
      data,
      `${API_ENV}/jobs/${data.id}`,
      "PUT",
      thenFunction
    );
  }

  function closeClicked() {
    document.querySelector("#cancelJobPopup").style.display = "none";
  }

  return (
    <div id="cancelJobPopup">
      <div id="cancelJobHeader">
        <h2>Cancel Job</h2>
      </div>
      <div id="cancelJobContent">
        <div className="cancelJobInput">
          <p>Cancel Reason</p>
          <Dropdown
            id="cancelReasonInput"
            className="sidePanelSelect"
            type={"cancelreasonjob"}
          />
        </div>
        <div className="cancelJobInput">
          <p>Cancel Date</p>
          <DateTimePicker id="cancelJobDate" value={new Date().toISOString()} />
        </div>
      </div>
      <div id="cancelJobFooter">
        <div
          id="cancelJobConfirm"
          className="cancelJobButton"
          onClick={() => confirmClicked(data)}
        >
          Confirm
        </div>

        <div
          id="cancelJobClose"
          className="cancelJobButton"
          onClick={closeClicked}
        >
          Close
        </div>
      </div>
    </div>
  );
}
