import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function SidePanelTabs({ tabs: tabsData }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // console.log("tabsData", tabsData);

  const handleClick = (tab, index, event) => {
    // console.log(`Clicked tab index: ${index}`); // Log the clicked tab index
    setActiveTabIndex(index);
    tab.click(event.target);
  };

  useEffect(() => {
    // console.log(`Active tab index updated: ${activeTabIndex}`); // Log the active tab index update
  }, [activeTabIndex]);

  if (!Array.isArray(tabsData) || tabsData.length === 0) {
    return null;
  }

  return (
    <div id="tabContainer">
      {tabsData.map((tab, index) => {
        if (!tab || typeof tab.click !== "function") {
          return null;
        }
        const className = index === activeTabIndex ? "tab currentTab" : "tab";

        // console.log(`Tab ${index} class: ${className}`); // Log the class name for each tab
        return (
          <div
            key={`sidePanelTab_${tab.text}`}
            className={className}
            onClick={(event) => handleClick(tab, index, event)}
          >
            {tab.text}
          </div>
        );
      })}
    </div>
  );
}

SidePanelTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      click: PropTypes.func.isRequired,
    })
  ).isRequired,
};
