import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";
import FileInput from "@ui/FileInput";
import Button from "@ui/Button";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    apiGroupId: yup.string().required("This field is required"),
    apiId: yup.string().required("This field is required"),
    ticketAssignedToId: yup.string().required("This field is required"),
    targetDate: yup.string().required("This field is required"),
    ticketTypeId: yup.string().required("This field is required"),
    issueDetail: yup.string().required("This field is required"),
  })
  .required();

export default function ViewTicket({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
    dropdownData3: [],
    dropdownData4: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      apiGroupId: "",
      apiId: "",
      fixedFlag: false,
      fixedDate: "",
      assignedTo: "",
      targetDate: "",
      ticketType: "",
      issueDetail: "",
      solution: "",
    },
  });
  const apiGroupId = watch("apiGroupId");

  // const { openModal } = useModal();
  const { data: stateData } = state;
  const attachmentPath = stateData.attachmentPath;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
    Hooks.getData(`${API_ENV}/Api?ApiGroupId=${apiGroupId}`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
    Hooks.getData(`${API_ENV}/Staff`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData3: data,
      }));
    });
    Hooks.getData(`${API_ENV}/TicketType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData4: data,
      }));
    });
  }, [apiGroupId]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        apiGroupId: stateData.apiGroupId || "",
        apiId: stateData.apiId || "",
        fixedFlag: stateData.fixedFlag,
        ticketAssignedToId: stateData.ticketAssignedToId || "",
        targetDate: stateData.targetDate || "",
        fixedDate: stateData.fixedDate || "",
        ticketTypeId: stateData.ticketTypeId || "",
        issueDetail: stateData.issueDetail || "",
        solution: stateData.solution || "",
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      if (!data.fixedDate) {
        delete data.fixedDate;
      }

      // console.log("apiUrl", apiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );

      // console.log("data", data);
      // console.log("data.fileAttachment", data.fileAttachment[0]);

      if (data.fileAttachment[0]) {
        // console.log("Test");
        Hooks.sendFileAttachment(
          data.fileAttachment[0],
          `${API_ENV}/ticket/uploadfile/${data.id}`,
          "PUT"
        );
      } else {
        // console.log("No file selected.");
      }
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleDownloadAttachment = useCallback(
    async (e) => {
      Hooks.downloadFile(
        `${API_ENV}/ticket/downloadfile/${stateData.id}`,
        stateData.attachmentPath
      );
    },
    [stateData.id, stateData.attachmentPath]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <DropdownList
            label={"API Group"}
            name="apiGroupId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            optionName="groupName"
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <DropdownList
            label={"Menu Path"}
            name="apiId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData2}
            optionName="menuPath"
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Ticket Type"}
            name="ticketTypeId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData4}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Assigned To"}
            name="ticketAssignedToId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData3}
            optionNames={["forename", "surname"]}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Target Date</label>
          <Controller
            name="targetDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimePicker
                classValue="input medium center"
                value={field.value}
                onChange={field.onChange}
                hideTime={false}
                error={fieldState.error}
              />
            )}
          />
        </SidePanelFormRow>

        <SidePanelFormRow></SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Fixed?"}
            name="fixedFlag"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <Input
            label={"Fixed Date"}
            name="fixedDate"
            control={control}
            errors={errors}
            readOnly
          />
        </SidePanelFormRow>

        <SidePanelFormRow type="full">
          <label>Issue Detail</label>
          <textarea
            {...register("issueDetail")}
            rows="5"
            className="input left"
          ></textarea>
          {errors.issueDetail && (
            <span className="fieldError">{errors.issueDetail.message}</span>
          )}
        </SidePanelFormRow>

        <SidePanelFormRow type="full">
          <label>Solution (Optional)</label>
          <textarea
            {...register("solution")}
            // rows="5"
            className="input left"
          ></textarea>
          {errors.solution && (
            <span className="fieldError">{errors.solution.message}</span>
          )}
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Upload File</label>
          <FileInput {...register("fileAttachment")} />
        </SidePanelFormRow>

        {attachmentPath && (
          <SidePanelFormRow>
            <label>Attached File: {stateData.fileName}</label>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleDownloadAttachment();
              }}
              style={{ marginTop: "-1.2rem", padding: "0.75rem" }}
            >
              Download
            </Button>
          </SidePanelFormRow>
        )}
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
