import API_ENV from "@config";
import React, { createContext, useContext, useState } from "react";
import Hooks from "../Hooks";

const AuthContext = createContext();

const initial2FADataValues = {
  loginData: {},
  primaryLogin: true,
  twoFARadioSelect: true,
  first2FASent: false,
  emailObfuscate: true,
};

export function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(isUserSignedIn);
  const [twoFAData, setTwoFAData] = useState(initial2FADataValues);

  // useEffect(() => {
  //   // console.log("isLoading:", isLoading);
  //   console.log("isAuthenticated:", isAuthenticated);
  // }, [isAuthenticated]);

  function isUserSignedIn() {
    if (
      Hooks.getCookie("jwt") === "" ||
      Hooks.getCookie("name") === "" ||
      Hooks.getCookie("userId") === "" ||
      Hooks.getCookie("signedIn") === ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  function obfuscateEmail(email) {
    const atIndex = email.indexOf("@");
    const firstPart = email.substring(0, 3);
    const lastPart = email.substring(atIndex - 3);
    const obfuscatedPart = "x".repeat(Math.max(0, atIndex - 6));

    return (
      <>
        <span className="unobfuscated">{firstPart}</span>
        <span className="obfuscated">{obfuscatedPart}</span>
        <span className="unobfuscated">{lastPart}</span>
      </>
    );
  }

  function obfuscatePhone(phoneNumber) {
    const lastPart = phoneNumber.substring(phoneNumber.length - 4);
    const obfuscatedPart = "x".repeat(Math.max(0, phoneNumber.length - 4));

    return (
      <>
        <span className="obfuscated">{obfuscatedPart}</span>
        <span className="unobfuscated">{lastPart}</span>
      </>
    );
  }

  // initial check for user credentials
  function login(data) {
    setIsLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${API_ENV}/Users/authenticate`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log("login():", data);

          if (data.twoFS === "Y") {
            setTwoFAData((prevData) => ({
              ...prevData,
              loginData: data,
              primaryLogin: false,
            }));
            setIsLoading(false);
          } else if (data.twoFS === "N") {
            if (data.jwtToken) {
              Hooks.setCookie("jwt", data.jwtToken, 1);
              Hooks.setCookie("userId", data.id, 1);
              Hooks.setCookie("name", `${data.foreName} ${data.surName}`, 1);
              Hooks.setCookie("pinned", "true", 1);
              Hooks.setCookie("signedIn", "true", 1);

              setTwoFAData((prevData) => ({
                ...prevData,
                primaryLogin: true,
              }));

              setIsAuthenticated(true);
              setIsLoading(false);
              resolve("success");
            } else {
              setIsLoading(false);
              reject("Incorrect username or password.");
            }
          }
        } else {
          setIsLoading(false);
          reject("Incorrect username or password.");
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        reject("A network error occurred. Please contact IT Support.");
      }
    });
  }

  // logs out user
  function logout() {
    Hooks.deleteCookies();
    setIsAuthenticated(false);
  }

  // Sends a request for user's choice of recieving 2FA code
  function twoFAMethod(data, resendFlag) {
    setIsLoading(true);
    return new Promise(async (resolve, reject) => {
      if (resendFlag === "N") {
        try {
          const response = await fetch(
            `${API_ENV}/LoginSession/SendMethod/${resendFlag}`,
            {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              credentials: "same-origin",
              headers: {
                "Content-Type": "application/json",
              },
              redirect: "follow",
              referrerPolicy: "no-referrer",
              body: JSON.stringify(data),
            }
          );

          if (response.ok) {
            setTwoFAData((prevData) => ({
              ...prevData,
              first2FASent: true,
              twoFARadioSelect: false,
            }));
            setIsLoading(false);
          } else {
            setIsLoading(false);
            reject("Please select a two factor authentication option.");
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          reject("A network error occurred. Please contact IT Support.");
        }
      } else if (resendFlag === "Y") {
        try {
          const response = await fetch(
            `${API_ENV}/LoginSession/SendMethod/${resendFlag}`,
            {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              credentials: "same-origin",
              headers: {
                "Content-Type": "application/json",
              },
              redirect: "follow",
              referrerPolicy: "no-referrer",
              body: JSON.stringify(data),
            }
          );

          if (response.ok) {
            setTwoFAData((prevData) => ({
              ...prevData,
              first2FASent: true,
              twoFARadioSelect: false,
            }));
            setIsLoading(false);
          } else {
            setIsLoading(false);
            reject("Please select a two factor authentication option.");
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          reject("A network error occurred. Please contact IT Support.");
        }
      }
    });
  }

  // 2FA login which afterwards allows entry into the system
  function twoFALogin(data) {
    setIsLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${API_ENV}/Users/authenticateby2fs`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log("twoFALogin():", data);

          if (data.passTwoFSFlag === true) {
            if (twoFAData.loginData.jwtToken) {
              Hooks.setCookie("jwt", twoFAData.loginData.jwtToken, 1);
              Hooks.setCookie("userId", twoFAData.loginData.id, 1);
              Hooks.setCookie(
                "name",
                `${twoFAData.loginData.foreName} ${twoFAData.loginData.surName}`,
                1
              );
              Hooks.setCookie("pinned", "true", 1);
              Hooks.setCookie("signedIn", "true", 1);

              setTwoFAData((prevData) => ({
                ...prevData,
                loginData: {},
                primaryLogin: true,
                twoFARadioSelect: true,
              }));

              setIsAuthenticated(true);
              setIsLoading(false);
              resolve("success");
            } else {
              setIsLoading(false);
              reject(
                "Incorrect two factor authentication code. Please try again."
              );
            }
          } else {
            setIsLoading(false);
            reject(data.responseMessage);
          }
        } else {
          setIsLoading(false);
          reject(data.responseMessage);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        reject("A network error occurred. Please contact IT Support.");
      }
    });
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        obfuscateEmail,
        obfuscatePhone,
        login,
        logout,
        twoFAData,
        setTwoFAData,
        twoFAMethod,
        twoFALogin,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error("useAuthContext was used outside of useAuthProvider");
  return context;
}
