import React from "react";

export default function ScheduleTimeRow({ day, start, end }) {
  let mouseDown = 0;

  function down() {
    mouseDown = 1;
  }

  function up() {
    mouseDown = 0;
  }

  function hoverTimeBlock(e) {
    if (mouseDown) {
      e.style.backgroundColor = "rgb(195, 217, 30)";
    }
  }

  function blockClick(e) {
    if (e.target.style.backgroundColor === "rgb(195, 217, 30)") {
      e.target.style.backgroundColor = "transparent";
    } else {
      e.target.style.backgroundColor = "rgb(195, 217, 30)";
    }
  }

  function timeBlock() {
    let array = [];
    for (let i = 0; i < 24; i++) {
      if (i >= start / 100 && i <= end / 100 && end !== 0) {
        array.push(
          <div
            key={`${day}-${i}`}
            className="timeBlock"
            style={{ backgroundColor: "rgb(195, 217, 30)" }}
            onMouseEnter={(e) => {
              hoverTimeBlock(e.target);
            }}
            onClick={(e) => {
              blockClick(e);
            }}
          ></div>
        );
      } else {
        if (day === "") {
          array.push(
            <div key={`${day}-${i}`} className="timeBlock">
              {i}
            </div>
          );
        } else {
          array.push(
            <div
              key={`${day}-${i}`}
              className="timeBlock"
              onMouseEnter={(e) => {
                hoverTimeBlock(e.target);
              }}
              onClick={(e) => {
                blockClick(e);
              }}
            ></div>
          );
        }
      }
    }
    return array;
  }

  return (
    <div
      id={`${day}Row`}
      className="scheduleTimeRow"
      onMouseDown={down}
      onMouseUp={up}
    >
      <div className="timeLabel">
        {day.charAt(0).toUpperCase() + day.slice(1)}
      </div>

      <div className="blockContainer">{timeBlock()}</div>
    </div>
  );
}
