import "../styles/pages/Login.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Heading from "../ui/Heading";
import Button from "../ui/Button";
import DarkModeToggle from "../ui/DarkModeToggle";
import InputOldNonRHF from "../ui/InputOldNonRHF";

export default function Login({ setIsLoggedIn }) {
  const {
    login,
    twoFAMethod,
    twoFALogin,
    twoFAData,
    setTwoFAData,
    obfuscateEmail,
    obfuscatePhone,
    isLoading,
  } = useAuth();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    twoFAMethod: "",
    twoFACode: "",
  });

  const handleLogin = async () => {
    try {
      setErrorMessage(null);
      await login({
        username: formData.username,
        password: formData.password,
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  const handle2FAMethod = async (resendFlag) => {
    if (resendFlag === "N") {
      try {
        setErrorMessage(null);
        await twoFAMethod(
          {
            username: formData.username,
            oneTimeCode: "",
            sendMethod: formData.twoFAMethod,
          },
          resendFlag
        );
        setTwoFAData((prevData) => ({
          ...prevData,
          twoFARadioSelect: false,
        }));
      } catch (error) {
        setErrorMessage(error);
      }
    } else if (resendFlag === "Y") {
      try {
        setErrorMessage(null);
        await twoFAMethod(
          {
            username: formData.username,
            oneTimeCode: "",
            sendMethod: formData.twoFAMethod,
          },
          resendFlag
        );
        setTwoFAData((prevData) => ({
          ...prevData,
          twoFARadioSelect: false,
        }));
      } catch (error) {
        setErrorMessage(error);
      }
    }
  };

  const handle2FALogin = async () => {
    try {
      setErrorMessage(null);
      await twoFALogin({
        username: formData.username,
        oneTimeCode: formData.twoFACode,
        sendMethod: formData.twoFAMethod,
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      setErrorMessage(error);
    }
  };

  return (
    <>
      <div className="loginWrapper">
        <div className="loginToggleHeader">
          <div className="loginDarkModeToggle">
            <DarkModeToggle />
          </div>
        </div>
        <div className="loginForm">
          {twoFAData.primaryLogin ? (
            <>
              <div className="loginHeader">
                <Heading as="h1">User Login</Heading>
                <Heading as="h4">Please enter username & password.</Heading>
              </div>

              <div className="loginInputContainer">
                <InputOldNonRHF
                  label="username"
                  name="username"
                  showLabel={false}
                  size="large"
                  placeholder="Username"
                  autoComplete="on"
                  maxLength="20"
                  formData={formData}
                  setFormData={setFormData}
                />

                <InputOldNonRHF
                  label={"password"}
                  name={"password"}
                  showLabel={false}
                  size="large"
                  type="password"
                  placeholder="Password"
                  autoComplete="on"
                  maxLength="20"
                  formData={formData}
                  setFormData={setFormData}
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() === "enter") {
                      handleLogin();
                    }
                  }}
                />
              </div>

              <Button size={"large"} onClick={handleLogin} disabled={isLoading}>
                Login
              </Button>

              {errorMessage && (
                <p className="loginError">Error: {errorMessage}</p>
              )}
            </>
          ) : (
            <>
              {twoFAData.twoFARadioSelect ? (
                <>
                  <div className="loginHeader">
                    <Heading as="h1">One-Time Passcode Required</Heading>
                    <Heading as="h4">
                      Please select an option to recieve your one-time passcode.
                    </Heading>
                  </div>

                  <div className="twoFAMethodContainer">
                    <div className="twoFAMethodRow">
                      <label htmlFor="email2FA">Via Email:</label>

                      <input
                        id="email2FA"
                        type="radio"
                        name="email2FAOption"
                        value="e"
                        checked={formData.twoFAMethod === "e"}
                        onChange={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFAMethod: "e",
                          }));
                          setTwoFAData((prevData) => ({
                            ...prevData,
                            emailObfuscate: true,
                          }));
                        }}
                      />
                    </div>

                    <div className="twoFAMethodRow">
                      <label htmlFor="phone2FA">Via Phone (SMS):</label>

                      <input
                        id="phone2FA"
                        type="radio"
                        name="phone2FAOption"
                        value="p"
                        checked={formData.twoFAMethod === "p"}
                        onChange={() => {
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFAMethod: "p",
                          }));
                          setTwoFAData((prevData) => ({
                            ...prevData,
                            emailObfuscate: false,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {!twoFAData.first2FASent ? (
                    <Button
                      disabled={isLoading}
                      size={"large"}
                      onClick={() => {
                        handle2FAMethod("N");
                      }}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      disabled={isLoading}
                      size={"large"}
                      onClick={() => {
                        handle2FAMethod("Y");
                      }}
                    >
                      Continue
                    </Button>
                  )}

                  {errorMessage && (
                    <div className="error">Error: {errorMessage}</div>
                  )}

                  <Button
                    size={"large"}
                    variation={"secondary"}
                    onClick={() => {
                      setTwoFAData((prevData) => ({
                        ...prevData,
                        primaryLogin: true,
                      }));
                    }}
                  >
                    Go back to username login.
                  </Button>
                </>
              ) : (
                <>
                  <div className="loginHeader">
                    <Heading as="h1">One-Time Passcode Required</Heading>

                    {twoFAData.emailObfuscate ? (
                      <Heading as="h4">
                        An email has been sent to:{" "}
                        {obfuscateEmail(twoFAData.loginData.email)}
                      </Heading>
                    ) : (
                      <Heading as="h4">
                        An SMS message has been sent to:{" "}
                        {obfuscatePhone(twoFAData.loginData.mobile)}
                      </Heading>
                    )}

                    <Heading as="h4">
                      Please enter your one-time passcode
                    </Heading>
                  </div>

                  <div id="twoFACodeContainer">
                    <div className="twoFAColumn">
                      <label htmlFor="twoFACode">Code Entry:</label>

                      <input
                        className="twoFACodeInputField"
                        type="text"
                        name="twoFACode"
                        maxLength="4"
                        pattern="[0-9]*" // Allows only numeric input
                        value={formData.twoFACode}
                        onChange={(e) => {
                          // Regular expression to allow only numeric input
                          const onlyNumbers = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );

                          // Limit the length to 4
                          const truncatedValue = onlyNumbers.slice(0, 4);

                          // Update the state with the sanitized value
                          setFormData((prevData) => ({
                            ...prevData,
                            twoFACode: truncatedValue,
                          }));
                        }}
                        onKeyDown={(event) => {
                          if (event.key.toLowerCase() === "enter") {
                            handle2FALogin();
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="twoFALoginButtons">
                    <Button
                      size={"large"}
                      disabled={isLoading}
                      onClick={() => handle2FALogin()}
                    >
                      Login
                    </Button>

                    {errorMessage && (
                      <div className="error">Error: {errorMessage}</div>
                    )}

                    <p>Need another code?</p>

                    <Button
                      size={"large"}
                      variation={"secondary"}
                      disabled={isLoading}
                      onClick={() => {
                        setTwoFAData((prevData) => ({
                          ...prevData,
                          twoFARadioSelect: true,
                        }));
                      }}
                    >
                      Request another 2FA code
                    </Button>
                  </div>
                </>
              )}
            </>
          )}

          {/* {errorMessage ? <div className="error">Error: {errorMessage}</div> : null} */}
        </div>
      </div>
    </>
  );
}
