import "../../styles/features/AccountPopup.scss";

import React, { useEffect, useState } from "react";

export default function MyAccountPopup({ staffDetails }) {
  const [content, updateContent] = useState({
    forename: "-",
    surname: "-",
    email: "-",
    mobile: "-",
  });

  useEffect(() => {
    try {
      updateContent(JSON.parse(staffDetails));
    } catch (e) {}
  }, [staffDetails]);

  function closeClicked() {
    document.querySelector("#myAccountPopup").style.display = "none";
  }

  return (
    <div id="myAccountPopup">
      <div id="myAccountHeader">
        <div id="myAccountTitle">My Account Details</div>
        <div id="myAccountClose" onClick={closeClicked}>
          X
        </div>
      </div>
      <div id="myAccountContent">
        <div className="myAccountItem">{content.forename}</div>
        <div className="myAccountItem">{content.surname}</div>
        <div className="myAccountItem">{content.email}</div>
        <div className="myAccountItem">{content.mobile}</div>
      </div>
    </div>
  );
}
