import React, { useEffect, useRef } from "react";
import "./popupmodal.scss";

export default function PopupModal({ children, onClose }) {
  const modal = useRef(null);

  useEffect(() => {
    if (modal && modal.current) {
      modal.current.style.display = children ? "flex" : "none";
    }
  }, [children]);

  return (
    <div id="popupModal" ref={modal} onClick={(e) => {}}>
      {children}
    </div>
  );
}
