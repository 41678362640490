import API_ENV from "@config";
import React, { useState, useEffect } from "react";
import Hooks from "../../../Hooks";
import NewDropdown from "../../generic/NewDropdown";

export default function JobStateType({
  jobTypeValue,
  jobStateValue,
  jobTypeUrl,
}) {
  const [jobStateUrl, updateStateUrl] = useState("");

  useEffect(() => {
    if (
      document.querySelector("#jobtypeInputContainer") &&
      Hooks.getSidePanelData()
    ) {
      const jobType = Hooks.getSidePanelData().jobTypeRefId;
      const secondId = Hooks.getSidePanelData()?.jobStateRefId || 0;

      if (!jobType) {
        return;
      }

      updateStateUrl(
        `${API_ENV}/jobState/jobType/${jobType}/${secondId}`
      );
    }
  }, [jobTypeValue, jobStateValue, jobTypeUrl]);

  function typeChanged() {
    setTimeout(() => {
      const jobType = document
        .querySelector("#jobtypeDropdown")
        .getAttribute("data");

      const secondId =
        Hooks.getSidePanelData()?.jobStateRefId ||
        Hooks.getSidePanelData()?.currJobStateId ||
        0;

      updateStateUrl(
        `${API_ENV}/jobState/jobType/${jobType}/${secondId}`
      );
    }, 250);
  }

  return (
    <>
      <div className="sidePanelRow">
        <div className="sidePanelKey">Job Type</div>
        <div className="rowInputContainer">
          <NewDropdown
            type="jobtype"
            key={jobTypeValue.label}
            topValue={jobTypeValue}
            onChange={typeChanged}
            customUrl={jobTypeUrl}
          />
        </div>
      </div>
      <div className="sidePanelRow">
        <div className="sidePanelKey">Job State</div>
        <div className="rowInputContainer">
          <NewDropdown
            type="jobstate"
            key={jobStateValue.label}
            topValue={jobStateValue}
            onChange={typeChanged}
            customUrl={jobStateUrl}
          />
        </div>
      </div>
    </>
  );
}
