import API_ENV from "@config";
import "../../styles/features/Dashboard.scss";

import Hooks from "../../Hooks";
import React, { useEffect, useState } from "react";
import RecentJobs from "./RecentJobs";
import { PieChart } from "react-minimal-pie-chart";
import DisplayStatistic from "./DisplayStatistic";
import MyAccountPopup from "./MyAccountPopup";
import { HiOutlineUserCircle } from "react-icons/hi2";

function getTime() {
  const date = new Date();
  const isMorning = date.getHours() >= 12 ? "PM" : "AM";
  return date.toLocaleTimeString() + " " + isMorning;
}

export default function Dashboard() {
  const [time, setTime] = useState(getTime());
  const [contractCount, setContracts] = useState(0);
  const [engineerCount, setEngineers] = useState(0);
  const [clientCount, setClients] = useState(0);
  const [warehouseCount, setWarehouses] = useState(0);
  // commenting out "setJobsToday" as it's not used & to remove unused-vars build warning
  const [jobsToday /*setJobsToday*/] = useState(0);
  const [recentJobs, setRecentJobs] = useState();
  const [staffDetails, setStaffDetails] = useState({});

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status
    const intervalId = setInterval(() => {
      if (isMounted) setTime(getTime());
    }, 1000);

    const fetchData = async (url, updateStateCallback) => {
      try {
        const data = await Hooks.getData(url);
        if (isMounted && data && data.length && typeof data !== "string") {
          updateStateCallback(data.length);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData(`${API_ENV}/contracts`, setContracts);

    fetchData(
      `${API_ENV}/staff?StaffTypeId=3`,
      setEngineers
    );

    fetchData(`${API_ENV}/clients`, setClients);

    fetchData(`${API_ENV}/warehouses`, setWarehouses);

    fetchData(
      `${API_ENV}/jobs?DaysMostRecentlyCreated=7`,
      setRecentJobs
    );

    fetchData(
      `${API_ENV}/staff/${Hooks.getCookie("userId")}`,
      setStaffDetails
    );

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
      clearInterval(intervalId); // Clear the interval
    };
  }, []);

  function imageClicked() {
    document.querySelector("#myAccountPopup").style.display = "flex";
  }

  return (
    <div id="dashboardContainer" className="dashboardContainer" data-tip="2222">
      <MyAccountPopup staffDetails={staffDetails} />
      <div id="dashboardHeader">
        <div id="headerTitle">
          <HiOutlineUserCircle id="userIcon" size={35} onClick={imageClicked} />
        </div>
        <div id="headerTime">{time}</div>
      </div>
      <div id="dashboardWidgets">
        <DisplayStatistic text="Jobs Assigned To You" value="21" />
        <DisplayStatistic text="Number of Engineers" value={engineerCount} />
        <DisplayStatistic text="Live Contracts" value={contractCount} />
        <DisplayStatistic text="Clients" value={clientCount} />
        <DisplayStatistic text="Jobs On Today" value={jobsToday} />
        <DisplayStatistic text="Warehouses" value={warehouseCount} />
      </div>

      <div id="dashboardContent">
        <RecentJobs data={recentJobs} />
        <div id="pieChartContainer">
          <div id="pieChartHeader">Jobs By Job Type</div>
          <div id="pieChart">
            <PieChart
              viewBoxSize={[100, 110]}
              radius={40}
              label={(props) => {
                return props.dataEntry.title;
              }}
              data={[
                { title: "Gas Jobs (26)", value: 26, color: "#E38627" },
                { title: "Electrical Jobs (30)", value: 30, color: "#C13C37" },
                { title: "Other jobs (15)", value: 15, color: "#6A2135" },
              ]}
            />
            <div className="pieChartText">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
              </p>
              <div className="pieKey">
                <div className="firstBlock block"></div>
                <label className="pieLabel">Gas Jobs</label>
              </div>
              <div className="pieKey">
                <div className="secondBlock block"></div>
                <label className="pieLabel">Electrical Jobs</label>
              </div>
              <div className="pieKey">
                <div className="thirdBlock block"></div>
                <label className="pieLabel">Other Jobs</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
