import API_ENV from "@config";
import "../styles/ui/Tags.scss";

import { HiMiniMinus } from "react-icons/hi2";
import Button from "./Button";
import { useEffect, useState } from "react";
import Hooks from "@hooksFile";
import Swal from "sweetalert2";

import TagsSearchBar from "./TagsSearchBar";

export default function Tags({
  objectDocTypeId,
  objectDocId,
  objectDocIdArray,
  isModal,
  setTagFormData,
}) {
  const [tagData, setTagData] = useState([]);

  // useEffect(() => {
  //   console.log("tagData:", tagData);
  //   // console.log("objectDocTypeId:", objectDocTypeId);
  //   // console.log(
  //   //   `objectDocTypeId: ${objectDocTypeId} objectDocId: ${objectDocId} `
  //   // );
  // }, [tagData]);

  useEffect(() => {
    if (setTagFormData) {
      setTagFormData(tagData);
    }
  }, [setTagFormData, tagData]);

  useEffect(() => {
    if (!isModal) {
      if (objectDocTypeId && objectDocId) {
        Hooks.getData(
          `${API_ENV}/tagsDoc/byObject/${objectDocTypeId}/${objectDocId}`,
          (data) => {
            // console.log("Tag Data:", data);
            setTagData(data);
          }
        );
      }
    } else {
    }
  }, [isModal, objectDocTypeId, objectDocId]);

  function handleRemoveTag(id) {
    // console.log("Remove function: ID - ", id);

    if (!isModal) {
      Swal.fire({
        title: `Delete Tag`,
        text: `Are you sure you wish to delete this tag? You can re-add tags after deletion.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          function thenFunction(response) {
            const updatedData = tagData.filter((tag) => tag.id !== id);
            setTagData(updatedData);
          }

          Hooks.sendData(
            {},
            `${API_ENV}/tagsDoc/${id}`,
            "DELETE",
            thenFunction,
            false,
            "Tag Deleted"
          );
        }
      });
    } else {
      const updatedData = tagData.filter((tag) => tag.id !== id);
      setTagData(updatedData);
    }
  }
  return (
    <>
      <TagsSearchBar
        title="Tags"
        type="tags"
        objectDocTypeId={objectDocTypeId}
        objectDocId={objectDocId}
        setOutsideFormData={setTagData}
        isModal={isModal}
      />

      <div className="tagsContainer">
        <div className="tagItemContainer">
          {tagData && tagData.length > 0 ? (
            tagData.map((tagItem, index) => (
              <div key={index} className="tagItem">
                <label className="taglabel">
                  {tagItem?.tags?.name || tagItem?.name || "No name found!"}
                </label>

                <Button
                  size={"smallest"}
                  type="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveTag(tagItem?.id);
                  }}
                >
                  <HiMiniMinus />
                </Button>
              </div>
            ))
          ) : (
            <label className="noTaglabel">No Tags</label>
          )}
        </div>
      </div>
    </>
  );
}
