import "../styles/ui/Modal.scss";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Button from "./Button";
import Heading from "./Heading";
import { HiXMark } from "react-icons/hi2";
import ReactDOM from "react-dom";

const ModalContext = createContext();

function ModalProvider({ children }) {
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <ModalContext.Provider value={{ activeModal, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
}

function Modal({ children, name, title, size }) {
  const { activeModal, closeModal } = useContext(ModalContext);
  const modalRef = useRef();

  // Close modal if clicked outside of it
  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    // Attach event listener to the document to capture all clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  if (activeModal !== name) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="overlay" onClick={handleClickOutside}>
      <div className={`modal ${size}`} ref={modalRef}>
        <div className="modalHeader">
          <Heading className="heading h1 modalTitle">{title}</Heading>

          <div className="buttonContainer">
            <Button size={"medium"} type="danger" onClick={closeModal}>
              <HiXMark />
            </Button>
          </div>
        </div>

        {children}
      </div>
    </div>,
    document.body
  );
}

function ModalTrigger({ children, name, onOpen, onClose, type = "" }) {
  const { openModal } = useContext(ModalContext);

  function handleClick() {
    openModal(name);
    if (onOpen) {
      onOpen();
    }
  }

  return (
    <div className={`modalTrigger ${type}`} onClick={handleClick}>
      {children}
    </div>
  );
}

function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined)
    throw new Error("ModalContext was used outside of ModalProvider");
  return context;
}

export { Modal, ModalProvider, ModalTrigger, useModal };
