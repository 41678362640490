import React from "react";

export default function AddressInputOldNonRHF({ data = {}, readOnly }) {
  const {
    address1 = "",
    address2 = "",
    address3 = "",
    address4 = "",
    town = "",
    county = "",
    postCode = "",
    phone = "",
  } = data;

  function checkPostCode(target) {
    function isValidUKPostcode(postcode) {
      // Regular expression pattern for UK postcode validation
      // const postcodeRegex =
      //   /^([A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}|GIR\s?0AA)$/;

      const postcodeRegex =
        /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i;

      // Remove any whitespace from the postcode
      const formattedPostcode = postcode.replace(/\s/g, "");

      // Check if the postcode matches the regex pattern
      return postcodeRegex.test(formattedPostcode);
    }

    if (isValidUKPostcode(target.value)) {
      target.classList.remove("badInput");
      target.classList.add("validInput");
    } else {
      target.classList.remove("validInput");
      target.classList.add("badInput");
    }
  }

  function checkAddressOne(target) {
    if (target.value) {
      target.classList.remove("badInput");
      target.classList.add("validInput");
    } else {
      target.classList.remove("validInput");
      target.classList.add("badInput");
    }
  }

  function checkTownCounty(target) {
    function hasOnlyAlphabeticCharacters(inputString) {
      const pattern = /^[a-zA-Z\s]+$/;
      return pattern.test(inputString);
    }

    if (hasOnlyAlphabeticCharacters(target.value)) {
      target.classList.remove("badInput");
      target.classList.add("validInput");
    } else {
      target.classList.remove("validInput");
      target.classList.add("badInput");
    }
  }

  return (
    <div id="addressContainer">
      <div className="addressEntry">
        <div> Address 1 </div>
        <div className="addressInputContainer">
          <input
            id="address1Input"
            onChange={(e) => {
              checkAddressOne(e.target);
            }}
            type="text"
            key={address1 || "EmptyAddress1"}
            defaultValue={address1}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Address 2 </div>
        <div className="addressInputContainer">
          <input
            id="address2Input"
            type="text"
            key={address2 || "EmptyAddress2"}
            defaultValue={address2}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Address 3 </div>
        <div className="addressInputContainer">
          <input
            id="address3Input"
            type="text"
            key={address3 || "EmptyAddress3"}
            defaultValue={address3}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Address 4 </div>
        <div className="addressInputContainer">
          <input
            id="address4Input"
            type="text"
            key={address4 || "EmptyAddress4"}
            defaultValue={address4}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Town </div>
        <div className="addressInputContainer">
          <input
            id="townInput"
            onChange={(e) => {
              checkTownCounty(e.target);
            }}
            type="text"
            key={town || "EmptyTown"}
            defaultValue={town}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> County </div>
        <div className="addressInputContainer">
          <input
            id="countyInput"
            onChange={(e) => {
              checkTownCounty(e.target);
            }}
            type="text"
            key={county || "EmptyCounty"}
            defaultValue={county}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Postcode </div>
        <div className="addressInputContainer">
          <input
            id="postcodeInput"
            onChange={(e) => {
              checkPostCode(e.target);
            }}
            type="text"
            key={postCode || "EmptyPostcode"}
            defaultValue={postCode}
            readOnly={readOnly}
          />
        </div>
      </div>
      <div className="addressEntry">
        <div> Phone </div>
        <div className="addressInputContainer">
          <input
            id="phoneInput"
            type="text"
            key={phone || "Emptyphone"}
            defaultValue={phone}
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
}
