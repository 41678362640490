import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hooks from "../../../Hooks";
import "./assignedApis.scss";
import Button from "@ui/Button";

const RowItem = ({ data, onUpdate }) => {
  const { id } = data;
  const apiGroupName = data.runReportList?.apiGroup?.groupName || "";
  const apiName = data.runReportList?.name || "";

  const [permissions, setPermissions] = useState({
    allowToRunOnline: data.allowToRunOnline,
    allowToRunAsFile: data.allowToRunAsFile,
    allowToRunAsEmail: data.allowToRunAsEmail,
  });

  useEffect(() => {
    setPermissions({
      allowToRunOnline: data.allowToRunOnline,
      allowToRunAsFile: data.allowToRunAsFile,
      allowToRunAsEmail: data.allowToRunAsEmail,
    });
  }, [data]);

  const handlePermissionChange = (permission) => (event) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: event.target.checked,
    }));
  };

  const handleUpdateClick = () => {
    onUpdate(data, permissions);
  };

  return (
    <tr key={id}>
      <td>{apiGroupName}</td>
      <td>{apiName}</td>
      <td>
        <div className="permissionsInput">
          {["allowToRunOnline", "allowToRunAsFile", "allowToRunAsEmail"].map(
            (perm) => (
              <div className="checkboxRow" key={perm}>
                <div>
                  Allowed To{" "}
                  {perm
                    .replace("allowToRun", "")
                    .replace(/([A-Z])/g, " $1")
                    .trim()}
                </div>
                <input
                  className={perm}
                  type="checkbox"
                  checked={permissions[perm]}
                  onChange={handlePermissionChange(perm)}
                />
              </div>
            )
          )}
        </div>
      </td>
      <td>
        <div className="buttonsContainer">
          <Button size="small" onClick={handleUpdateClick}>
            Update
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => console.log("Delete clicked")}
          >
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};

RowItem.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const AssignedReport = ({ data }) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      setReports(data);
    } else {
      setReports([]);
    }
  }, [data]);

  const handleUpdate = (reportData, permissions) => {
    const { id, runReportListId } = reportData;

    const newData = {
      id,
      roleId: Hooks.getSidePanelData().id,
      runReportListId,
      ...permissions,
    };

    Hooks.sendData(newData, `${API_ENV}/roleToRunReportList/${id}`, "PUT");
  };

  return (
    <div className="assignedApisContainer">
      <table>
        <thead>
          <tr>
            <th>Group Name</th>
            <th>Report Name</th>
            <th>Permissions</th>
            <th>Update/Delete</th>
          </tr>
        </thead>
        <tbody>
          {reports.length > 0 ? (
            reports.map((report) => (
              <RowItem key={report.id} data={report} onUpdate={handleUpdate} />
            ))
          ) : (
            <tr>
              <td colSpan="4" id="noApi">
                No reports assigned to this role.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

AssignedReport.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AssignedReport;
