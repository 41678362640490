import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import AddPopup from "./AddPopup/AddPopup";
import HistoryPopup from "./HistoryPopup/HistoryPopup";
import "./notesTodo.scss";
import SingleNote from "./SingleNote";

export default function NotesTodo({ jobId }) {
  const [notesContent, updateNotes] = useState();
  const [todoContent, updateTodo] = useState();
  const [addPopupType, updateAddType] = useState("");

  function getContent(data) {
    if (data && data.length) {
      let array = [];
      data.forEach((item) => {
        array.push(<SingleNote key={item.id} content={item} />);
      });
      if (array.length) {
        return array;
      }
    } else {
      return <SingleNote content="No Data" />;
    }
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async (url, setStateCallback) => {
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (isMounted) {
          setStateCallback(getContent(data));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (jobId) {
      fetchData(
        `${API_ENV}/notes/job/${jobId}`,
        updateNotes
      );
      fetchData(
        `${API_ENV}/todo/job/${jobId}`,
        updateTodo
      );
    }

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [jobId]);

  function addNewNote() {
    updateAddType("notes");
    document.querySelector("#addPopup").style.display = "flex";
  }

  function addNewTodo() {
    updateAddType("todo");
    document.querySelector("#addPopup").style.display = "flex";
  }

  function addConfirmed(type) {
    if (type && type.length) {
      const titleText = Hooks.conditionalString(
        document.querySelector("#titleContainerText").textContent
      );
      const jobId =
        titleText === "properties"
          ? JSON.parse(document.querySelector(".selected").getAttribute("data"))
              .id
          : Hooks.getSidePanelData().id;

      if (type === "notes") {
        const data = document.querySelector("#noteInput").value;

        Hooks.sendData(
          data,
          `${API_ENV}/notes/job/${jobId}/${parseInt(
            document.querySelector("#actionSelect").value
          )}`,
          "POST",
          (response) => {
            if (response.ok) {
              Hooks.getData(
                `${API_ENV}/notes/job/${jobId}`,
                (data) => {
                  updateNotes(getContent(data));
                  document.querySelector("#addPopup").style.display = "none";
                }
              );
            }
          }
        );
      } else if (type === "todo") {
        const text = document.querySelector("#todoInput").value;
        Hooks.sendData(
          text,
          `${API_ENV}/todo/job/${jobId}`,
          "POST",
          (response) => {
            if (response.ok) {
              Hooks.getData(
                `${API_ENV}/todo/job/${jobId}`,
                (data) => {
                  updateTodo(getContent(data));
                  document.querySelector("#addPopup").style.display = "none";
                }
              );
            }
          }
        );
      }
    }
  }

  function viewHistory() {
    document.querySelector("#historyPopup").style.display = "flex";
  }

  return (
    <>
      <AddPopup type={addPopupType} confirmClick={addConfirmed} />
      <HistoryPopup jobId={Hooks.getSidePanelData()?.id} />
      <div id="notesContainer">
        <div id="notesHeader">
          <div className="subHeader notes">
            <div className="historyButton" onClick={viewHistory}>
              <span>History</span>
            </div>
            <div className="subHeaderTitle">Notes</div>
            <div className="subHeaderPlus" onClick={addNewNote}>
              <span>+</span>
            </div>
          </div>
          <div className="subHeader todo">
            <div className="subHeaderTitle">To Do</div>
            <div className="subHeaderPlus" onClick={addNewTodo}>
              <span>+</span>
            </div>
          </div>
        </div>
        <div id="contentContainer">
          <div id="notesContent">{notesContent}</div>
          <div id="todoContent">{todoContent}</div>
        </div>
      </div>
    </>
  );
}
