import React from "react";
import Hooks from "../../Hooks";
import ReactTooltip from "react-tooltip";

import Button from "../../ui/Button";

const iconMappings = {
  add: "HiMiniPlus",
  runreport: "HiMiniPlus",
  refresh: "HiArrowPath",
  filter: "HiOutlineFunnel",
  live: "HiOutlineArchiveBox",
  archived: "HiOutlineArchiveBox",
  warehouse: "HiOutlineArchiveBox",
};

const tooltipMappings = {
  add: "Add a new entry.",
  refresh: "Refresh displayed data.",
  live: "Switch between live and archived data.",
  filter: "Filter displayed data.",
  runreport: "Run a new report.",
};

export default function OperationsButtons({ buttons }) {
  if (!Array.isArray(buttons) || !buttons.length) {
    console.error("An error occurred loading the header buttons.");
    return null;
  }

  return (
    <div id="operationsButtons">
      {buttons.map((item, index) => (
        <React.Fragment key={`button_${index}`}>
          <ReactTooltip
            id={`tooltip_${index}`}
            effect="solid"
            type="light"
            place="bottom"
          />
          <Button
            size={"extraSmall"}
            id={`${Hooks.conditionalString(item.text).toLowerCase()}Button`}
            dataTip={tooltipMappings[Hooks.conditionalString(item.text)]}
            icon={iconMappings[Hooks.conditionalString(item.text)]}
            onClick={item?.onClick}
          >
            {item.text}
          </Button>
        </React.Fragment>
      ))}
    </div>
  );
}
