import API_ENV from "@config";
import React, { useRef, useState } from "react";
import Hooks from "../../../Hooks";

// Todo, redo this component.
export default function SearchForPart({ type, entryClicked }) {
  const [content, setContent] = useState(
    <div>Use search bar above to find {formatType(type)}.</div>
  );
  const sidePanelId = Hooks.getSidePanelData()?.id;
  const searchBar = useRef(null);

  function itemClicked(target) {
    // console.log("target:", target);

    if (entryClicked) {
      entryClicked(target);
    }

    if (
      target &&
      Hooks.conditionalString(
        document.querySelector("#titleContainerText").textContent
      ) !== "purchaserequests"
    ) {
      switch (type) {
        case "stock":
          document
            .querySelector("#aisleInput")
            .setAttribute(
              "data",
              target.querySelector(".contentListId").textContent
            );
          document.querySelector("#aisleInput").value =
            target.querySelector(".contentListAisle").textContent;
          document.querySelector("#rackInput").value =
            target.querySelector(".contentListRack").textContent;
          document.querySelector("#levelInput").value =
            target.querySelector(".contentListLevel").textContent;
          break;
        case "supplier":
          document
            .querySelector("#suppliernameInput")
            .setAttribute(
              "data",
              target.querySelector(".contentListId").textContent
            );
          document.querySelector("#suppliernameInput").value =
            target.querySelector(".contentListName").textContent;
          document.querySelector("#supplierpartnoInput").value =
            target.querySelector(".contentListPartNo").textContent;
          break;
        case "sor":
          document.querySelector("#sorcode").value =
            target.querySelector(".contentListNo").textContent;
          document.querySelector("#sordescription").value =
            target.querySelector(".contentListDesc").textContent;
          break;
        case "equipmenttypesearch":
          document
            .querySelector("#equiptypeInput")
            .setAttribute(
              "data",
              target.querySelector(".contentListIdHidden").textContent
            );
          document.querySelector("#equiptypeInput").value =
            target.querySelector(".contentListName").textContent;
          document.querySelector("#makeInput").value =
            target.querySelector(".contentListMake").textContent;
          document.querySelector("#modelInput").value =
            target.querySelector(".contentListModel").textContent;
          break;
        case "part":
          document
            .querySelector("#partid")
            .setAttribute(
              "data",
              target.querySelector(".contentListId").textContent
            );
          document.querySelector("#partid").value =
            target.querySelector(".contentListId").textContent;
          document.querySelector("#partnumber").value =
            target.querySelector(".contentListNo").textContent;
          document.querySelector("#partdescription").value =
            target.querySelector(".contentListDesc").textContent;
          break;
        default:
          break;
      }
    }

    if (searchBar.current) {
      searchBar.current.value = "";
    }

    setContent(<div>Use search bar above to find {formatType(type)}.</div>);
  }

  function contentItem(data) {
    if (type === "stock") {
      // console.log(data);
      return (
        <div
          className="partContentItem"
          key={data.id}
          onClick={(e) => {
            itemClicked(e.target.parentElement);
          }}
        >
          <div className="contentListId">{data.id}</div>
          <div className="contentListAisle">{data.stockLocation.aisle}</div>
          <div className="contentListRack">{data.stockLocation.rack}</div>
          <div className="contentListLevel">{data.stockLocation.level}</div>
        </div>
      );
    } else if (type === "supplier") {
      console.log(data);
      return (
        <div
          className="partContentItem"
          key={data.id}
          onClick={(e) => {
            itemClicked(e.target.parentElement);
          }}
        >
          <div className="contentListId">{data.id}</div>
          <div className="contentListName">{data.name}</div>
          <div className="contentListPartNo">{data?.parts?.partno}</div>
          <div className="contentListPostCode">{data?.postCode}</div>
        </div>
      );
    } else if (type === "equipmenttypesearch") {
      return (
        <div
          className="partContentItem"
          key={data.id}
          onClick={(e) => {
            itemClicked(e.target.parentElement);
          }}
        >
          <div className="contentListIdHidden">{data.id}</div>
          <div className="contentListName">{data.equipmentCategory.name}</div>
          <div className="contentListMake">{data.make}</div>
          <div className="contentListModel">{data.model}</div>
        </div>
      );
    } else if (type === "sor" || type === "part") {
      return (
        <div
          className="partContentItem"
          key={data.id}
          onClick={(e) => {
            itemClicked(e.target.parentElement);
          }}
        >
          <div className="contentListId">{data.id}</div>
          <div className="contentListNo">{data.partno}</div>
          <div className="contentListDesc">{data.description}</div>
        </div>
      );
    }
  }

  function onType(value) {
    if (value.length < 2) {
      setContent(<div>Use search bar above to find {formatType(type)}.</div>);
      return;
    }

    if (value) {
      let urls = {
        sor: `${API_ENV}/Sor?General=${value}&showarchive=false`,
        // equipmenttypesearch: `${API_ENV}/equipmentType?Make=${value}`,
        equipmenttypesearch: `${API_ENV}/equipmentType?General=${value}&PropertyId=${sidePanelId}&showarchive=false`,
        part: `${API_ENV}/parts?PartNo=${value}`,
        supplier: `${API_ENV}/suppliers?Name=${value}`,
        stock: `${API_ENV}/stock?PartNo=${value}`,
      };

      Hooks.getData(urls[type], (data) => {
        // console.log("data:", data);

        if (data && data.length && typeof data !== "string") {
          let array = [];

          data.forEach((part) => {
            array.push(contentItem(part));
          });

          setContent(array);
        } else {
          setContent(<div className="noResults">No results found.</div>);
        }
      });
    } else {
      setContent(<div className="noResults">No results found.</div>);
    }
  }

  function formatType(string) {
    return string === "equipmenttypesearch" ? "equipment type" : string;
  }

  return (
    <div id="partSearch">
      <div id="partInput">
        <input
          ref={searchBar}
          id="searchForPart"
          type="text"
          placeholder={`Search for ${formatType(type)}...`}
          onChange={(e) => onType(e.target.value)}
        />
      </div>

      <div id="partList">
        {content.length > 0 && type === "equipmenttypesearch" && (
          <div className="contentListColumnHeaders">
            <div className="CLHeader">Equipment Category</div>
            <div className="CLHeader">Make</div>
            <div className="CLHeader">Model</div>
          </div>
        )}
        {content}
      </div>
    </div>
  );
}
