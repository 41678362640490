import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../../Hooks";
import Form from "../../../../ui/Form";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import Input from "../../../../ui/Input";
import DropdownList from "../../../../ui/DropdownList";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    make: yup.string().required("This field is required"),
    model: yup.string().required("This field is required"),
    equipmentCategoryRefId: yup.string().required("This field is required"),
    equipmentSubCategory1RefId: yup.string().required("This field is required"),
  })
  .required();

export default function ViewEquipmentType({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      make: "",
      model: "",
      equipmentCategoryRefId: "",
      equipmentSubCategory1RefId: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  useEffect(() => {
    console.log("stateData:", stateData);
    // console.log("dropdownData:", dropdownData);
  }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/equipmentCategory`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData1: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/EquipmentSubCategory1`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData2: data,
        }));
      }
    );
  }, []);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        make: stateData.make,
        model: stateData.model,
        equipmentCategoryRefId: stateData.equipmentCategoryRefId,
        equipmentSubCategory1RefId: stateData.equipmentSubCategory1RefId,
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("dataSubmit", data);
      // console.log("apiUrl", apiUrl);
      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Make"} name="make" control={control} errors={errors} />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Model"}
            name="model"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Equipment Category"}
            name="equipmentCategoryRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Equipment Subcategory"}
            name="equipmentSubCategory1RefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData2}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
