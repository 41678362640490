import API_ENV from "@config";
import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";

import CollapsibleGroup from "@ui/CollapsibleGroup";
import ContentList from "features/forms/MultiContentForm/Components/ContentList";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    qty: yup
      .number()
      .required("This field is required")
      .typeError("Quantity must be a number"),

    // For conditional fields which only appear based on another fields value
    // field2: yup.string().when("field1", {
    //   is: "value",
    //   then: () => yup.string().required("This field is required"),
    //   otherwise: () => yup.string().notRequired(),
    // }),
  })
  .required();

export default function ViewJobSors({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  // const [dropdownData, setDropdownData] = useState({
  //   dropdownData1: [],
  // });
  const [contentListData, setContentListData] = useState([]);

  const { tableRowId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sorCode: "",
      shortDesc: "",
      uomId: "",
      uomName: "",
      rate: "",
      qty: "",
      subTotal: 0,
      sorRefId: 0,
    },
  });

  const qty = watch("qty");
  const rate = watch("rate");
  const sorRefId = watch("sorRefId");

  // Calculate subTotal whenever qty or rate changes
  useEffect(() => {
    const subTotal = qty && rate ? qty * rate : 0;
    setValue("subTotal", subTotal);
  }, [qty, rate, setValue]);

  // const { openModal } = useModal();
  // const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (contentListData && contentListData.length > 0) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: contentListData[0].id,
        // * BOOLEANS need || "" removed!
        sorCode: contentListData[0]?.sor.sorCode || "",
        shortDesc: contentListData[0]?.sor.shortDesc || "",
        uomId: contentListData[0]?.sor.uomId || "",
        uomName: contentListData[0]?.sor.uom.name || "",
        rate: contentListData[0]?.sor.rate || "",
        qty: contentListData[0]?.qty || "",
        subTotal: contentListData[0]?.subTotal || "",
        sorRefId: contentListData[0]?.sorRefId || "",
      });
    }
  }, [contentListData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  // useEffect(() => {
  //   // ! TO DO
  //   Hooks.getData(`${API_ENV}/`, (data) => {
  //     // console.log("useEffect API 1 data:", data);
  //     setDropdownData((prevData) => ({
  //       ...prevData,
  //       dropdownData1: data,
  //     }));
  //   });
  // }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        // cleanURL();
        Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
          setContentListData(data);
        });
      }

      const dataObject = {
        id: data.id,
        jobRefId: Number(tableRowId),
        sorRefId: data.sorRefId,
        // sorCode: data.sorCode,
        // shortDesc: data.shortDesc,
        // uomId: data.uomId,
        // uomName: data.uomName,
        // rate: data.rate,
        qty: data.qty,
        // subTotal: data.subTotal,
      };

      console.log("handleSubmitForm data:", data);
      console.log("handleSubmitForm dataObject:", dataObject);

      Hooks.sendData(
        dataObject,
        `${API_ENV}/jobsor/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, tableRowId]
  );

  const handleArchive = useCallback(() => {
    Swal.fire({
      title: "Archive item?",
      text: "Are you sure you wish to archive this item? This cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        function thenFunction(response) {
          Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
            setContentListData(data);
          });
        }

        Hooks.sendData(
          {},
          `${API_ENV}/jobsor/${tableRowId}/${sorRefId}`,
          "DELETE",
          thenFunction
        );
      }
    });
  }, [tableRowId, sorRefId]);

  const handleAddSorToJob = useCallback(() => {
    setFormState({ type: "addsortojob", data: {} });
  }, [setFormState]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Add SOR To Job", click: handleAddSorToJob },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleAddSorToJob,
    handleArchive,
    // handleOpenModal,
  ]);

  useEffect(() => {
    // API GET contentList data
    Hooks.getData(`${API_ENV}/jobSor/job/${tableRowId}`, (data) => {
      // console.log(data);
      setContentListData(data);
    });
  }, [tableRowId]);

  function targetClicked(e, item) {
    // Directly use the passed item object to update the form
    // No need to parse eventData from the event.target.getAttribute
    // console.log("targetClicked item: ", item);

    reset({
      // ID here is needed for PUT call in handleSubmitForm
      id: item?.id,
      // * BOOLEANS need || "" removed!
      // * Date values & others need to be null if there is no data!
      sorCode: item?.sor.sorCode || "",
      shortDesc: item?.sor.shortDesc || "",
      uomId: item?.sor.uomId || "",
      uomName: item?.sor.uom.name || "",
      rate: item?.sor.rate || "",
      qty: item?.qty || "",
      subTotal: item?.subTotal || "",
      sorRefId: item?.sorRefId || "",
    });
  }

  return (
    <Form>
      <div className="contentListContainer">
        <ContentList
          data={contentListData}
          type={"jobsors"}
          clicked={targetClicked}
        />
      </div>

      {contentListData.length > 0 && (
        <CollapsibleGroup title="Form">
          <div className="sidePanelFormRowsContainer">
            <SidePanelFormRow>
              <Input
                label={"SOR Code"}
                name="sorCode"
                readOnly
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <Input
                label={"Short Description"}
                name="shortDesc"
                readOnly
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <Input
                label={"UOM"}
                name="uomName"
                readOnly
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <Input
                label={"Rate"}
                name="rate"
                readOnly
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <Input
                label={"Quantity"}
                name="qty"
                type="number"
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            <SidePanelFormRow>
              <Input
                label={"Sub Total"}
                name="subTotal"
                readOnly
                control={control}
                errors={errors}
              />
            </SidePanelFormRow>

            {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List"}
              name="field2"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow> */}

            {/* <SidePanelFormRow>
            <DropdownList
              label={"Dropdown List 2"}
              name="field3"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: true, name: "Yes" },
                { id: false, name: "No" },
              ]}
            />
          </SidePanelFormRow> */}

            {/* <SidePanelFormRow>
            <label>Start Date</label>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => (
                <DateTimePicker
                  classValue="input medium center"
                  value={field.value}
                  onChange={field.onChange}
                  hideTime={false}
                  error={fieldState.error}
                />
              )}
            />
          </SidePanelFormRow> */}

            {/* <SidePanelFormRow type="full">
            <label>textArea</label>
            <textarea
              {...register("textArea")}
              rows="5"
              className="input left"
            ></textarea>
            {errors.issueDetail && (
              <span className="fieldError">{errors.issueDetail.message}</span>
            )}
          </SidePanelFormRow> */}

            {/* <SidePanelFormRow>
          <Button
            size={"small"}
            onClick={(e) => {
              e.preventDefault();
              handleOpenModal();
            }}
          >
            Open Modal
          </Button>
        </SidePanelFormRow> */}
          </div>
        </CollapsibleGroup>
      )}

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
