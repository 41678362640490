import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";
import AddressInput from "../../../features/forms/AddressInput";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Tags from "@ui/Tags";
import AttributesDisplay from "@ui/AttributesDisplay";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // uprn: yup.string().required("This field is required"),
    clientRefId: yup.string().required("This field is required"),
    address1: yup.string().required("This field is required"),
    town: yup.string().required("This field is required"),
    county: yup.string().required("This field is required"),
    postCode: yup
      .string()
      .required("This field is required")
      .matches(
        /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i,
        "Invalid UK postcode"
      ),
    // phone: yup.string().required("This field is required"),
  })
  .required();

export default function ViewProperty({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
  setFormState,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      uprn: "",
      clientRefId: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      town: "",
      county: "",
      postCode: "",
      phone: "",
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;
  const parentPropertyId = stateData.parentPropertyId;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form Values: ", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/clients`, (data) => {
      // console.log("useEffect API 1 data:", data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, [stateData.id]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        uprn: stateData.uprn || "",
        clientRefId: stateData.clientRefId || "",
        address1: stateData.address1 || "",
        address2: stateData.address2 || "",
        address3: stateData.address3 || "",
        address4: stateData.address4 || "",
        town: stateData.town || "",
        county: stateData.county || "",
        postCode: stateData.postCode || "",
        phone: stateData.phone || "",
      });
    }
  }, [stateData, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("dataSubmit", data);
      // console.log("apiUrl", apiUrl);
      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  const handleAddJob = useCallback(() => {
    setFormState({ type: "addjobtoproperty", data: {} });
  }, [setFormState]);

  const handleAddSubProperty = useCallback(() => {
    setFormState({ type: "addsubproperty", data: {} });
  }, [setFormState]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    if (parentPropertyId === 0) {
      setFooterState([
        { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
        { text: "Add Sub Property", click: handleAddSubProperty },
        { text: "Add Job", click: handleAddJob },
        { text: "Archive", click: handleArchive },
        // { text: "Open Modal", click: handleOpenModal },
        { text: "Close", click: closeFunction },
      ]);
    } else {
      setFooterState([
        { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
        // { text: "Add Sub Property", click: handleAddSubProperty },
        { text: "Add Job", click: handleAddJob },
        { text: "Archive", click: handleArchive },
        // { text: "Open Modal", click: handleOpenModal },
        { text: "Close", click: closeFunction },
      ]);
    }
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    handleAddJob,
    handleAddSubProperty,
    parentPropertyId,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"UPRN"}
              name="uprn"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Client"}
              name="clientRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
            />
          </SidePanelFormRow>

          <AddressInput register={register} errors={errors} />
        </div>
      </CollapsibleGroup>

      {/* //. Tags  */}
      <CollapsibleGroup title="Tags">
        <Tags
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={3}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>

      {/* //. Attributes  */}
      <CollapsibleGroup title="Attributes">
        <AttributesDisplay
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={3}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>
    </Form>
  );
}
