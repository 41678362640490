import "../styles/ui/NestedList.scss";

export default function NestedList({
  children,
  data,
  onItemClick,
  selectedItemId,
  title,
}) {
  return (
    <>
      <div className="nestedListContainer">
        {title && <h4 className="listTitle">{title}</h4>}
        <ul className="listTree">
          {Array.isArray(data) &&
            data.map((item) => (
              <li
                key={item.id}
                className={`listElement ${
                  item.withLeaf ? "withLeaf" : "noLeaf"
                } ${selectedItemId === item.id ? "selected" : ""}`}
                onClick={onItemClick ? () => onItemClick(item) : undefined}
              >
                {item.name}
                {children}
              </li>
            ))}
        </ul>
        {typeof data === "string" && <p className="listError">No data found</p>}
      </div>
    </>
  );
}
