import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../../Hooks";
import SubJob from "./SubJob";
import "./subJobs.scss";

export default function SubJobs({ jobId, data }) {
  const [content, updateContent] = useState();

  useEffect(() => {
    let isMounted = true;

    const array = [];
    Hooks.getData(
      `${API_ENV}/jobs/subjobs/${jobId}`,
      (jobs) => {
        if (isMounted) {
          if (jobs && jobs.length) {
            jobs.forEach((job) => {
              array.push(<SubJob data={job} />);
            });
          }
          updateContent(array);
        }
      }
    );

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [jobId, data]);

  useEffect(() => {
    if (content && content.length && typeof content !== "string") {
      document.querySelector("#subJobsContainer").style.display = "block";
    } else {
      document.querySelector("#subJobsContainer").style.display = "none";
    }
  }, [content]);

  return (
    <div id="subJobsContainer">
      <div id="subJobsHeader">Sub Jobs</div>
      <div id="subJobsContent">{content}</div>
    </div>
  );
}
