import "../styles/ui/Input.scss";

import React from "react";
import { useController } from "react-hook-form";

const Input = ({
  // Component props
  label,
  textAlign = "center",
  size = "medium",
  // React Hook Form props
  name,
  control,
  rules,
  defaultValue = "",
  errors,
  // Regular state props
  formData,
  setFormData,
  // other props
  type = "text",
  readOnly = false,
  ...rest
}) => {
  const useControllerProps = control
    ? { name, control, rules, defaultValue }
    : {};

  const {
    field: { onChange, onBlur, value, ref } = {},
    fieldState: { error: rhfError } = {},
  } = useController(useControllerProps);

  // Determine input props based on whether control is provided
  const inputProps = control
    ? {
        ref,
        onChange,
        onBlur,
        value: type === "checkbox" ? value : value,
        checked: type === "checkbox" ? value : undefined,
      }
    : {
        defaultValue: formData[name],
        onChange: (e) => {
          const inputValue =
            type === "checkbox" ? e.target.checked : e.target.value;
          setFormData((prevData) => ({
            ...prevData,
            [name]: inputValue,
          }));
        },
        checked: type === "checkbox" ? formData[name] : undefined,
      };

  const errorMessage = control ? rhfError?.message : errors?.[name]?.message;

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        className={`input ${size} ${textAlign} ${readOnly && "readOnly"}`}
        name={name}
        type={type}
        readOnly={readOnly}
        {...inputProps}
        {...rest}
      />
      {errorMessage && <p className="fieldError">{errorMessage}</p>}
    </>
  );
};

export default Input;
