import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";
import AddressInput from "../../../features/forms/AddressInput";
import API_ENV from "@config";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // uprn: yup.string().required("This field is required"),
    clientRefId: yup.string().required("This field is required"),
    address1: yup.string().required("This field is required"),
    town: yup.string().required("This field is required"),
    county: yup.string().required("This field is required"),
    postCode: yup
      .string()
      .required("This field is required")
      .matches(
        /^([A-Z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}|GIR\s?0AA)$/i,
        "Invalid UK postcode"
      ),
    // phone: yup.string().required("This field is required"),
  })
  .required();

export default function AddProperty({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      uprn: "",
      clientRefId: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      town: "",
      county: "",
      postCode: "",
      phone: "",
    },
  });

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/clients`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      // console.log("apiUrl", apiUrl);
      // console.log("modifiedApiUrl", modifiedApiUrl);
      // console.log("data", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        "POST",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"UPRN"} name="uprn" control={control} errors={errors} />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Client"}
            name="clientRefId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
          />
        </SidePanelFormRow>

        <AddressInput register={register} errors={errors} />
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
