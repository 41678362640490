import API_ENV from "@config";
import React, { useState, useEffect } from "react";

import "./eqdropdown.scss";
import NewDropdown from "../../../generic/NewDropdown";

export default function EqDropdown({ equipmentCategory, subCategory }) {
  const [url, updateUrl] = useState();

  useEffect(() => {
    if (equipmentCategory && equipmentCategory.id) {
      updateUrl(
        `${API_ENV}/EquipmentSubCategory?EquipmentCategoryId=${equipmentCategory.id}`
      );
    }
  }, [equipmentCategory]);

  function eqChanged() {
    if (
      document.querySelector("#equipmentcategoryDropdown") &&
      document.querySelector("#equipmentcategoryDropdown").getAttribute("data")
    ) {
      const value = document
        .querySelector("#equipmentcategoryDropdown")
        .getAttribute("data");
      updateUrl(
        `${API_ENV}/EquipmentSubCategory?EquipmentCategoryId=${value}`
      );
    }
  }

  return (
    <div id="eqDropdown">
      <div className="eqRow">
        <div className="sidePanelKey">Equipment Category</div>
        {equipmentCategory && (
          <NewDropdown
            type="equipmentcategory"
            key={equipmentCategory.label || "nil"}
            topValue={equipmentCategory}
            onChange={eqChanged}
          />
        )}
      </div>
      <div className="eqRow">
        <div className="sidePanelKey">Equipment Subcategory</div>
        {subCategory && (
          <NewDropdown
            type="equipmentsubcategory"
            key={subCategory.label || url}
            topValue={subCategory}
            customUrl={url}
          />
        )}
      </div>
    </div>
  );
}
