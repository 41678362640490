import React, { forwardRef } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";

export default forwardRef(function SearchField(
  { id, placeholder, searchClicked, defaultText, dataId },
  ref
) {
  return (
    <div className="searchField">
      <input
        ref={ref}
        id={id}
        className="searchFieldInput"
        dataid={dataId}
        placeholder={defaultText || `Search for ${placeholder}`}
      />
      <div className="searchButton" onClick={searchClicked}>
        <HiMagnifyingGlass onClick={searchClicked} />
      </div>
    </div>
  );
});
