import "../styles/ui/Header.scss";

import HeaderMenu from "./HeaderMenu";
import Hooks from "../Hooks";

export default function Header() {
  return (
    <header className="header">
      <div className="headerContent">
        {/* <UserAvatar /> */}
        <div className="headerLogon">
          Logged on as: <span>{`${Hooks.getCookie("name")}`}</span>
        </div>
        <HeaderMenu />
      </div>
    </header>
  );
}
