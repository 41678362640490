import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import StandaloneTable from "features/table/StandaloneTable";
import Heading from "@ui/Heading";
import Row from "@ui/Row";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Button from "@ui/Button";
import HR from "@ui/HR";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    listValue: yup.string().required("This field is required"),
    attributeCategoryId: yup.string().required("This field is required"),
    attributeUnitId: yup.string().required("This field is required"),
  })
  .required();

export default function ViewAttributeListChoice({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });
  const [tableData, setTableData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState("");

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      listValue: "",
      attributeCategoryId: "",
      attributeUnitId: "",
    },
  });
  const attributeListChoiceId = watch("id");

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) =>
  //     console.log("Form values:", values)
  //   );
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        //! This isnt needed here due to add & edit functionality
        // ID here is needed for PUT call in handleSubmitForm
        // id: stateData.id,
        // * BOOLEANS need || "" removed!
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/attributeListChoice/attributeUnitId/${stateData.id}`,
      (data) => {
        console.log("Table data: ", data);

        // Transform the data to bring unitName to the top level
        const transformedData = data.map((item) => ({
          ...item,
          unitName: item.attributeUnit?.unitName || "",
        }));

        setTableData(transformedData);
        setTableColumns([
          { accessorKey: "listValue", header: "Dropdown Name" },
          // { accessorKey: "unitName", header: "Attribute Unit" },
        ]);
      }
    );

    Hooks.getData(
      `${API_ENV}/attributeCategory`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          dropdownData1: data,
        }));
      }
    );
    Hooks.getData(`${API_ENV}/attributeUnit`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
  }, [stateData.id]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        // cleanURL();
      }

      // console.log("apiUrl", apiUrl);
      console.log("handleSubmitForm data:", data);

      if (attributeListChoiceId) {
        console.log("PUT");
        Hooks.sendData(
          data,
          `${API_ENV}/attributeListChoice/${attributeListChoiceId}`,
          "PUT",
          thenFunction
        );
      } else {
        console.log("POST");
        Hooks.sendData(
          data,
          `${API_ENV}/attributeListChoice/`,
          "POST",
          thenFunction
        );
      }
    },
    [refreshTable, attributeListChoiceId]
  );

  const handleArchive = useCallback(() => {
    function thenFunction(response) {
      refreshTable();
    }

    Hooks.sendData(
      {},
      `${API_ENV}/attributeListChoice/${attributeListChoiceId}`,
      "DELETE",
      thenFunction
    );
  }, [refreshTable, attributeListChoiceId]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    if (attributeListChoiceId) {
      setFooterState([
        {
          text: "Submit Edited Changes",
          click: handleSubmit(handleSubmitForm),
        },
        {
          text: "Archive Selected Attribute List Choice",
          click: handleArchive,
        },
        // { text: "Open Modal", click: handleOpenModal },
        { text: "Close", click: closeFunction },
      ]);
    } else {
      setFooterState([
        { text: "Add New List Choice", click: handleSubmit(handleSubmitForm) },
        // { text: "Archive", click: handleArchive },
        // { text: "Open Modal", click: handleOpenModal },
        { text: "Close", click: closeFunction },
      ]);
    }
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    attributeListChoiceId,
    // handleOpenModal,
  ]);

  const handleRowClick = (rowData) => {
    // console.log("rowData", rowData);
    reset({
      id: rowData?.id || "",
      listValue: rowData?.listValue || "",
      attributeCategoryId: rowData.attributeUnit?.attributeCategoryId || "",
      attributeUnitId: rowData?.attributeUnitId || "",
    });
    setSelectedChoice(`ID: ${rowData?.id}, Name: ${rowData?.listValue} `);
  };

  const resetFields = () => {
    reset({
      listValue: "",
      attributeCategoryId: "",
      attributeUnitId: "",
    });
  };

  return (
    <Form>
      {stateData.answerTypeRefId === 4 ? (
        <>
          <CollapsibleGroup title="Attribute List Choice Table (Click to edit)">
            <StandaloneTable
              tableData={tableData}
              tableColumns={tableColumns}
              onRowClick={handleRowClick}
              tableContainerType="collapsibleGroupTable"
            />
          </CollapsibleGroup>

          <CollapsibleGroup title="Form">
            {attributeListChoiceId ? (
              <Row>
                <Heading as="h5">Editing: {selectedChoice}</Heading>
              </Row>
            ) : (
              <Row>
                <Heading as="h5">Add new Attribute List Choice</Heading>
              </Row>
            )}
            <HR />
            <div className="sidePanelFormRowsContainer">
              {attributeListChoiceId && (
                <SidePanelFormRow>
                  <Input
                    label={"ID"}
                    name="id"
                    readOnly
                    control={control}
                    errors={errors}
                  />
                </SidePanelFormRow>
              )}
              <SidePanelFormRow>
                <Input
                  label={"List Value"}
                  name="listValue"
                  control={control}
                  errors={errors}
                />
              </SidePanelFormRow>
              <SidePanelFormRow>
                <DropdownList
                  label={"Attribute Category"}
                  name="attributeCategoryId"
                  control={control}
                  errors={errors}
                  optionName="catName"
                  dropdownData={dropdownData.dropdownData1}
                />
              </SidePanelFormRow>
              <SidePanelFormRow>
                <DropdownList
                  label={"Attribute Unit"}
                  name="attributeUnitId"
                  control={control}
                  errors={errors}
                  optionName="unitName"
                  dropdownData={dropdownData.dropdownData2}
                />
              </SidePanelFormRow>
            </div>

            {attributeListChoiceId && (
              <>
                <HR />

                <Row>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      resetFields();
                    }}
                  >
                    Reset Form to add a new Attribute List Choice
                  </Button>
                </Row>
              </>
            )}
          </CollapsibleGroup>
        </>
      ) : (
        <p>This Attribute Unit's Answer Type is not "Dropdown List"</p>
      )}

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
