import "../styles/ui/Select.scss";

export default function Select({
  options,
  value,
  onChange,
  type = "medium",
  children,
  ...props
}) {
  return (
    <select
      className={`select ${type}`}
      value={value}
      onChange={onChange}
      {...props}
    >
      {options
        ? options.map((option) => (
            <option
              value={option.value}
              key={option.value}
              className={"option"}
            >
              {option.label}
            </option>
          ))
        : children && children}
    </select>
  );
}
