import React, { useRef, useEffect } from "react";
import "../styles/ui/ContextMenu.scss";

const ContextMenu = ({ x, y, options, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={menuRef} className="contextMenu" style={{ top: y, left: x }}>
      {options.map((option, index) => (
        <div key={index} className="contextMenuItem" onClick={option.onClick}>
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
