import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../Hooks";

import EmailFilter from "../../filters/EmailFilter";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import ReportFilter from "../../filters/ReportFilter";
import { Modal, ModalTrigger, useModal } from "../../../ui/Modal";
import LetterPrintReciever from "../../filters/LetterPrintReciever";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import DropdownListOldNonRHF from "@ui/DropdownListOldNonRHF";

const initialFilterInfo = {
  // filterSaveId: "",
  // filterSaveName: "",
  selectedFilterId: "",
  selectedFilterName: "",
  savedFilters: [],
  // saveAs: 0,
};

export default function AddReport({ setFooterState, cleanURL, refreshTable }) {
  const [formData, setFormData] = useState({
    apiGroup: "",
    reportNameValue: "",
    exportFormat: "",
    letterPrint: "",
    orderBy: "",
    searchFilter: "",
    runNow: true,
    runAtDate: "",
    runAtDateFormatted: "",
    runRecurring: "",
    intervalNumber: "",
    intervalPeriod: "",
    dependencies: {
      runReportListId: null,
      runReportTypeId: null,
      allowToRunOnline: null,
      allowToRunAsFile: null,
      allowToChangeParameter: null,
      reportName: null,
      letterPrintId: null,
    },
  });

  const [filterInfo, setFilterInfo] = useState(initialFilterInfo);

  const [dropdownData, setDropdownData] = useState({
    apiGroupData: [],
    reportNameData: [],
    exportFormatData: [],
    runRecurringData: [],
  });

  const [downloadContent, setDownloadContent] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const { openModal } = useModal();

  // Check state changes here, disable or remove once done
  // useEffect(() => {
  //   console.log("formData:", formData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [formData]);

  // API Group
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        apiGroupData: data,
      }));
    });
  }, []);

  useEffect(() => {
    Hooks.getData(`${API_ENV}/runPeriodUnit`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        runRecurringData: data,
      }));
    });
  }, []);

  useEffect(() => {
    if (formData.dependencies.runReportListId) {
      // Saved Filters
      Hooks.getData(
        `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${formData.dependencies.runReportListId}`,
        (data) => {
          // console.log("savedFilters: ", data);

          if (data.length > 0) {
            setFilterInfo((prevData) => ({
              ...prevData,
              savedFilters: data,
              selectedFilterId: data[0].id,
              selectedFilterName: data[0].filterName,
            }));
          }
        }
      );
    }
  }, [formData.dependencies.runReportListId]);

  //* handleLoadFilter if it was automatic on selection
  // useEffect(() => {
  //   if (filterInfo.selectedFilterId) {
  //     // *Apply Filters
  //     Hooks.getData(
  //       `${API_ENV}/runReportfilter/runReportId/${formData.dependencies.runReportListId}?FilterName=${filterInfo.selectedFilterName}`,
  //       (data) => {
  //         // console.log("Set Filters: ", data);

  //         const filterObject = {
  //           staffId: Hooks.getCookie("userId"),
  //           sortColumnFieldId: data.sortColumnFieldId,
  //           runReportListId: formData.dependencies.runReportListId,
  //           contents: data.contents,
  //         };

  //         // console.log("filterObject", filterObject);

  //         Hooks.sendData(
  //           filterObject,
  //           `${API_ENV}/runReportfilter`,
  //           "POST",
  //           null,
  //           false,
  //           `Filter: "${data.filterName}" Selected`
  //         );
  //       }
  //     );
  //   }
  // }, [
  //   filterInfo.selectedFilterId,
  //   filterInfo.selectedFilterName,
  //   formData.dependencies.runReportListId,
  // ]);

  function handleLoadFilter() {
    if (filterInfo.selectedFilterId) {
      Hooks.getData(
        `${API_ENV}/runReportfilter/runReportId/${formData.dependencies.runReportListId}?FilterName=${filterInfo.selectedFilterName}`,
        (data) => {
          // console.log("Set Filters: ", data);

          const filterObject = {
            staffId: Hooks.getCookie("userId"),
            sortColumnFieldId: data.sortColumnFieldId,
            runReportListId: formData.dependencies.runReportListId,
            contents: data.contents,
          };

          // console.log("filterObject", filterObject);

          Hooks.sendData(
            filterObject,
            `${API_ENV}/runReportfilter`,
            "POST",
            null,
            false,
            `Filter: "${data.filterName}" Selected`
          );
        }
      );
    }
  }

  // Report Name
  useEffect(() => {
    if (formData.apiGroup) {
      Hooks.getData(
        `${API_ENV}/runReportlist/accesslist?apigroupid=${formData.apiGroup}`,
        (data) => {
          // console.log(data);

          setDropdownData((prevData) => ({
            ...prevData,
            reportNameData: data,
          }));
        }
      );
    }

    // Setting form dependencies depending on Report
    if (formData.dependencies.runReportListId) {
      Hooks.getData(
        `${API_ENV}/runReportlist/withaccessright/${formData.dependencies.runReportListId}`,
        (data) => {
          // console.log(data);
          if (data) {
            setFormData((prevData) => ({
              ...prevData,
              dependencies: {
                ...prevData.dependencies,
                allowToRunOnline: data.allowToRunOnline,
                allowToRunAsFile: data.allowToRunAsFile,
                allowToChangeParameter: data.allowToChangeParameter,
                reportName: data.name,
              },
            }));
          }
        }
      );

      // Populating "Order By", "Search Filter" & "Letter Print" fields
      Hooks.getData(
        `${API_ENV}/runReportfilter/ShowOrderByAndSearchFilterMessage/${formData.dependencies.runReportListId}`,
        (data) => {
          // console.log(
          //   "runReportfilter/ShowOrderByAndSearchFilterMessage",
          //   data
          // );
          if (data && data.length > 0) {
            const firstItem = data[0];

            setFormData((prevData) => ({
              ...prevData,
              letterPrint: firstItem.letterPrintSetup.letterName,
              orderBy: firstItem.displayOrderBy,
              searchFilter: firstItem.displaySearchFilter,
              dependencies: {
                ...prevData.dependencies,
                letterPrintId: firstItem.letterPrintSetupId,
              },
            }));
          }
        }
      );
    }
  }, [formData.apiGroup, formData.dependencies.runReportListId]);

  // Export Format
  useEffect(() => {
    if (formData.dependencies.runReportTypeId === 1) {
      //PDF
      Hooks.getData(`${API_ENV}/runReportType?isPDF=true`, (data) => {
        // console.log(data);

        setDropdownData((prevData) => ({
          ...prevData,
          exportFormatData: data,
        }));

        const firstOption = data[0].outputFormat;
        setFormData((prevData) => ({
          ...prevData,
          exportFormat: firstOption,
        }));
      });
    } else if (
      formData.dependencies.runReportTypeId === 2 ||
      formData.dependencies.runReportTypeId === 3
    ) {
      // Non-PDF
      Hooks.getData(`${API_ENV}/runReportType?isPDF=false`, (data) => {
        // console.log(data);

        setDropdownData((prevData) => ({
          ...prevData,
          exportFormatData: data,
        }));

        const firstOption = data[0].outputFormat;
        setFormData((prevData) => ({
          ...prevData,
          exportFormat: firstOption,
        }));
      });
    }
  }, [formData.dependencies.runReportTypeId]);

  const handleRunOnline = useCallback(
    async (e) => {
      try {
        const apiUrl = `${API_ENV}/runReportlist/runonline/download/${formData.dependencies.runReportListId}`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${Hooks.getCookie("jwt")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // console.log(response);

        const blob = await response.blob(); // Get the binary data of the file
        // console.log(blob);

        // Create a download link for the file
        const url = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement("a"), {
          href: url,
          download: `${formData.dependencies.reportName}${formData.exportFormat}`,
        });
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);

        const content = (
          <>
            <Heading as="h4">Download Report</Heading>
            <p>Ready to download report: {formData.dependencies.reportName}</p>
          </>
        );
        setDownloadContent(content);
        refreshTable();
      } catch (error) {
        console.error(error);
      }
    },
    [
      formData.dependencies.runReportListId,
      formData.dependencies.reportName,
      formData.exportFormat,
      refreshTable,
    ]
  );

  const handleRunAsFile = useCallback(async () => {
    // Check report has been selected
    setErrorMessage(null);
    setDownloadContent(null);

    if (!formData.dependencies.runReportListId) {
      console.error("No report selected");
      setErrorMessage(
        <>
          <Heading as="h4">Error: No Report Selected</Heading>
          <p>
            Please select a report using the dropdown lists above & continue.
          </p>
        </>
      );
      return;
    }

    if (
      formData.runRecurring &&
      (!formData.intervalNumber || !formData.intervalPeriod)
    ) {
      console.error("Missing interval details for recurring setup");
      setErrorMessage(
        <>
          <Heading as="h4">Error: No Interval Value/Type selected</Heading>
          <p>
            Please ensure "Interval Value" & "Interval Type" fields have values
            whilst "Run Recurring at:" is checked.
          </p>
        </>
      );
      return;
    }

    let apiUrl = `${API_ENV}/runReportlist/runReportProcess/${formData.dependencies.runReportListId}/${formData.runNow}`;

    if (formData.runNow === true) {
      if (formData.dependencies.runReportTypeId) {
        apiUrl += `?ReportTypeID=${formData.dependencies.runReportTypeId}`;
      }
      if (formData.runRecurring === true) {
        apiUrl +=
          (apiUrl.includes("?") ? "&" : "?") +
          `RunRecur=${formData.runRecurring}`;
        apiUrl += `&RunInterval=${formData.intervalNumber}`;
        apiUrl += `&RunIntervalId=${formData.intervalPeriod}`;
      }
    } else {
      apiUrl += `?ScheduleAt=${formData.runAtDateFormatted}`;
      if (formData.dependencies.runReportTypeId) {
        apiUrl += `&ReportTypeID=${formData.dependencies.runReportTypeId}`;
      }
      if (formData.runRecurring === true) {
        apiUrl += `&RunRecur=${formData.runRecurring}`;
        apiUrl += `&RunInterval=${formData.intervalNumber}`;
        apiUrl += `&RunIntervalId=${formData.intervalPeriod}`;
      }
    }

    console.log("apiUrl:", apiUrl);

    Hooks.getData(apiUrl, (data) => {
      console.log(data);

      const content = (
        <>
          <Heading as="h4">Download Report</Heading>
          <p>{data}</p>
        </>
      );

      setDownloadContent(content);
      refreshTable();
    });
  }, [
    formData.dependencies.runReportListId,
    formData.runNow,
    formData.runAtDateFormatted,
    formData.dependencies.runReportTypeId,
    formData.runRecurring,
    formData.intervalNumber,
    formData.intervalPeriod,
    refreshTable,
  ]);

  const handleRunAsEmail = useCallback(async () => {
    // Check report has been selected
    if (formData.dependencies.runReportListId) {
      let apiUrl = `${API_ENV}/runReportlist/runReportAsSendEmail/${formData.dependencies.runReportListId}/${formData.runNow}`;
      console.log(apiUrl);

      if (formData.reportTypeId) {
        apiUrl += `?ReportTypeID=${formData.dependencies.runReportTypeId}`;
      }
      if (formData.runNow === false) {
        apiUrl += `&ScheduleAt=${formData.runAtDateFormatted}`;
      }

      Hooks.getData(apiUrl, (data) => {
        console.log(data);
      });
    }
  }, [
    formData.dependencies.runReportListId,
    formData.dependencies.runReportTypeId,
    formData.reportTypeId,
    formData.runAtDateFormatted,
    formData.runNow,
  ]);

  const handleCustomEmailReceiver = useCallback(async () => {
    openModal("EmailFilter");
  }, [openModal]);

  const handleLetterPrintReceiver = useCallback(async () => {
    openModal("LetterPrintReceiver");
  }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    if (!formData.dependencies.letterPrintId) {
      setFooterState([
        { text: "Run as File", click: handleRunAsFile },
        { text: "Run as Email", click: handleRunAsEmail },
        { text: "Custom Email Receiver", click: handleCustomEmailReceiver },
        { text: "Close", click: closeFunction },
      ]);
    } else {
      setFooterState([
        { text: "Run as File", click: handleRunAsFile },
        { text: "Run as Email", click: handleRunAsEmail },
        { text: "Letter Print Receiver", click: handleLetterPrintReceiver },
        { text: "Close", click: closeFunction },
      ]);
    }
  }, [
    setFooterState,
    handleRunAsFile,
    handleRunAsEmail,
    handleCustomEmailReceiver,
    handleLetterPrintReceiver,
    closeFunction,
    formData.dependencies.letterPrintId,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <DropdownListOldNonRHF
            label={"API Group"}
            size="extraSmall"
            dropdownData={dropdownData.apiGroupData}
            formData={formData}
            setFormData={setFormData}
            optionName={"groupName"}
            customKeyName={"apiGroup"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="reportName">Report Name</label>
          <select
            id="reportName"
            className="select extraSmall"
            value={formData.reportName}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setFormData((prevData) => ({
                ...prevData,
                reportNameValue: selectedValue,
              }));

              const [runReportTypeIdString, idString] =
                selectedValue.split(":");
              const runReportTypeId = parseInt(runReportTypeIdString, 10);
              const id = parseInt(idString, 10);
              setFormData((prevData) => ({
                ...prevData,
                dependencies: {
                  ...prevData.dependencies,
                  runReportListId: id,
                  runReportTypeId: runReportTypeId,
                },
              }));
            }}
          >
            <option value="">Select option</option>
            {Array.isArray(dropdownData.reportNameData)
              ? dropdownData.reportNameData.map((item) => (
                  <option
                    key={item.id}
                    value={`${item.runReportTypeId}:${item.id}`}
                  >
                    {item.name}
                  </option>
                ))
              : null}
          </select>
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="exportFormat">Export Format</label>
          <select
            id="exportFormat"
            className="select extraSmall"
            value={formData.exportFormat}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const runReportTypeId = Number(
                selectedOption.getAttribute("data-runreporttypeid")
              );

              setFormData((prevData) => ({
                ...prevData,
                exportFormat: e.target.value,
                dependencies: {
                  ...prevData.dependencies,
                  runReportTypeId: runReportTypeId,
                },
              }));
            }}
          >
            {/* <option value="">Select option</option> */}

            {(!dropdownData.exportFormatData ||
              dropdownData.exportFormatData.length === 0) && (
              <option value="">Select option</option>
            )}
            {Array.isArray(dropdownData.exportFormatData)
              ? dropdownData.exportFormatData.map((item) => (
                  <option
                    key={item.id}
                    value={item.outputFormat}
                    data-runreporttypeid={item.id}
                  >
                    {item.name}
                  </option>
                ))
              : null}
          </select>
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Select Filters:</label>
          <select
            className="select extraSmall"
            value={filterInfo.selectedFilterId}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const selectedFilterName =
                selectedOption.getAttribute("data-filtername");

              setFilterInfo((prevData) => ({
                ...prevData,
                selectedFilterId: Number(e.target.value),
                selectedFilterName: selectedFilterName,
              }));
            }}
          >
            {/* <option value="">Select option</option> */}
            {Array.isArray(filterInfo.savedFilters) &&
            filterInfo.savedFilters.length > 0 ? (
              filterInfo.savedFilters.map((item) => (
                <option
                  key={item.id}
                  value={item.id}
                  data-filtername={item.filterName}
                >
                  {item.filterName}
                </option>
              ))
            ) : (
              <option value="">Select Report</option>
            )}
          </select>
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Filter Settings</label>

          <div className="buttonsGroup">
            <Button
              size="small expand"
              onClick={(e) => {
                e.preventDefault();
                handleLoadFilter();
              }}
            >
              Load Filter
            </Button>

            <ModalTrigger name="ReportFilterForm">
              <Button
                size={"small expand"}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Edit Filter
              </Button>
            </ModalTrigger>
          </div>
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="letterPrint">Letter Print:</label>
          <input
            id="letterPrint"
            className="input extraSmall"
            type="text"
            readOnly
            value={formData.letterPrint || "No Letter Print"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="orderBy">Order By:</label>
          <input
            id="orderBy"
            className="input extraSmall"
            type="text"
            readOnly
            value={formData.orderBy || "No filter"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="searchFilter">Search Filter:</label>
          <input
            id="searchFilter"
            className="input extraSmall"
            type="text"
            readOnly
            value={formData.searchFilter || "No filter"}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="runNow">Run Now:</label>
          <input
            id="runNow"
            className="input extraSmall"
            type="radio"
            name="runTime"
            checked={formData.runNow === true}
            onChange={() => {
              setFormData((prevData) => ({
                ...prevData,
                runNow: true,
                runAtDate: "",
                runAtDateFormatted: "",
              }));
            }}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="runAt">Run At:</label>
          <input
            id="runAt"
            className="input extraSmall"
            type="radio"
            name="runTime"
            checked={formData.runNow === false}
            onChange={() => {
              setFormData((prevData) => ({
                ...prevData,
                runNow: false,
              }));
            }}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="runAt">Date & Time selection:</label>
          <ReactDatePicker
            id="runAtDateField"
            className="input extraSmall"
            selected={formData.runAtDate}
            disabled={formData.runNow}
            onChange={(date) => {
              const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
              setFormData((prevData) => ({
                ...prevData,
                runAtDate: date,
                runAtDateFormatted: formattedDate,
              }));
            }}
            showTimeSelect
            dateFormat="Pp"
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="runNow">Run Recurring at:</label>
          <input
            id="runNow"
            className="input extraSmall"
            type="checkbox"
            value={formData.runRecurring}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                runRecurring: e.target.checked,
              }));
            }}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="withInterval">Interval Value:</label>
          <input
            id="withInterval"
            className="input extraSmall"
            type="number"
            disabled={!formData.runRecurring}
            value={formData.intervalNumber || ""}
            onChange={(e) => {
              const newValue = Math.max(1, parseInt(e.target.value, 10));
              setFormData((prevData) => ({
                ...prevData,
                intervalNumber: newValue,
              }));
            }}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label htmlFor="withInterval">Interval Type:</label>
          <select
            id="interval"
            className="select extraSmall"
            value={formData.intervalPeriod}
            disabled={!formData.runRecurring}
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                intervalPeriod: Number(e.target.value),
              }));
            }}
          >
            <option>Select option</option>
            {Array.isArray(dropdownData.runRecurringData)
              ? dropdownData.runRecurringData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))
              : null}
          </select>
        </SidePanelFormRow>
      </div>

      <div className="formDownload">
        {downloadContent}
        {downloadContent && (
          <>
            <Button size={"small"} onClick={handleRunOnline}>
              Download file
            </Button>
          </>
        )}

        {errorMessage}
      </div>

      <Modal name="ReportFilterForm" title={"Report Filter"}>
        <ReportFilter
          setOutsideFormData={setFormData}
          reportId={formData.dependencies.runReportListId}
          reportTypeId={formData.dependencies.runReportTypeId}
          selectedFilterId={filterInfo.selectedFilterId}
        />
      </Modal>

      <Modal name="EmailFilter" title={"Email Filter"}>
        <EmailFilter
          // onSearch={afSearchClicked}
          reportId={formData.dependencies.runReportListId}
          reportTypeId={formData.dependencies.runReportTypeId}
          runNow={formData.runNow}
          runAtDateFormatted={formData.runAtDateFormatted}
        />
      </Modal>

      <Modal
        name="LetterPrintReceiver"
        title={"Letter Print Receiver"}
        size="maxWidth"
      >
        <LetterPrintReciever
          reportId={formData.dependencies.runReportListId}
          // reportTypeId={formData.dependencies.runReportTypeId}
          // runNow={formData.runNow}
          // runAtDateFormatted={formData.runAtDateFormatted}
        />
      </Modal>
    </Form>
  );
}
