import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import Tags from "@ui/Tags";
import AttributesDisplay from "@ui/AttributesDisplay";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    email: yup.string().required("This field is required"),
    forename: yup.string().required("This field is required"),
    surname: yup.string().required("This field is required"),
    mobile: yup
      .string()
      .required("This field is required")
      .matches(/^(?:\+44|0)7\d{9}$/, "Please enter a valid UK mobile number"),
    staffTypeRefId: yup.string().required("This field is required"),
    username: yup.string().required("This field is required"),
    // password: yup.string().required("This field is required"),
  })
  .required();

export default function ViewStaff({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      forename: "",
      surname: "",
      mobile: "",
      staffTypeRefId: "",
      username: "",
      // password: "",
      twoFSOption: "",
      managerId: 0,
    },
  });

  // const { openModal } = useModal();
  const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("stateData:", stateData);
  //   // console.log("dropdownData:", dropdownData);
  // }, [stateData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // Fill form via state
  useEffect(() => {
    if (stateData) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: stateData.id,
        // * BOOLEANS need || "" removed!
        email: stateData.email || "",
        forename: stateData.forename || "",
        surname: stateData.surname || "",
        mobile: stateData.mobile || "",
        staffTypeRefId: stateData.staffTypeRefId || "",
        username: stateData.username || "",
        twoFSOption: stateData.twoFSOption || "",
        managerId: stateData.managerId || 0,
      });
    }
  }, [stateData, reset]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/staffType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });

    Hooks.getData(`${API_ENV}/staff?showarchive=false`, (data) => {
      // console.log("data:", data);

      const newData = data.map((item) => ({
        id: item.id,
        name: `${item.forename} ${item.surname}`,
      }));

      // console.log("newData:", newData);

      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: newData,
      }));
    });
  }, []);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      // console.log("apiUrl", apiUrl);
      // console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive(stateData.id);
  }, [archive, stateData.id]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Email"}
              name="email"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Forename"}
              name="forename"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Surname"}
              name="surname"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          <SidePanelFormRow>
            <Input
              label={"Mobile"}
              name="mobile"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Staff Type"}
              name="staffTypeRefId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData1}
              optionName="description"
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Username"}
              name="username"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>
          {/* 
        <SidePanelFormRow>
          <Input
            label={"Password"}
            name="password"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow> */}

          <SidePanelFormRow>
            <DropdownList
              label={"2FA Interval"}
              name="twoFSOption"
              control={control}
              errors={errors}
              dropdownData={[
                { id: "None", name: "None" },
                { id: "Everytime", name: "Everytime" },
                { id: "Daily", name: "Daily" },
                { id: "Weekly", name: "Weekly" },
                { id: "Monthly", name: "Monthly" },
              ]}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Manager"}
              name="managerId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.dropdownData2}
              firstOptionValue={0}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>

      {/* //. Tags  */}
      <CollapsibleGroup title="Tags">
        <Tags
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={4}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>

      {/* //. Attributes  */}
      <CollapsibleGroup title="Attributes">
        <AttributesDisplay
          // objectDocTypeId =  1 = Job
          // objectDocTypeId =  2 = Equipment
          // objectDocTypeId =  3 = Property
          // objectDocTypeId =  4 = Staff
          // objectDocId =  ID of Job/Equipment/Property/Staff
          objectDocTypeId={4}
          objectDocId={stateData.id}
        />
      </CollapsibleGroup>
    </Form>
  );
}
