import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../../Hooks";
import "./historyPopup.scss";

function singleEntry(data) {
  if (typeof data === "string") {
    return (
      <div className="singleEntry" key={`entry_${data.id}`}>
        {data}
      </div>
    );
  } else {
    return (
      <div className="singleEntry" key={`entry_${data.id}`}>
        <div>{data?.jobState?.description}</div>
        <div>{new Date(data.logDate).toDateString()}</div>
      </div>
    );
  }
}

export default function HistoryPopup({ jobId }) {
  const [content, updateContent] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (jobId) {
      Hooks.getData(
        `${API_ENV}/jobs/getjobstatehistory/${jobId}`,
        (data) => {
          if (isMounted) {
            if (data && data.length) {
              let array = [];

              data.forEach((item) => {
                array.push(singleEntry(item));
              });

              updateContent(array);
            } else {
              updateContent([singleEntry("No state history for this job")]);
            }
          }
        }
      );
    }

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [jobId]);

  function closeClicked() {
    document.querySelector("#historyPopup").style.display = "none";
  }

  return (
    <div id="historyPopup">
      <div id="historyTitle">
        <div className="popupTitle">Job State History</div>
        <div className="closePopup" onClick={closeClicked}>
          X
        </div>
      </div>
      <div id="historyContent">
        <div className="singleEntry">
          <div>Status</div>
          <div>Logged</div>
        </div>
        {content}
      </div>
    </div>
  );
}
