import './scheduleTimePicker.scss';
import ScheduleTimeRow from './ScheduleTimeRow';
import React, { useEffect, useState } from 'react';

export default function ScheduleTimePicker({ data, className }) {

    const [scheduleObject, updateSchedule] = useState({
        monStart: 0,
        monEnd: 0,
        tueStart: 0,
        tueEnd: 0,
        wedStart: 0,
        wedEnd: 0,
        thurStart: 0,
        thurEnd: 0,
        friStart: 0,
        friEnd: 0,
        satStart: 0,
        satEnd: 0
    });

    useEffect(() => {
        if (data) {
            updateSchedule(data);
        };
    }, [data]);

    return (
        <div id='scheduleTimePicker'>
            <ScheduleTimeRow day='' />
            <ScheduleTimeRow day='monday' start={scheduleObject.monStart} end={scheduleObject.monEnd} />
            <ScheduleTimeRow day='tuesday' start={scheduleObject.tueStart} end={scheduleObject.tueEnd} />
            <ScheduleTimeRow day='wednesday' start={scheduleObject.wedStart} end={scheduleObject.wedEnd} />
            <ScheduleTimeRow day='thursday' start={scheduleObject.thurStart} end={scheduleObject.thurEnd} />
            <ScheduleTimeRow day='friday' start={scheduleObject.friStart} end={scheduleObject.friEnd} />
            <ScheduleTimeRow day='saturday' start={scheduleObject.satStart} end={scheduleObject.satEnd} />
            <ScheduleTimeRow day='sunday' start={scheduleObject.sunStart} end={scheduleObject.sunEnd} />
        </div>
    );
};