import React from "react";

function ContentListItem({ item, type, onListItemClick, isSelected }) {
  // useEffect(() => {
  //   console.log("isSelected:", isSelected);
  // }, [isSelected]);

  const getText = () => {
    switch (type) {
      case "resident":
        return [
          `#${item.id}`,
          `${item.title ? `${item.title} ` : ""}  
          ${item.forename ? `${item.forename} ` : ""} 
          ${item.surname ? `${item.surname} ` : ""} 
          ${item.primary ? " (Primary)" : ""}`,
        ];
      case "assignedjobs":
        return [
          `#${item.id}`,
          `${item.jobType.description} for ${item.contract.name}`,
        ];
      case "equipmentdetails":
        return [
          `Name: ${item.name ? item.name : "No name"}`,
          `Model: ${item.equipmentType.model}`,
        ];
      case "visit":
        return [`#${item.id}`, `${new Date(item.scheduledFor).toDateString()}`];
      case "jobparts":
        return [`#${item.id}`, `${item.parts.description} ${item.notes || ""}`];
      case "jobsors":
        return [`#${item.sor.sorCode}`, `${item.sor.shortDesc}`];
      case "staffcertifications":
        return [`#${item.certnumber}`, `${item.name}`];
      case "staffcontract":
        return [`#${item.id}`, `${item.name}`];
      case "assignedapis":
        return [`#${item.apiId}`, `${item.api.name}`];
      case "scheduling":
        return [`#${item.id}`, item.description];
      case "supplierparts":
        return [item?.parts?.partno, item?.parts?.description];
      case "stocklocation":
        return [`${item?.id}`, `${item.aisle} ${item?.rack} ${item?.level}`];
      case "partsupplier":
        return [`${item?.supplier?.name}`, `${item?.unitCost}`];
      default:
        return [];
    }
  };

  const text = getText();

  return text.length > 0 ? (
    <div
      className={`contentListItem ${isSelected ? "selected" : ""}`}
      onClick={(e) => onListItemClick(e, JSON.stringify(item))}
    >
      <div data-item={JSON.stringify(item)}>{text[0]}</div>
      <div>{text[1]}</div>
    </div>
  ) : null;
}

export default ContentListItem;
