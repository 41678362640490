import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";
import SidePanelFormRow from "@ui/SidePanelFormRow";
import Input from "@ui/Input";
import DropdownList from "@ui/DropdownList";
import DateTimePicker from "@generic/DateTimePicker";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    apiGroupId: yup.string().required("This field is required"),
    apiId: yup.string().required("This field is required"),
    ticketAssignedToId: yup.string().required("This field is required"),
    targetDate: yup.string().required("This field is required"),
    ticketTypeId: yup.string().required("This field is required"),
    issueDetail: yup.string().required("This field is required"),
  })
  .required();

export default function AddTicket({
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
    dropdownData2: [],
    dropdownData3: [],
    dropdownData4: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      apiGroupId: "",
      apiId: "",
      fixedFlag: false,
      // fixedDate: "",
      ticketAssignedToId: "",
      targetDate: "",
      ticketTypeId: "",
      issueDetail: "",
      solution: "",
    },
  });
  const apiGroupId = watch("apiGroupId");

  // const { openModal } = useModal();

  // useEffect(() => {
  //   console.log("dropdownData:", dropdownData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // * UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
    Hooks.getData(`${API_ENV}/Api?ApiGroupId=${apiGroupId}`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData2: data,
      }));
    });
    Hooks.getData(`${API_ENV}/Staff`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData3: data,
      }));
    });
    Hooks.getData(`${API_ENV}/TicketType`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData4: data,
      }));
    });
  }, [apiGroupId]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      // Modify the targetDate to ISO 8601 format if it exists
      if (data.targetDate) {
        data.targetDate = new Date(data.targetDate).toISOString();
      }

      let modifiedApiUrl = apiUrl;
      if (apiUrl.includes("add")) {
        modifiedApiUrl = apiUrl.replace("add", "");
      }

      console.log("handleSubmitForm data:", data);

      Hooks.sendData(
        data,
        `${API_ENV}/${modifiedApiUrl}`,
        `POST`,
        thenFunction
      );

      // const fileInput = document.querySelector('input[type="file"]');

      // if (fileInput.files[0]) {
      // console.log("yes attach");
      // Hooks.sendDataWithFile(
      //   data,
      //   `${API_ENV}/${modifiedApiUrl}`,
      //   `${API_ENV}/ticket/uploadfile/`,
      //   thenFunction
      // );
      // } else {
      //   console.log("no attach");
      //   Hooks.sendData(
      //     data,
      //     `${API_ENV}/${modifiedApiUrl}`,
      //     `POST`,
      //     thenFunction
      //   );
      // }
    },
    [refreshTable, cleanURL, apiUrl, reset]
  );

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      // { text: "Open Modal", click: handleOpenModal },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <DropdownList
            label={"API Group"}
            name="apiGroupId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            optionName="groupName"
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Menu Path"}
            name="apiId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData2}
            optionName="menuPath"
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Ticket Type"}
            name="ticketTypeId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData4}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"Assigned To"}
            name="ticketAssignedToId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData3}
            optionNames={["forename", "surname"]}
          />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <label>Target Date</label>
          <Controller
            name="targetDate"
            control={control}
            render={({ field, fieldState }) => (
              <DateTimePicker
                classValue="input medium center"
                value={field.value}
                onChange={field.onChange}
                hideTime={false}
                error={fieldState.error}
              />
            )}
          />
        </SidePanelFormRow>

        {/* <SidePanelFormRow></SidePanelFormRow> */}

        <SidePanelFormRow>
          <Input
            label={"Fixed?"}
            name="fixedFlag"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>

        {/* <SidePanelFormRow>
          <Input
            label={"Fixed Date"}
            name="fixedDate"
            control={control}
            errors={errors}
            readOnly
          />
        </SidePanelFormRow> */}

        <SidePanelFormRow type="full">
          <label>Issue Detail</label>
          <textarea
            {...register("issueDetail")}
            rows="5"
            className="input left"
          ></textarea>
          {errors.issueDetail && (
            <span className="fieldError">{errors.issueDetail.message}</span>
          )}
        </SidePanelFormRow>

        <SidePanelFormRow type="full">
          <label>Solution (Optional)</label>
          <textarea
            {...register("solution")}
            // rows="5"
            className="input left"
          ></textarea>
          {errors.solution && (
            <span className="fieldError">{errors.solution.message}</span>
          )}
        </SidePanelFormRow>

        {/* <SidePanelFormRow>
          <label>Upload File (Optional)</label>
          <FileInput {...register("fileAttachment")} />
        </SidePanelFormRow> */}
      </div>

      {/* <Modal name="Example" title={"Title"}>
       
      </Modal> */}
    </Form>
  );
}
