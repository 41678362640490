import API_ENV from "@config";
import Hooks from "@hooksFile";
import React, { useRef, useState, useCallback } from "react";
import "../styles/ui/SearchBar.scss";
import toast from "react-hot-toast";

export default function TagsSearchBar({
  objectDocTypeId,
  objectDocId,
  title,
  type,
  setOutsideFormData,
  isModal,
}) {
  const [content, setContent] = useState([]);
  const searchBar = useRef(null);

  //* This updates the parent component/outside form
  const handleSearchItemClick = useCallback(
    (data) => {
      data = JSON.parse(data);
      // console.log("handlePropertyClick data: ", data);

      if (!isModal) {
        function thenFunction() {
          Hooks.getData(
            `${API_ENV}/tagsDoc/byObject/${objectDocTypeId}/${objectDocId}`,
            (data) => {
              // console.log("Attempt 1: ", data);
              setOutsideFormData([...data]);
            }
          );

          if (searchBar.current) {
            searchBar.current.value = "";
          }

          setContent([]);
          toast.success("Tag added");
        }

        const dataObject = data.id;

        Hooks.sendData(
          {},
          `${API_ENV}/tagsDoc/oneByone?TagId=${dataObject}&ObjectDocId=${objectDocId}&ObjectDocTypeId=${objectDocTypeId}`,
          "POST",
          thenFunction,
          true
        );
      } else {
        setOutsideFormData((prevData) => [...prevData, data]);

        if (searchBar.current) {
          searchBar.current.value = "";
        }

        setContent([]);
      }
    },
    [isModal, objectDocId, objectDocTypeId, setOutsideFormData]
  );

  //* This calls API when typing characters in search bar
  const onType = useCallback(
    (value) => {
      // if (value.length < 2) {
      //   setContent([]);
      //   return;
      // }

      const urls = {
        tags: (value) => `${API_ENV}/tags?TagsValue=${value}&showarchive=false`,
      };

      Hooks.getData(urls[type](value), (data) => {
        // console.log("data:", data);
        if (!data || !data.length) {
          setContent([]);
          return;
        }

        const array = data.map((item, index) => {
          const itemKey = `${type}-${item.id || index}`;
          const text = {
            tags: `${item?.name}`,
          };

          return (
            <div
              key={itemKey}
              data={JSON.stringify(item)}
              onClick={(e) =>
                handleSearchItemClick(e.target.getAttribute("data"))
              }
              value={item.id}
            >
              {text[type]}
            </div>
          );
        });

        setContent(array);
      });
    },
    [type, handleSearchItemClick]
  );

  //* Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action, such as form submission
      event.stopPropagation(); // Stop the event from bubbling up
    }
  };

  return (
    <div className="searchBarAutoFill">
      <input
        ref={searchBar}
        type="text"
        placeholder={`Search for ${title}...`}
        className="input medium"
        onChange={(e) => onType(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {content && <div className="searchBarResults">{content}</div>}
    </div>
  );
}
