import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Hooks from "@hooksFile";
import Form from "@ui/Form";

import Row from "@ui/Row";
import Heading from "@ui/Heading";
import FormRow from "@ui/FormRow";
import HR from "@ui/HR";
import Button from "@ui/Button";
import Tags from "@ui/Tags";

// It is important for data types to correspond correctly otherwise API data might not send. Example:
// string = yup.string()
// boolean = yup.bool() etc
const schema = yup
  .object({
    // * BOOLEANS need to be yup.bool()
    // tags: yup.string().required("This field is required"),
    // tags: yup.array().of(yup.string()).required("This field is required"),
  })
  .required();

export default function AddTagModalForm({ objectDocIdArray }) {
  const [confirmationScreen, setConfirmationScreen] = useState(false);
  const [objectDocTypeId, setObjectDocTypeId] = useState();
  const [tagFormData, setTagFormData] = useState([]);

  const {
    // control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // tags: "",
    },
  });

  // const { data: stateData } = state;

  // useEffect(() => {
  //   console.log("tagFormData:", tagFormData);
  //   console.log("objectDocTypeId:", objectDocTypeId);
  //   console.log("objectDocIdArray:", objectDocIdArray);
  // }, [tagFormData, objectDocTypeId, objectDocIdArray]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log("Form values:", values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  useEffect(() => {
    if (Hooks.getCookie("last_page") === "jobs") {
      setObjectDocTypeId(1);
    } else if (Hooks.getCookie("last_page") === "properties") {
      setObjectDocTypeId(3);
    } else if (Hooks.getCookie("last_page") === "staff") {
      setObjectDocTypeId(4);
    }
  }, []);

  // HANDLE Functions
  // !TO DO
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        reset();
      }

      // let url = `${API_ENV}/tagsDoc/multipleBymultiple/${objectDocTypeId}?TagIds=2&TagIds=3&ObjectDocIds=512&ObjectDocIds=1024`;

      const dataObject = tagFormData;
      // console.log("handleSubmitForm dataObject:", dataObject);
      // console.log("handleSubmitForm objectDocIdArray:", objectDocIdArray);

      // Extract ids from dataObject
      const tagIds = dataObject.map((item) => item.id);

      // Construct the TagIds part of the URL
      const tagIdsParam = tagIds
        .map((id, index) => {
          return `${index === 0 ? "?TagIds=" : "&TagIds="}${id}`;
        })
        .join("");

      // Construct the ObjectDocIds part of the URL
      const objectDocIdsParam = objectDocIdArray
        .map((id) => {
          return `&ObjectDocIds=${id}`;
        })
        .join("");

      // Combine both parts into the final URL
      let url = `${API_ENV}/tagsDoc/multipleBymultiple/${objectDocTypeId}${tagIdsParam}${objectDocIdsParam}`;

      // console.log("Generated URL:", url);

      Hooks.sendData({}, url, "POST", thenFunction);
    },
    [tagFormData, objectDocIdArray, objectDocTypeId, reset]
  );

  return (
    <Form type="modalForm">
      {!confirmationScreen && (
        <>
          <Row>
            <Heading as="h5">Add Tags to selected rows.</Heading>
          </Row>

          <br />

          <Tags
            // objectDocTypeId =  1 = Job
            // objectDocTypeId =  2 = Equipment
            // objectDocTypeId =  3 = Property
            // objectDocTypeId =  4 = Staff
            // objectDocId =  ID of Job/Equipment/Property/Staff
            objectDocTypeId={objectDocTypeId}
            objectDocIdArray={objectDocIdArray}
            isModal={true}
            setTagFormData={setTagFormData}
          />
        </>
      )}

      {/* Confirmation Screen */}
      {confirmationScreen && (
        <>
          <Row>
            <Heading as="h5">
              Are you sure you want to {confirmationScreen}?
            </Heading>
          </Row>

          <HR />

          {confirmationScreen === "save" ? (
            <Row type="center">
              <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationScreen(false);
                }}
              >
                Cancel
              </Button>

              <Button
                size="small"
                type="danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(handleSubmitForm());
                }}
              >
                Save
              </Button>
            </Row>
          ) : null}
        </>
      )}

      {/* Bottom Modal Buttons */}
      {!confirmationScreen && (
        <>
          <HR />

          <FormRow position={"center"}>
            <Button
              size={"small"}
              onClick={(e) => {
                e.preventDefault();
                setConfirmationScreen("save");
              }}
            >
              Save
            </Button>
          </FormRow>
        </>
      )}
    </Form>
  );
}
