import React, { useState } from "react";
import Hooks from "../../../Hooks";
import API_ENV from "@config";

export default function Processes() {
  const [content, updateContent] = useState();

  function search(id) {
    if (id) {
      Hooks.getData(
        `${API_ENV}/runRequestProc/${id}`,
        (data) => {
          updateContent(data);
        }
      );
    }
  }

  function getContent(type) {
    if (!content || !type || !content[type]) {
      return "No Value";
    }

    if (type === "message") {
      return content.runRequestMessage[0];
    } else if (type.toLowerCase().includes("time")) {
      return new Date(content[type]).toDateString();
    } else {
      return content[type];
    }
  }

  function rowComponent(type, value) {
    return (
      <div className="processRow">
        <div className="processKey">{type}</div>
        <div className="processValue">{value}</div>
      </div>
    );
  }

  return (
    <div id="processContainer">
      <div id="processInputs">
        <input id="processIdInput" type="text" placeholder="id" />
        <div
          onClick={() => {
            search(document.querySelector("#processIdInput").value);
          }}
        >
          Search
        </div>
      </div>
      <div id="processContents">
        {rowComponent("ID", getContent("id"))}
        {rowComponent("Name", getContent("name"))}
        {rowComponent("Status", getContent("status"))}
        {rowComponent("Start Time", getContent("startTime"))}
        {rowComponent("End Time", getContent("endTime"))}
        {rowComponent("Message", getContent("message"))}
      </div>
    </div>
  );
}
