import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Hooks from "../../../Hooks";
import Form from "../../../ui/Form";
import SidePanelFormRow from "../../../ui/SidePanelFormRow";
import Input from "../../../ui/Input";
import DropdownList from "../../../ui/DropdownList";
import CollapsibleGroup from "../../../ui/CollapsibleGroup";

// Form Validation Schema
const schema = yup
  .object({
    name: yup.string().required("Report Name is required"),
    runReportTypeId: yup.string().required("Report Type is required"),
    fileNamePrefix: yup.string().required("File Name Prefix is required"),
    queryTable: yup.string().required("Query Table is required"),
    apiGroupId: yup.string().required("API Group is required"),
  })
  .required();

export default function AddDefineReport({
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [dropdownData, setDropdownData] = useState({
    apiGroupData: [],
    reportTypeData: [],
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      runReportTypeId: "",
      fileNamePrefix: "",
      queryTable: "",
      apiGroupId: "",
    },
  });

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // API Group
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup?isRootLevel=true`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        apiGroupData: data,
      }));
    });
  }, []);

  const handleReportTypeChange = (event) => {
    const selectedValue = event.target.value;
    // Check if "PDF" is selected
    if (selectedValue === "1") {
      // assuming "1" is the id for PDF
      setValue("queryTable", "PdfJobNumView");
    } else {
      setValue("queryTable", ""); // Optionally clear or set to default for other options
    }
  };

  // HANDLE Functions
  // React Hook Form & Yup version
  const handleAdd = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
        reset();
      }

      Hooks.sendData(data, `${API_ENV}/runReportList`, "POST", thenFunction);
    },
    [refreshTable, cleanURL, reset]
  );

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Add", click: handleSubmit(handleAdd) },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, handleSubmit, handleAdd, closeFunction]);

  return (
    <Form>
      <CollapsibleGroup title="Form">
        <div className="sidePanelFormRowsContainer">
          <SidePanelFormRow>
            <Input
              label={"Report Name"}
              name="name"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"Report Type"}
              name="runReportTypeId"
              control={control}
              errors={errors}
              dropdownData={[
                { id: 1, name: "PDF" },
                { id: 2, name: "CSV" },
                { id: 3, name: "Excel" },
              ]}
              customOnChange={handleReportTypeChange}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"File Name Prefix"}
              name="fileNamePrefix"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <Input
              label={"Query Table"}
              name="queryTable"
              control={control}
              errors={errors}
            />
          </SidePanelFormRow>

          <SidePanelFormRow>
            <DropdownList
              label={"API Group"}
              name="apiGroupId"
              control={control}
              errors={errors}
              dropdownData={dropdownData.apiGroupData}
              optionName={"groupName"}
            />
          </SidePanelFormRow>
        </div>
      </CollapsibleGroup>
    </Form>
  );
}
