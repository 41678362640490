import API_ENV from "@config";
import Hooks from "../../../Hooks";
import Swal from "sweetalert2";

import Dropdown from "../../generic/Dropdown";
import TextAreaInput from "../../generic/TextAreaInput";
import JobStateType from "./JobStateType";
import NewDropdown from "../../generic/NewDropdown";

import SearchForPart from "./../../forms/Jobs/SearchForPart";
import PartSearch from "../../forms/SearchForPart/PartSearch";
import ChooseWarehouse from "./../../forms/PurchaseRequests/ListSelections/ChooseWarehouse";
import SearchField from "../../forms/PurchaseRequests/SearchField";
import PartList from "./../../forms/SearchForPart/PartList";
import InspectionQuestions from "./../../forms/Inspections/InspectionSets/InspectionQuestions";
import TimeDropdown from "./../../forms/Contracts/TimeDropdown/TimeDropdown";
import InspectionTypeSelect from "./../../forms/Inspections/InspectionSets/InspectionTypeSelect";
import ScheduleTimePicker from "./../../forms/Contracts/ScheduleTimePicker/ScheduleTimePicker";
import SubJobs from "./../../forms/Jobs/SubJobs/SubJobs";
import NotesTodo from "./../../forms/NotesAndTodo/NotesTodo";
import EqDropdown from "./../../forms/FormComponents/EquipmentCategory/EqDropdown";
import { useTempFix } from "../../../context/AddJobQuickFix";
import AddressInputOldNonRHF from "../../forms/AddressInputOldNonRHF";
import DateTimePickerOldNonRHF from "@generic/DateTimePickerOldNonRHF";
// import { useEffect } from "react";

function firstDropdownValue(type, data) {
  // console.log("type: ", type);
  // console.log("data: ", data);

  switch (type) {
    case "jobcontract":
    case "jobcontract2":
    case "contract":
      return { value: data?.contract?.id, label: data?.contract?.name };
    case "engineer":
      if (data?.engineer) {
        return {
          value: data?.engineer?.id,
          label: data?.engineer?.forename + " " + data?.engineer?.surname,
        };
      } else {
        return { value: 0, label: "Select Engineer" };
      }
    case "priority":
      return { value: data?.priority?.id, label: data?.priority?.name };
    case "invoiced":
      return { value: data?.invoiced, label: toString(data?.invoiced) };
    case "jobstate":
      return {
        value: data?.jobState?.id,
        label: data?.jobState?.description,
      };
    case "jobtype":
      return { value: data?.jobType?.id, label: data?.jobType?.description };
    case "jobtypeschedule":
      return { value: data?.jobTypeRefId, label: data?.jobType?.description };
    case "client":
      return { value: data?.client?.id, label: data?.client?.name };

    case "equipmenttype":
      return {
        value: data?.equipmentType?.id,
        label: data?.equipmentType?.model,
      };
    case "equipmentcategory":
      if (data.equipmentCategoryId) {
        return {
          value: data?.equipmentCategoryId,
          label: data?.equipmentCategory?.name,
        };
      } else {
        return {
          value: data?.equipmentCategoryRefId,
          label: data?.equipmentCategory?.name,
        };
      }
    case "costtype":
      return { value: data?.costType?.id, label: data?.costType?.name };
    case "qcstatus":
      return {
        value: data?.qcStatus?.id,
        label: data?.qcStatus?.description,
      };
    case "inspectiontype":
      return {
        value: data?.inspectionType?.id,
        label: data?.inspectionType?.name,
      };
    case "primary":
      return { value: data?.primary, label: data?.primary };
    case "isvan":
      return { value: data?.isVan, label: data?.isVan };
    case "visitstate":
      return {
        value: data?.visitState?.id,
        label: data?.visitState?.description,
      };
    case "nextjobstate":
      return {
        value: data?.nextJobState?.id,
        label: data?.nextJobState?.description,
      };
    case "currentjobstate":
      return {
        value: data?.currJobState?.id,
        label: data?.currJobState?.description,
      };
    case "equipmentsubcategory":
      return {
        value: data?.equipmentSubCategory?.id,
        label: data?.equipmentSubCategory?.name,
      };
    case "chargeable":
      return { value: data?.chargeable, label: data?.chargeable };
    case "jobcategory":
      return {
        value: data?.jobCategory?.id,
        label: data?.jobCategory?.description,
      };
    case "completeflag":
      return { value: data?.complete_flag, label: data?.complete_flag };
    case "parts":
      return { value: data.parts.id, label: data.parts.partno };
    case "warehouse":
      return { value: data?.warehouse?.id, label: data?.warehouse?.name };
    case "jobstatetransition":
      return {
        value: data?.currJobStateId,
        label: data?.currJobState?.description,
      };
    case "nextstatetransition":
      return {
        value: data?.nextJobStateId,
        label: data?.nextJobState?.description,
      };
    case "compulsory_flag":
      return { value: data?.compulsory_flag, label: data?.compulsory_flag };
    case "currentvisitstate":
      return {
        value: data?.currVisitState?.id,
        label: data?.currVisitState?.description,
      };
    case "nextvisitstate":
      return {
        value: data?.nextVisitState?.id,
        label: data?.nextVisitState?.description,
      };
    case "stafftype":
      return {
        value: data?.staffType?.id,
        label: data?.staffType?.description,
      };
    case "targetwarehouse":
      return {
        value: data?.targetWarehouseRefId,
        label: data?.targetWarehouse?.name,
      };
    case "jobpartstate":
      return {
        value: data?.jobPartState?.id,
        label: data?.jobPartState?.name,
      };
    case "fittedfromvan":
      return {
        value: data?.fittedFromVan?.id,
        label: data?.fittedFromVan?.name,
      };
    case "sourcewarehouse":
      return {
        value: data?.sourceWarehouse?.id,
        label: data?.sourceWarehouse?.name,
      };
    case "destinationwarehouse":
      return {
        value: data?.destinationWarehouse?.id,
        label: data?.destinationWarehouse?.name,
      };
    case "allowedtoread":
    case "allowedtoupdate":
    case "allowedtocreate":
    case "allowedtodelete":
    case "smsupdate":
    case "hassubjobs":
      return {
        value: data[Hooks.resetKey(type)],
        label: data[Hooks.resetKey(type)],
      };
    case "apigroup":
      return { value: data?.apiGroup?.id, label: data?.apiGroup?.groupName };
    case "supplier":
      return { value: data?.supplier?.id, label: data?.supplier?.name };
    case "targetsupplier":
    case "preferredsupplier":
      return {
        value: data?.targetSupplier?.id,
        label:
          Hooks.conditionalString(data?.targetSupplier?.name) === "nil"
            ? "Non selected"
            : data?.targetSupplier?.name,
      };
    case "deliverynotestate":
      return {
        value: data?.deliveryNoteStateId,
        label: data?.deliveryNoteState?.name,
      };
    case "preferredwarehouse":
      return {
        value: data?.targetWarehouse?.id,
        label: data?.targetWarehouse?.name,
      };
    case "partscategory":
      return {
        value: data?.partsCategory?.id,
        label: data?.partsCategory?.name,
      };
    case "partssubcategory1":
      return {
        value: data?.partsSubCategory1?.id,
        label: data?.partsSubCategory1?.name,
      };
    case "partssubcategory2":
      return {
        value: data?.partsSubCategory2?.id,
        label: data?.partsSubCategory2?.name,
      };
    case "partssubcategory3":
      return {
        value: data?.partsSubCategory3?.id,
        label: data?.partsSubCategory3?.name,
      };

    case "apigrouproot":
      return {
        value: data?.apiGroup?.id,
        label: data?.apiGroup?.groupName,
      };
    case "reportname":
      return {
        value: data?.id,
        label: data?.name,
      };
    case "exportformat":
      return {
        value: data?.id,
        label: data?.name,
      };

    default:
      return { value: 0, label: "No value" };
  }
}

function verifyInput(target, key) {
  if (!target || !key) {
    return;
  }

  const value = target.value;

  function toggleValid(condition, target) {
    if (condition) {
      target.classList.remove("badInput");
      target.classList.add("validInput");
    } else {
      target.classList.remove("validInput");
      target.classList.add("badInput");
    }
  }

  if (
    [
      "name",
      "username",
      "password",
      "deliverynotenumber",
      "partno",
      "uprn",
      "description",
      "price",
      "reason",
    ].includes(key)
  ) {
    toggleValid(target.value, target);
  } else if (key.includes("email")) {
    function isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    toggleValid(isValidEmail(value), target);
  } else if (key.includes("mobile")) {
    function isValidUKMobileNumber(number) {
      const ukMobileRegex = /^(?:\+44|0)7\d{9}$/;
      return ukMobileRegex.test(number);
    }

    toggleValid(isValidUKMobileNumber(value), target);
  } else if (key.includes("landline")) {
    function isValidUkLandline(phoneNumber) {
      // Remove any non-digit characters from the phone number
      const cleanedNumber = phoneNumber.replace(/\D/g, "");

      // Check if the cleaned number starts with the correct UK landline prefix
      if (cleanedNumber.startsWith("01") || cleanedNumber.startsWith("02")) {
        // Check if the cleaned number has a valid length (10 or 11 digits)
        if (cleanedNumber.length === 10 || cleanedNumber.length === 11) {
          return true;
        }
      }

      // The phone number is not a valid UK landline number
      return false;
    }

    toggleValid(isValidUkLandline(value), target);
  }
}

// Todo, rework this whole function to be more readable and maintainable
export default function SidePanelRow({
  readOnly,
  title,
  value,
  fullData,
  onChange,
  viewFlyout,
  searchClicked,
  dataId,
  long,
  partSearch,
  selectedPart,
}) {
  const { globalData } = useTempFix();
  let className = "",
    keyClass = "sidePanelKey";
  let titleCondition = Hooks.conditionalString(title);

  // useEffect(() => {
  //   // console.log("title", title);
  //   console.log("titleCondition", titleCondition);
  //   // console.log("fullData", fullData);
  // }, [titleCondition]);

  // Todo create hook for id to shorten them.
  let id = `${titleCondition}Input`;
  if (id === "jobcontractInput") {
    id = "contractInput";
  }

  if (Hooks.conditionalString(value) === "null") {
    value = "";
  }

  let type = "text";

  if (titleCondition.includes("quantity")) {
    type = "number";
  }

  let input = (
    // <BasicInput
    //   id={id}
    //   key={value}
    //   type={type}
    //   size="medium"
    //   // className="textInput sidePanelInput"
    //   maxLength={80}
    //   defaultValue={value}
    //   onChange={(e) => {
    //     verifyInput(e.target, titleCondition);
    //   }}
    // />

    <input
      id={id}
      key={value}
      type={type}
      className="textInput sidePanelInput"
      maxLength={80}
      defaultValue={value}
      onChange={(e) => {
        verifyInput(e.target, titleCondition);
      }}
    />
  );
  let time = "";

  let key = <div className={keyClass}>{Hooks.formatSidePanelKey(title)}</div>;

  // console.log(title);
  if (title === "Client Job") {
    key = <div className={keyClass}>Client</div>;
  } else if (title === "Client Job 2") {
    key = <div className={keyClass}>Client</div>;
  }

  const readOnlyInputs = [
    "purchaseorderid",
    "purchaserequestid",
    "clientjob",
    "clientjob2",
    "purchaseorderstate",
    "sorcode",
    "sordescription",
    "state",
    "statemessage",
    "deliverynotestate",
    "powarehouse",
    "polocation",
  ];
  const exclusions = ["stockaction"];

  if (
    (readOnly || readOnlyInputs.includes(titleCondition)) &&
    !exclusions.includes(titleCondition)
  ) {
    if (
      titleCondition.includes("date") ||
      titleCondition.includes("raisedon")
    ) {
      value = new Date(value).toDateString();
    }

    // Temporary - will need a function to do this for everything.
    if (titleCondition === "deliverynotestate") {
      value = fullData?.deliveryNoteState?.name;
    } else if (titleCondition === "warehouse") {
      value = fullData?.warehouse?.name;
    } else if (titleCondition === "stocklocation") {
      value = fullData?.stockLocation?.aisle;
    } else if (titleCondition === "parts") {
      value = fullData?.parts?.partno;
    } else if (titleCondition === "clientjob") {
      value = fullData?.client?.name;
    } else if (titleCondition === "clientjob2") {
      // TEMP FIX
      value = globalData?.client?.name;
    } else if (titleCondition === "sourcewarehouse") {
      value = fullData?.sourceWarehouse?.name;
    } else if (titleCondition === "destinationwarehouse") {
      value = fullData?.destinationWarehouse?.name;
    } else if (titleCondition === "quantity") {
      value = fullData?.quantity;
    }

    return (
      <div className={"sidePanelRow" + className}>
        {key}
        <div className="rowInputContainer">
          <input
            id={titleCondition}
            key={`${titleCondition}_${value}`}
            value={value}
            dataid={dataId}
            className="readOnly sidePanelInput"
            type="text"
            readOnly
          />
        </div>
      </div>
    );
  }

  // Eventually we want all the dropdowns to be the "newdropdown" component.
  const newDropdownInputs = [
    "client",
    "contract",
    "contracttoproperty",
    "documenttag",
    "engineer",
    "jobcontract",
    "jobcontract2",
    "targetsupplier",
    "visit",
    "supplier",
    "deliverynotestate",
    "allowedtoupdate",
    "allowedtoread",
    "allowedtodelete",
    "allowedtocreate",
    "preferredsupplier",
    "preferredwarehouse",
    "priority",
    "invoiced",
    "qcstatus",
    "stockaction",
    "partscategory",
    "partssubcategory1",
    "partssubcategory2",
    "partssubcategory3",
    "partssubcategory4",
    "purchaseorder",
    "podetails",
    "isvan",
    "stafftype",
    "apigroup",
    "currentvisitstate",
    "nextvisitstate",
    "jobtype",
    "jobtypeschedule",
    "smsupdate",
    "role",
    "equipmentcategory",
    "primary",
    "visitstate",
    "costtype",

    "apigrouproot",
    "reportname",
    "exportformat",
  ];

  if (newDropdownInputs.includes(titleCondition)) {
    const firstValue = firstDropdownValue(titleCondition, fullData);
    // console.log("firstValue: ", firstValue);
    // console.log("titleCondition: ", titleCondition);
    // console.log("fullData: ", fullData);

    if (titleCondition === "stockaction") {
      titleCondition = fullData?.warehouse?.isVan
        ? "stockactionvan"
        : "stockaction";
    }

    if (titleCondition === "purchaseorder") {
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstValue}
          onChange={onChange}
        />
      );
    } else if (
      [
        "allowedtoupdate",
        "allowedtoread",
        "allowedtodelete",
        "allowedtocreate",
        "invoiced",
        "isvan",
        "smsupdate",
        "primary",
      ].includes(titleCondition)
    ) {
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstDropdownValue(titleCondition, fullData)}
          isBool={true}
        />
      );
    } else if (titleCondition === "jobcontract") {
      const url = `${API_ENV}/contracts?ClientId=${fullData.clientRefId}`;
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstDropdownValue(titleCondition, fullData)}
          customUrl={url}
        />
      );
    } else if (titleCondition === "jobcontract2") {
      // const url = `${API_ENV}/contracts?ClientId=${30}`;
      const url = `${API_ENV}/contracts?ClientId=${globalData.clientRefId}`;
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstDropdownValue(titleCondition, fullData)}
          customUrl={url}
        />
      );
    } else if (
      titleCondition === "preferredsupplier" &&
      !Hooks.getCookie("last_page").includes("requests")
    ) {
      const url = `${API_ENV}/partsAndSupplier?PartsNo=${fullData?.parts?.partno}`;
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstDropdownValue(titleCondition, fullData)}
          customUrl={url}
        />
      );
    } else {
      // This is where Client/API Group/Report Name etc dropdown is using NewDropDown
      // console.log(titleCondition)
      // here
      input = (
        <NewDropdown
          key={firstValue.label}
          type={titleCondition}
          topValue={firstValue}
        />
      );
    }
  }

  const dropdownInputs = [
    "answertype",
    "cancelreason",
    "chargeable",
    "compulsory_flag",
    "currentjobstate",
    "destinationwarehouse",
    "equipmentsubcategory",
    "hassubjobs",
    "inspectionset",
    "inspectionsettype",
    "jobcategory",
    "jobstate",
    "nextjobstate",
    "parts",
    "sourcewarehouse",
    "targetwarehouse",
    "warehouse",
  ];

  if (dropdownInputs.includes(titleCondition)) {
    let dropdownType = titleCondition;
    if (
      titleCondition === "sourcewarehouse" ||
      titleCondition === "destinationwarehouse"
    ) {
      dropdownType = "warehouse";
    }

    input = (
      <Dropdown
        id={id}
        className="sidePanelSelect"
        render={value}
        type={dropdownType}
        firstValue={firstDropdownValue(titleCondition, fullData)}
        onChange={onChange}
      />
    );
  }

  const searchInputs = [
    "equipmenttypesearch",
    "searchforsor",
    "searchforstock",
    "searchforsupplier",
  ];

  if (searchInputs.includes(titleCondition)) {
    return <SearchForPart type={titleCondition.replace("searchfor", "")} />;
  }

  const dateInputs = [
    "arrivaldate",
    "dateInput",
    "logged",
    "booked",
    "qcdate",
    "enddate",
    "startdate",
    "anniversary",
    "datefrom",
    "dateexpire",
  ];

  // Temporary fix.
  if (
    dateInputs.some((dateInputId) => id.includes(dateInputId)) &&
    titleCondition !== "allowedtoupdate" &&
    titleCondition !== "smsupdate"
  ) {
    input = (
      <DateTimePickerOldNonRHF
        id={id}
        value={value}
        classValue="sidePanelInput"
      />
    );
  }

  // console.log("titleCondition", titleCondition);
  switch (titleCondition) {
    case "searchforpart":
      input = <PartSearch search={partSearch} />;
      break;
    case "pounitcost":
      function getLastPoPrice(type) {
        const supplierId = Hooks.getSidePanelData()?.supplierRefId;
        const partId = document.querySelector("#partid").value;

        if (!supplierId) {
          Hooks.displayError("Error loading supplier.");
          return;
        }

        if (!partId) {
          Hooks.displayError("Please select a part.");
          return;
        }

        let url = `${API_ENV}/poDetail/GetLatestPoPrice/${partId}/${supplierId}`;

        if (type === "default") {
          url = `${API_ENV}/partsAndSupplier/FindCostQtybyPartSupplier/${partId}/${supplierId}`;
        }

        Hooks.getData(url, (data) => {
          if (typeof data === "string") {
            Swal.fire({
              text: data,
              icon: "info",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            if (data?.unitCost || data?.unitPrice) {
              document.querySelector("#pounitcost").value =
                data?.unitCost || data?.unitPrice;
            } else {
              document.querySelector("#pounitcost").value = "No value.";
            }
          }
        });
      }

      input = (
        <div id="pounitcostContainer">
          <input id="pounitcost" type="text" />
          <div
            className="button"
            onClick={() => {
              getLastPoPrice("last");
            }}
          >
            Last PO Price
          </div>
          <div
            className="button"
            onClick={() => {
              getLastPoPrice("default");
            }}
          >
            Default
          </div>
        </div>
      );
      break;
    case "directlyallocatetojob":
    case "override":
      input = <input id="directlyallocateInput" type="checkbox" />;
      break;
    case "warehouselocation":
      let stockLocation = {};
      let warehouse = {};
      if (fullData?.targetWarehouse) {
        warehouse.name = fullData?.targetWarehouse?.name;
        warehouse.value = fullData?.targetWarehouse?.id;
      } else if (fullData?.defaultWarehouse) {
        warehouse.name = fullData?.defaultWarehouse?.name;
        warehouse.value = fullData?.defaultWarehouse?.id;
      } else if (Hooks.getSidePanelData()) {
        const sidePanelData = Hooks.getSidePanelData();
        warehouse.name = sidePanelData?.defaultWarehouse?.name;
        warehouse.value = sidePanelData?.defaultWarehouse?.id;
      }

      if (fullData?.defaultStockLocation) {
        stockLocation = fullData.defaultStockLocation;
      } else {
        stockLocation = Hooks.getSidePanelData()?.defaultStockLocation;
      }

      return (
        <ChooseWarehouse
          stockLocation={stockLocation}
          warehouseValue={warehouse}
        />
      );
    case "targetjob":
    case "stocklocation":
    case "parts":
      let dataId;
      if (titleCondition === "stocklocation") {
        value = `Aisle: ${fullData?.stockLocation?.aisle} => Rack: ${fullData?.stockLocation?.rack} => Level: ${fullData?.stockLocation?.level}`;
        dataId = fullData?.stockLocationId;
      } else if (titleCondition === "parts") {
        value = fullData?.parts?.partno;
        dataId = fullData?.partsRefId;
      }

      input = (
        <SearchField
          id={`${titleCondition}Search`}
          dataId={dataId}
          defaultText={
            value || `No ${Hooks.formatKey(titleCondition)} assigned`
          }
          searchClicked={(e) => {
            searchClicked(
              e.target.parentElement.querySelector("input"),
              titleCondition
            );
          }}
        />
      );
      break;
    case "equipmentcategorysub":
      const equipmentCategory = fullData?.equipmentCategory
        ? {
            id: fullData.equipmentCategory.id,
            label: fullData.equipmentCategory.name,
          }
        : null;
      const subCategory = fullData?.equipmentSubCategory
        ? {
            id: fullData.equipmentSubCategory.id,
            label: fullData.equipmentSubCategory.name,
          }
        : null;
      return (
        <EqDropdown
          equipmentCategory={equipmentCategory}
          subCategory={subCategory}
        />
      );
    case "parentjobid":
      input = (
        <input
          id={titleCondition}
          className="sidePanelInput"
          placeholder="Optional"
        />
      );
      break;
    case "file":
      input = <input id="fileInput" type="file" />;
      break;
    case "newpassword":
    case "confirmpassword":
      input = (
        <input
          id={titleCondition}
          key={titleCondition}
          value={value}
          className="sidePanelInput"
          type="password"
        />
      );
      break;
    case "partsmessage":
      input = (
        <textarea
          id={titleCondition}
          key={titleCondition}
          value={value}
          className="readOnly"
          type="text"
          rows={5}
          readOnly
        ></textarea>
      );
      break;
    case "partnumber":
    case "jobpartstate":
      const title = Hooks.conditionalString(
        document.querySelector("#sidePanelTitle").textContent
      );
      if (title.includes("add") || title.includes("purchaserequest")) {
        input = (
          <input
            id={titleCondition}
            key={`${titleCondition}_add`}
            value={value}
            className="sidePanelInput"
            type="text"
          />
        );
      } else {
        input = (
          <input
            id={titleCondition}
            dataid={selectedPart?.partId}
            key={`${titleCondition}_${value}`}
            value={selectedPart?.partNo}
            className="readOnly sidePanelInput"
            type="text"
            readOnly
          />
        );
      }
      break;
    case "completeddate":
    case "completed":
      if (value && new Date(value)) {
        const date = new Date(value);
        input = (
          <input
            key={`${titleCondition}_${value}`}
            className="readOnly sidePanelInput"
            type="text"
            readOnly
            value={`${date.toDateString()}, ${date.toLocaleTimeString()}`}
          />
        );
      } else {
        input = (
          <input
            key={`${titleCondition}_${value}`}
            className="readOnly sidePanelInput"
            type="text"
            readOnly
            value="Not complete"
          />
        );
      }
      break;
    case "partlist":
      return <PartList kitId={Hooks.getSidePanelData().id} />;
    case "jobtypestate":
      return (
        <JobStateType
          jobTypeValue={firstDropdownValue("jobtype", fullData)}
          jobStateValue={firstDropdownValue("jobstate", fullData)}
        />
      );
    case "subjobtypestate":
      const empty = { id: 0, label: "No Value" };
      return (
        <JobStateType
          jobTypeUrl={`${API_ENV}/jobType?HasSubjobs=false&${
            Hooks.getSidePanelData().jobType.jobCategoryRefId
          }`}
          jobTypeValue={empty}
          jobStateValue={empty}
          type="subjob"
        />
      );
    case "message":
      if (fullData && fullData.runRequestMessage) {
        if (fullData.runRequestMessage.length) {
          let formattedMessage = [];
          fullData.runRequestMessage.forEach((message) => {
            formattedMessage.push(<p>{message}</p>);
          });
          return <div id="processMessageContainer">{formattedMessage}</div>;
        } else {
          return (
            <div id="processMessageContainer">
              <p id="noProcessMessage">No message for this process.</p>
            </div>
          );
        }
      }
      break;
    case "property":
    case "postcode":
      if (
        Hooks.conditionalString(Hooks.getSidePanelTitle()).includes("addnew")
      ) {
        input = (
          <input
            id={`${titleCondition}Input`}
            className="readOnly sidePanelInput"
            key={`${titleCondition}_readOnly`}
            type="text"
            readOnly
          />
        );
      } else {
        if (fullData && fullData.property && fullData.property.address1) {
          input = (
            <input
              className="readOnly sidePanelInput"
              type="text"
              readOnly
              value={
                fullData.property.address1 + " " + fullData.property.postCode
              }
            />
          );
        }
      }
      break;
    case "massadd":
      input = <input id={id} className="sidePanelInput" type="checkbox" />;
      break;
    case "propertysearch":
    case "partsearch":
      console.error("Re-enable TypeSearchOldNonRHF inside SidePanelRow.js");
      // return (
      //   <TypeSearchOldNonRHF type={titleCondition.replace("search", "")} />
      // );
      break;
    case "questions":
      return <InspectionQuestions questions={fullData.inspectionQuestion} />;
    case "addequipmenttype":
      input = (
        <Dropdown
          id={id}
          className="sidePanelSelect"
          render={value}
          type={Hooks.conditionalString(title)}
        />
      );
      break;
    case "make":
    case "model":
    case "equiptype":
      input = (
        <input
          id={`${titleCondition}Input`}
          key={`${titleCondition}_${value}`}
          className="readOnly sidePanelInput"
          type="text"
          readOnly
          value={value || "Non Selected"}
        />
      );
      break;
    case "nextservice":
    case "lastservice":
      const dateValue =
        value && new Date(value)
          ? new Date(value).toDateString()
          : "Not assigned.";
      input = (
        <input
          key={`${titleCondition}_${dateValue}`}
          className="readOnly sidePanelInput"
          type="text"
          readOnly
          value={dateValue}
        />
      );
      break;
    case "scheduletimepicker":
      return <ScheduleTimePicker className={className} data={fullData} />;
    case "frequency":
      input = (
        <div id="frequencyContainer" className="schedulerRow">
          <input
            id="frequencyNumber"
            type="number"
            min={1}
            defaultValue={fullData.period || 1}
          />
          <Dropdown
            id="frequencyDrop"
            type="scheduler"
            firstValue={fullData.periodUnit}
          />
        </div>
      );
      break;
    case "mot":
      input = (
        <Dropdown id="mot" type="mot" firstValue={{ value: fullData.mot }} />
      );
      break;
    case "period":
      input = (
        <TimeDropdown
          type={titleCondition}
          data={{ window: fullData?.period, unit: fullData?.periodUnit }}
        />
      );
      break;
    case "flexibility":
      input = (
        <TimeDropdown
          type={titleCondition}
          data={{ window: fullData?.window, unit: fullData?.windowUnit }}
        />
      );
      break;
    case "lookahead":
      input = (
        <TimeDropdown
          type={titleCondition}
          data={{ window: fullData?.lookAhead, unit: fullData?.lookAheadUnit }}
        />
      );
      break;
    case "settype":
      const defaultType = fullData?.equipmentCategory?.id
        ? "equipmentcategory"
        : "";
      return <InspectionTypeSelect data={fullData} defaultType={defaultType} />;
    case "scheduleddate":
    case "targetdate":
    case "raisedon":
    case "deliverydate":
    case "scheduledfor":
      input = (
        <DateTimePickerOldNonRHF
          id={id}
          value={value}
          classValue="sidePanelInput"
          hideTime={true}
        />
      );
      break;
    case "starttime":
    case "endtime":
      if (
        fullData &&
        Hooks.resetKey(titleCondition) &&
        fullData[Hooks.resetKey(titleCondition)]
      ) {
        time = fullData[Hooks.resetKey(titleCondition)]
          .split("T")[1]
          .split(".")[0];
        time = time.split(":")[0] + ":" + time.split(":")[1];
      }

      input = (
        <input
          id={`${titleCondition}Input`}
          className="sidePanelInput"
          type="time"
          key={time}
          defaultValue={time}
        />
      );
      break;
    case "estimatedarrivaltime":
    case "actualarrivaltime":
    case "departuretime":
      if (value) {
        time = value.split("T")[1].split(".")[0];
        time = time.split(":")[0] + ":" + time.split(":")[1];
      }
      input = (
        <input
          id={`${titleCondition}Input`}
          className="sidePanelInput"
          type="time"
          key={time}
          defaultValue={time}
        />
      );
      break;
    case "subjobs":
      return <SubJobs jobId={fullData.id} />;
    case "fittedfromvan":
      input = (
        <input
          id={titleCondition}
          key={`${titleCondition}_${value}`}
          value={fullData?.fittedFromVan?.isVan}
          className="readOnly sidePanelInput"
          type="text"
          readOnly
        />
      );
      break;
    case "quantityfittedfromvan":
      input = (
        <input
          id={titleCondition}
          key={`${titleCondition}_${value}`}
          value={fullData.qtyFittedFromVan}
          className="readOnly sidePanelInput"
          type="text"
          readOnly
        />
      );
      break;
    case "completeflag":
      input = (
        <input
          id="completeflagInput"
          type="checkbox"
          defaultChecked={fullData.completed_flag}
          key={fullData.completed_flag}
        />
      );
      break;
    case "visitnotes":
    case "equipmentnotes":
      const placeholder = titleCondition === "reason" ? "Reason" : "Notes";
      return (
        <TextAreaInput id={id} defaultValue={value} placeholder={placeholder} />
      );
    case "wo":
      const woValue = fullData?.reference === "NULL" ? "" : fullData?.reference;
      input = (
        <input
          id={id}
          type="text"
          className="textInput sidePanelInput"
          defaultValue={woValue}
        />
      );
      break;
    case "notes":
    case "todo":
      if (!value) {
        value = Hooks.getSidePanelData()?.id;
      }
      return <NotesTodo jobId={value} />;
    default:
      if (titleCondition.includes("readonly")) {
        input = (
          <p id={titleCondition.replace("readonly", "") + "Text"}>{value}</p>
        );
      } else if (titleCondition === "address") {
        return <AddressInputOldNonRHF data={value} />;
      }
      break;
  }

  if (id.includes("record")) {
    let dateValue =
      value && new Date(value) ? new Date(value).toDateString() : "Unknown";
    input = (
      <div
        id={id.replace("Input", "NoEdit")}
        className="sidePanelNormaltext smallerText"
      >
        {dateValue}
      </div>
    );
    keyClass += " smallerKey";
  } else if (
    [
      "status",
      "type",
      "status",
      "assign job",
      "equipment type",
      "assign staff",
    ].includes(title.toLowerCase())
  ) {
    if (title === "Engineer" && !value) {
      value = `default${Hooks.conditionalString(title)}`;
    }

    if (
      document.querySelector("#titleContainerText") &&
      Hooks.conditionalString(
        document.querySelector("#titleContainerText").textContent
      ) === "jobs" &&
      titleCondition.includes("engineer") &&
      Hooks.getSidePanelTitle() !== "Add New Job"
    ) {
      input = (
        <Dropdown
          id={id}
          className="sidePanelSelect"
          render={value}
          type="contractengineer"
          firstValue={firstDropdownValue(titleCondition, fullData)}
        />
      );
    } else {
      // console.log("fullData: ", fullData);
      console.log(
        "Hooks.conditionalString(title): ",
        Hooks.conditionalString(title)
      );
      input = (
        <Dropdown
          id={id}
          className="sidePanelSelect"
          render={value}
          type={Hooks.conditionalString(title)}
          firstValue={firstDropdownValue(titleCondition, fullData)}
        />
      );
    }
  } else if (id === "stafftypeInput") {
    try {
      input = (
        <input
          id={id}
          type="text"
          className="textInput"
          defaultValue={value.description}
        />
      );
    } catch (e) {}
  }

  // Todo change this to hook. formatSidePanelKey
  const isAddNew = Hooks.conditionalString(Hooks.getSidePanelTitle()).includes(
    "addnew"
  );

  const isTitleContainerTextJobs = document
    .querySelector("#titleContainerText")
    .textContent.includes("Jobs");
  const isReadOnly = titleCondition.includes("readonly");
  const isProperty = titleCondition === "property";
  const isEngineer = titleCondition.includes("engineer");
  const isClient = titleCondition.includes("client");

  if (
    !isAddNew &&
    isTitleContainerTextJobs &&
    !isReadOnly &&
    (isProperty || isEngineer || isClient)
  ) {
    let flyoutId, flyoutType;
    switch (titleCondition) {
      case "clientjob":
        title = "Client";
        flyoutType = "client";
        flyoutId = fullData?.client?.id;
        break;
      case "engineer":
        flyoutType = "engineer";
        flyoutId = fullData?.engineer?.id;
        break;
      case "property":
        flyoutType = "property";
        flyoutId = fullData?.property?.id;
        break;
      default:
        break;
    }

    key = (
      <div
        className="flyoutViewContainer"
        onClick={() => {
          viewFlyout(flyoutType, flyoutId);
        }}
      >
        <div className={keyClass}>{title}</div>
        <div className="flyoutViewButton">View</div>
      </div>
    );
  } else if (titleCondition && titleCondition.includes("_flag")) {
    key = <div className={keyClass}>{title.replace("_flag", "")}</div>;
  } else if (
    [
      "subjobs",
      "searchforsor",
      "partsmessage",
      "notes",
      "todo",
      "visitnotes",
      "equipmentnotes",
      "scheduletimepicker",
      "questions",
      "settype",
      "message",
      "jobtypestate",
      "jobtransitiondrop",
      "address",
      "searchforpart",
      "propertysearch",
    ].includes(titleCondition)
  ) {
    key = "";
  } else if (
    ["lastserviceedit", "makeedit", "modeledit"].includes(titleCondition)
  ) {
    key = <div className={keyClass}>{title.replace("Edit", "")}</div>;
  } else if (titleCondition === "addequipmenttype") {
    key = <div className={keyClass}>Equipment Type</div>;
  } else if (titleCondition.includes("readonly")) {
    key = <div className={keyClass}>{title.replace("read only", "")}</div>;
  } else if (titleCondition.includes("reference")) {
    key = <div className={keyClass}>WO</div>;
  } else if (titleCondition.includes("propertynumber")) {
    key = <div className={keyClass}>Property</div>;
  } else if (titleCondition === "newsetquestions") {
    key = <div className={keyClass}>Questions</div>;
  } else if (titleCondition === "completeflag") {
    key = <div className={keyClass}>Complete?</div>;
  } else if (titleCondition === "jobcontract") {
    key = <div className={keyClass}>Contract</div>;
  } else if (titleCondition === "jobcontract2") {
    key = <div className={keyClass}>Contract</div>;
  }

  return (
    <div className={"sidePanelRow" + className + (long ? " long" : "")}>
      {key}
      <div className="rowInputContainer">{input}</div>
    </div>
  );
}
