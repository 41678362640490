import API_ENV from "@config";
import Hooks from "@hooksFile";
import Button from "@ui/Button";
import CollapsibleGroup from "@ui/CollapsibleGroup";
import FileInput from "@ui/FileInput";
import Heading from "@ui/Heading";
import Row from "@ui/Row";
import React, { useEffect, useRef, useState } from "react";

function Test() {
  const [photo, setPhoto] = useState();
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log("photo", photo);
  }, [photo]);

  function handleFileChange(event) {
    const file = event.target.files[0];
    setPhoto(file);
  }

  function uploadPhotoTest() {
    // console.log("uploadPhotoTest");

    if (!photo) {
      console.log("No file selected");
      return;
    }

    // console.log("uploadPhotoTest, Photo attachment:", photo);

    Hooks.sendFileAttachment(
      photo,
      `${API_ENV}/inspectionQuestionWithAnswer/importimage/100`,
      "PUT"
    );
  }

  function downloadPhotoTest() {
    // console.log("downloadPhotoTest");

    Hooks.downloadFile(
      `${API_ENV}/inspectionQuestionWithAnswer/100`,
      (data) => {
        console.log(data);
      }
    );
  }

  return (
    <>
      <CollapsibleGroup
        title="Photo Upload & Download Test "
        isOpenStatus={true}
      >
        <Row>
          <Heading as="h2">Photo Upload</Heading>
          <br />
        </Row>

        <Row>
          <FileInput ref={fileInputRef} onChange={handleFileChange} />
          <Button size="large" onClick={uploadPhotoTest}>
            Upload
          </Button>

          <Button size="large" onClick={downloadPhotoTest}>
            Download
          </Button>
        </Row>
      </CollapsibleGroup>
    </>
  );
}

export default Test;
