import "../styles/ui/Input.scss";

import Hooks from "../Hooks";

export default function InputOldNonRHF({
  label,
  showLabel = true,
  textAlign = "center",
  size = "medium",

  formData,
  setFormData,
  value,
  customKeyName,

  onSearch,
  ...props
}) {
  const inputId = `${Hooks.camelCaseString(label)}Input`;

  const keyName = customKeyName || Hooks.camelCaseString(label);

  const handleChange = (e) => {
    const newValue = e.target.value;

    // Update the form data as usual
    setFormData((prevData) => ({
      ...prevData,
      [keyName]: newValue,
    }));

    // Call onSearch if it's provided and the input is not readOnly
    const { readOnly } = props;
    if (onSearch && !readOnly) {
      onSearch(newValue);
    }
  };

  // Null date visual change
  if (value === "0001-01-01T00:00:00" || value === "Mon Jan 01 0001") {
    value = "-";
  }

  return (
    <>
      {showLabel && <label htmlFor={inputId}>{label}</label>}

      <input
        {...props}
        id={inputId}
        className={`input ${size} ${textAlign}`}
        value={
          value !== undefined
            ? value
            : formData[keyName] !== undefined
            ? formData[keyName]
            : ""
        }
        onChange={handleChange}
      />
    </>
  );
}
