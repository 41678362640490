import React from "react";

import "./partsearch.scss";
import { HiOutlinePlusCircle } from "react-icons/hi2";

export default function PartListItem({ itemData, select, type }) {
  const partCategories = [
    {
      ref: React.useRef(null),
      data: itemData?.partsCategory,
      label: "Part Category :",
    },
    {
      ref: React.useRef(null),
      data: itemData?.partsSubCategory1,
      label: "Part Sub Category 1:",
    },
    {
      ref: React.useRef(null),
      data: itemData?.partsSubCategory2,
      label: "Part Sub Category 2:",
    },
    {
      ref: React.useRef(null),
      data: itemData?.partsSubCategory3,
      label: "Part Sub Category 3:",
    },
    {
      ref: React.useRef(null),
      data: itemData?.partsSubCategory4,
      label: "Part Sub Category 4:",
    },
  ];

  function subClicked(target, event) {
    event?.stopPropagation();
    if (target) {
      target.style.display = "flex";
    }
  }

  return (
    <div className="partListItem">
      <div
        className="itemHeader"
        onClick={() => subClicked(partCategories[0].ref.current)}
      >
        <div className="headerTitle">
          <div className="partExpand">
            <HiOutlinePlusCircle />
          </div>
          {type
            ? itemData?.name
            : `${itemData?.partno} - ${itemData?.description}`}
        </div>
        <div
          className="partSelect"
          onClick={(event) => {
            event.stopPropagation();
            select(itemData);
          }}
        >
          Select
        </div>
      </div>
      <div className="partsHierarchy">
        {partCategories.map((cat, index) => (
          <div
            key={index}
            ref={cat.ref}
            className="partsHierarchyItem"
            onClick={(event) =>
              subClicked(partCategories[index + 1]?.ref.current, event)
            }
          >
            <div className="itemKey">{cat.label}</div>
            <div className="itemValue">{cat.data?.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
