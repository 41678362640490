import React, { useState, useEffect } from "react";
import NewDropdown from "../../../generic/NewDropdown";

export default function InspectionTypeSelect({ data, defaultType }) {
  const [setType, updateSetType] = useState();
  const [equipmentCategory, updateEquipmentCategory] = useState();

  useEffect(() => {
    function getSetType() {
      try {
        return {
          value: data.inspectionTypeRefId,
          label: data.inspectionType.name,
        };
      } catch (e) {
        return { value: 0, label: "No Value" };
      }
    }

    function setTypeChanged(setType) {
      if (setType === "job" || setType === "visit") {
        document.querySelector("#typeLabel").textContent = "";
        updateEquipmentCategory(getEquipmentCategory());
      } else {
        document.querySelector("#typeLabel").textContent = "Equipment Category";
        if (data && data.equipmentCategory && data.equipmentCategory.id) {
          updateEquipmentCategory(
            getEquipmentCategory(
              {
                value: data.equipmentCategory.id,
                label: data.equipmentCategory.name,
              },
              "equipmentcategory"
            )
          );
        } else {
          updateEquipmentCategory(
            getEquipmentCategory({}, "equipmentcategory")
          );
        }
      }
    }

    function loadType() {
      if (
        document.querySelector("#sidePanelTitle") &&
        document.querySelector("#sidePanelTitle").textContent &&
        document.querySelector("#sidePanelTitle").textContent.includes("Add")
      ) {
        return <NewDropdown type="settype" onChange={setTypeChanged} />;
      } else {
        return (
          <NewDropdown
            key={Math.random()}
            type="settype"
            onChange={setTypeChanged}
            topValue={getSetType()}
          />
        );
      }
    }

    function getEquipmentCategory(firstValue, type) {
      if (type === "equipmentcategory" && firstValue && firstValue.value) {
        return <NewDropdown type="equipmentcategory" topValue={firstValue} />;
      } else if (type === "equipmentcategory") {
        return <NewDropdown type="equipmentcategory" />;
      } else {
        return <></>;
      }
    }

    updateSetType(loadType());

    if (
      defaultType &&
      defaultType === "equipmentcategory" &&
      data &&
      data.equipmentCategory &&
      data.equipmentCategory.id
    ) {
      document.querySelector("#typeLabel").textContent = "Equipment Category";
      updateEquipmentCategory(
        getEquipmentCategory(
          {
            value: data.equipmentCategory.id,
            label: data.equipmentCategory.name,
          },
          "equipmentcategory"
        )
      );
    } else {
      updateEquipmentCategory(getEquipmentCategory());
    }
  }, [data, defaultType]);

  return (
    <div id="inspectionTypeSelect">
      <div className="inspectionTypeRow">
        <div className="sidePanelKey">Set Type</div>
        {setType}
      </div>
      <div className="inspectionTypeRow">
        <div className="sidePanelKey" id="typeLabel">
          {defaultType === "equipmentcategory" ? "Equipment Category" : ""}
        </div>
        {equipmentCategory}
      </div>
    </div>
  );
}
