import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import InputOldNonRHF from "@ui/InputOldNonRHF";
import DropdownListOldNonRHF from "@ui/DropdownListOldNonRHF";

const initialFormData = {
  newCategoryName: "",
  partsCategoryId: "",
  partsCategoryName: "",
  partsSubCategory1Id: "",
  partsSubCategory1Name: "",
  partsSubCategory2Id: "",
  partsSubCategory2Name: "",
  partsSubCategory3Id: "",
  partsSubCategory3Name: "",
  partsSubCategory4Id: "",
  partsSubCategory4Name: "",
  partsCategoryIdParent: "",
  partsSubCategory1IdParent: "",
  partsSubCategory2IdParent: "",
  partsSubCategory3IdParent: "",
  partsSubCategory4IdParent: "",
};

const initialDropdownValues = {
  partsCategoryDD: [],
  partsSubCategory1DD: [],
  partsSubCategory2DD: [],
  partsSubCategory3DD: [],
  partsSubCategory4DD: [],
};

export default function UpdatePartsSubCategory({
  state,
  onClose,
  cleanURL,
  refreshTable,
  category,
}) {
  const [formData, setFormData] = useState(initialFormData);
  const [dropdownData, setDropdownData] = useState(initialDropdownValues);

  const confirmationScreen = false;
  const sentConfirmation = false;

  const [addScreen, setAddScreen] = useState(false);

  const { data } = state;

  useEffect(() => {
    console.log("formData", formData);
    // console.log("data", data);
  }, [formData, data]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/partsCategory`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        partsCategoryDD: data,
      }));
    });
    Hooks.getData(
      `${API_ENV}/partsSubCategory1`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory1DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory2`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory2DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory3`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory3DD: data,
        }));
      }
    );
    Hooks.getData(
      `${API_ENV}/partsSubCategory4`,
      (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          partsSubCategory4DD: data,
        }));
      }
    );
  }, []);

  // Fill form via state
  useEffect(() => {
    if (data) {
      setFormData((prevData) => ({
        ...prevData,
        partsCategoryId:
          data.partsCategoryId === null ? "" : data.partsCategoryId,
        partsCategoryName:
          data.partsCategoryName === null ? "" : data.partsCategoryName,
        partsSubCategory1Id:
          data.partsSubCategory1Id === null ? "" : data.partsSubCategory1Id,
        partsSubCategory1Name:
          data.partsSubCategory1Name === null ? "" : data.partsSubCategory1Name,
        partsSubCategory2Id:
          data.partsSubCategory2Id === null ? "" : data.partsSubCategory2Id,
        partsSubCategory2Name:
          data.partsSubCategory2Name === null ? "" : data.partsSubCategory2Name,
        partsSubCategory3Id:
          data.partsSubCategory3Id === null ? "" : data.partsSubCategory3Id,
        partsSubCategory3Name:
          data.partsSubCategory3Name === null ? "" : data.partsSubCategory3Name,
        partsSubCategory4Id:
          data.partsSubCategory4Id === null ? "" : data.partsSubCategory4Id,
        partsSubCategory4Name:
          data.partsSubCategory4Name === null ? "" : data.partsSubCategory4Name,
      }));
    }
  }, [data]);

  function handleDeleteCategory(url, id) {
    let dataToSend;

    function thenFunction() {
      Hooks.getData(`${API_ENV}/${url}`, (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          [`${url}DD`]: data,
        }));
      });
    }

    Hooks.sendData(
      dataToSend,
      `${API_ENV}/${url}/${id}`,
      "DELETE",
      thenFunction,
      (data) => {
        // console.log(data);
      }
    );
  }

  function handleAddCategory(url) {
    let dataToSend;

    if (url === "partsCategory") {
      dataToSend = {
        name: formData.newCategoryName,
      };
    } else if (url === "partsSubCategory1") {
      dataToSend = {
        name: formData.newCategoryName,
        partsCategoryId: formData.partsCategoryIdParent,
      };
    } else if (url === "partsSubCategory2") {
      dataToSend = {
        name: formData.newCategoryName,
        partsSubCategory1Id: formData.partsSubCategory1IdParent,
      };
    } else if (url === "partsSubCategory3") {
      dataToSend = {
        name: formData.newCategoryName,
        partsSubCategory2Id: formData.partsSubCategory2IdParent,
      };
    } else if (url === "partsSubCategory4") {
      dataToSend = {
        name: formData.newCategoryName,
        partsSubCategory3Id: formData.partsSubCategory3IdParent,
      };
    }

    function thenFunction() {
      Hooks.getData(`${API_ENV}/${url}`, (data) => {
        // console.log(data);
        setDropdownData((prevData) => ({
          ...prevData,
          [`${url}DD`]: data,
        }));
      });
      setFormData((prevData) => ({
        ...prevData,
        newCategoryName: "",
      }));
    }

    Hooks.sendData(
      dataToSend,
      `${API_ENV}/${url}`,
      "POST",
      thenFunction,
      (data) => {
        // console.log(data);
      }
    );
  }

  return (
    <div id="afContainer">
      <div id="afHeader">
        <div id="afTitle">
          <h3> Maintain Sub/Categories </h3>
        </div>
        <div id="afHeaderButtons">
          <div id="afExit" className="afButton" onClick={onClose}>
            Exit
          </div>
        </div>
      </div>

      <div id="afContentForm">
        {!confirmationScreen && !sentConfirmation && (
          <>
            <div className="formContainerModal">
              {/* Parts Category */}
              {category === "PartsCategory" ? (
                <>
                  <div className="formRowModal1Col">
                    <DropdownListOldNonRHF
                      label={"Parts Category Categories"}
                      dropdownData={dropdownData.partsCategoryDD}
                      formData={formData}
                      setFormData={setFormData}
                      customKeyName={"partsCategoryId"}
                    />
                    <button
                      className="formRowButton"
                      onClick={() =>
                        handleDeleteCategory(
                          "partsCategory",
                          formData.partsCategoryId
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>

                  {addScreen === "partsCategory" ? (
                    <>
                      <div className="formRowModal1Col">
                        <h3>New Parts Category</h3>
                      </div>

                      <div className="formRowModal1Col">
                        <InputOldNonRHF
                          label={"New Parts Category Name"}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"newCategoryName"}
                        />
                        <button
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsCategory")}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  ) : null}

                  {!addScreen && (
                    <div className="formRowModal1Col">
                      <button
                        className="formRowButtonAdd"
                        onClick={() => setAddScreen("partsCategory")}
                      >
                        Add new Parts Category Entry
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {/* Parts SubCategory1 */}
              {category === "SubCategory1" ? (
                <>
                  <div className="formRowModal1Col">
                    <DropdownListOldNonRHF
                      label={"Parts SubCategory 1 Categories"}
                      dropdownData={dropdownData.partsSubCategory1DD}
                      formData={formData}
                      setFormData={setFormData}
                      customKeyName={"partsSubCategory1Id"}
                    />
                    <button
                      className="formRowButton"
                      onClick={() =>
                        handleDeleteCategory(
                          "partsSubCategory1",
                          formData.partsSubCategory1Id
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>

                  {addScreen === "SubCategory1" ? (
                    <>
                      <div className="formRowModal1Col">
                        <h3>New Parts SubCategory 1</h3>
                      </div>
                      <div className="formRowModal1Col">
                        <DropdownListOldNonRHF
                          label={
                            "Select Parent Group For New Parts SubCategory 1"
                          }
                          dropdownData={dropdownData.partsCategoryDD}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"partsCategoryIdParent"}
                        />
                        <button
                          style={{ visibility: "hidden" }}
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory1")}
                        >
                          Add
                        </button>
                      </div>

                      <div className="formRowModal1Col">
                        <InputOldNonRHF
                          label={"New Parts SubCategory 1 Name"}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"newCategoryName"}
                        />
                        <button
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory1")}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  ) : null}

                  {!addScreen && (
                    <div className="formRowModal1Col">
                      <button
                        className="formRowButtonAdd"
                        onClick={() => setAddScreen("SubCategory1")}
                      >
                        Add new SubCategory 1 Entry
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {/* Parts SubCategory2 */}
              {category === "SubCategory2" ? (
                <>
                  <div className="formRowModal1Col">
                    <DropdownListOldNonRHF
                      label={"Parts SubCategory 2"}
                      dropdownData={dropdownData.partsSubCategory2DD}
                      formData={formData}
                      setFormData={setFormData}
                      customKeyName={"partsSubCategory2Id"}
                    />
                    <button
                      className="formRowButton"
                      onClick={() =>
                        handleDeleteCategory(
                          "partsSubCategory2",
                          formData.partsSubCategory2Id
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>

                  {addScreen === "SubCategory2" ? (
                    <>
                      <div className="formRowModal1Col">
                        <h3>New Parts SubCategory 1</h3>
                      </div>
                      <div className="formRowModal1Col">
                        <DropdownListOldNonRHF
                          label={
                            "Select Parent Group For New Parts SubCategory 2"
                          }
                          dropdownData={dropdownData.partsSubCategory1DD}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"partsSubCategory1IdParent"}
                        />
                        <button
                          style={{ visibility: "hidden" }}
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory2")}
                        >
                          Add
                        </button>
                      </div>

                      <div className="formRowModal1Col">
                        <InputOldNonRHF
                          label={"New Parts SubCategory 2 Name"}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"newCategoryName"}
                        />
                        <button
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory2")}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  ) : null}

                  {!addScreen && (
                    <div className="formRowModal1Col">
                      <button
                        className="formRowButtonAdd"
                        onClick={() => setAddScreen("SubCategory2")}
                      >
                        Add new SubCategory 2 Entry
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {/* Parts SubCategory3 */}
              {category === "SubCategory3" ? (
                <>
                  <div className="formRowModal1Col">
                    <DropdownListOldNonRHF
                      label={"Parts SubCategory 3"}
                      dropdownData={dropdownData.partsSubCategory3DD}
                      formData={formData}
                      setFormData={setFormData}
                      customKeyName={"partsSubCategory3Id"}
                    />
                    <button
                      className="formRowButton"
                      onClick={() =>
                        handleDeleteCategory(
                          "partsSubCategory3",
                          formData.partsSubCategory3Id
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>

                  {addScreen === "SubCategory3" ? (
                    <>
                      <div className="formRowModal1Col">
                        <h3>New Parts SubCategory 3</h3>
                      </div>
                      <div className="formRowModal1Col">
                        <DropdownListOldNonRHF
                          label={
                            "Select Parent Group For New Parts SubCategory 3"
                          }
                          dropdownData={dropdownData.partsSubCategory2DD}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"partsSubCategory2IdParent"}
                        />
                        <button
                          style={{ visibility: "hidden" }}
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory3")}
                        >
                          Add
                        </button>
                      </div>

                      <div className="formRowModal1Col">
                        <InputOldNonRHF
                          label={"New Parts SubCategory 3 Name"}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"newCategoryName"}
                        />
                        <button
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory3")}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  ) : null}

                  {!addScreen && (
                    <div className="formRowModal1Col">
                      <button
                        className="formRowButtonAdd"
                        onClick={() => setAddScreen("SubCategory3")}
                      >
                        Add new SubCategory 3 Entry
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              {/* Parts SubCategory4 */}
              {category === "SubCategory4" ? (
                <>
                  <div className="formRowModal1Col">
                    <DropdownListOldNonRHF
                      label={"Parts SubCategory 4"}
                      dropdownData={dropdownData.partsSubCategory4DD}
                      formData={formData}
                      setFormData={setFormData}
                      customKeyName={"partsSubCategory4Id"}
                    />
                    <button
                      className="formRowButton"
                      onClick={() =>
                        handleDeleteCategory(
                          "partsSubCategory4",
                          formData.partsSubCategory4Id
                        )
                      }
                    >
                      Delete
                    </button>
                  </div>

                  {addScreen === "SubCategory4" ? (
                    <>
                      <div className="formRowModal1Col">
                        <h3>New Parts SubCategory 1</h3>
                      </div>
                      <div className="formRowModal1Col">
                        <DropdownListOldNonRHF
                          label={
                            "Select Parent Group For New Parts SubCategory 3"
                          }
                          dropdownData={dropdownData.partsSubCategory3DD}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"partsSubCategory3IdParent"}
                        />
                        <button
                          style={{ visibility: "hidden" }}
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory4")}
                        >
                          Add
                        </button>
                      </div>
                      <div className="formRowModal1Col">
                        <InputOldNonRHF
                          label={"New Parts SubCategory 4 Name"}
                          formData={formData}
                          setFormData={setFormData}
                          customKeyName={"newCategoryName"}
                        />
                        <button
                          className="formRowButton"
                          onClick={() => handleAddCategory("partsSubCategory4")}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  ) : null}

                  {!addScreen && (
                    <div className="formRowModal1Col">
                      <button
                        className="formRowButtonAdd"
                        onClick={() => setAddScreen("SubCategory4")}
                      >
                        Add new SubCategory 4 Entry
                      </button>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
