import "../styles/ui/Sidebar.scss";

import { useEffect, useState } from "react";

import Navigation from "./Navigation";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi2";
import Logo from "./Logo";

export default function Sidebar({ isOpen, setIsOpen }) {
  const [showNavigation, setShowNavigation] = useState(true);
  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    let timeoutId;
    if (isOpen) {
      timeoutId = setTimeout(() => {
        setShowNavigation(true);
      }, 250); // Adjust delay timing
    } else {
      setShowNavigation(false);
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <aside className={`sidebar ${isOpen ? "open" : "closed"}`}>
      {showNavigation && (
        <>
          <Logo />
          <Navigation />
        </>
      )}

      <div
        className={`sidebarToggle ${isOpen ? "open" : "closed"}`}
        onClick={toggleSidebar}
      >
        {isOpen ? (
          <HiChevronDoubleLeft size={20} />
        ) : (
          <HiChevronDoubleRight size={20} />
        )}
      </div>
    </aside>
  );
}
