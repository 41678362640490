import React, { useRef } from "react";

import "./purchaserequests.scss";
import SearchField from "./SearchField";
import PartSearch from "../SearchForPart/PartSearch";
import SidePanelRow from "../../sidePanel/SidePanelComponents/SidePanelRow";

export default function AddPurchaseRequest({
  search,
  partSearch,
  selectedPart,
}) {
  const searchField = useRef(null);
  return (
    <>
      <PartSearch search={partSearch} />
      <SidePanelRow
        title="Part Number"
        readOnly
        dataId={selectedPart?.partId}
        value={selectedPart?.partNo}
      />
      <SidePanelRow title="Quantity" />
      <SidePanelRow title="Target Date" />
      <div className="sidePanelRow">
        <div className="sidePanelKey">Target Job</div>
        <div className="rowInputContainer">
          <SearchField
            id="jobSearch"
            ref={searchField}
            placeholder="target job"
            searchClicked={() => {
              search(searchField.current, "targetjob");
            }}
          />
        </div>
      </div>
      <SidePanelRow title="Preferred Warehouse " />
      <SidePanelRow title="Preferred Supplier" />
      <SidePanelRow title="Reason" />
    </>
  );
}
