import API_ENV from "@config";
import React, { useEffect, useState } from "react";
import Hooks from "../../../Hooks";
import AssignedItems from "../Jobs/AssignedItems";
import SubTab from "./../../sidePanel/SidePanelComponents/SubTabs";

export default function JobTypeInspections({ data }) {
  const [tabs, updateTabs] = useState();
  const [inspections, updateInspections] = useState();

  function tabClicked(tab) {
    Hooks.getData(
      `${API_ENV}/inspectionSet/jobtype/${
        Hooks.getSidePanelData().id
      }/inspectiontype/${tab.getAttribute("value")}`,
      (data) => {
        if (data && data.length) {
          updateInspections(data);
        } else {
          updateInspections({});
        }
      }
    );
  }

  useEffect(() => {
    Hooks.getData(`${API_ENV}/inspectionType`, (data) => {
      if (data && data.length) {
        let array = [];
        data.forEach((inspectionType) => {
          array.push({ id: inspectionType.id, text: inspectionType.name });
        });
        updateTabs(array);
      }
    });

    Hooks.getData(
      `${API_ENV}/inspectionSet/jobtype/${
        Hooks.getSidePanelData().id
      }/inspectiontype/1`,
      (data) => {
        if (data && data.length) {
          updateInspections(data);
        }
      }
    );
  }, [data]);

  return (
    <>
      <SubTab data={tabs} clicked={tabClicked} />
      <AssignedItems data={inspections} type="viewinspectionsets" />
    </>
  );
}
