import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../../../../ui/Form";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import Input from "../../../../ui/Input";
import DropdownList from "../../../../ui/DropdownList";
import Hooks from "../../../../Hooks";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    apiGroupId: yup.string().required("API Group is required"),
    menuPath: yup.string().required("This field is required"),
  })
  .required();

export default function ViewApi({
  state,
  setFooterState,
  cleanURL,
  refreshTable,
  apiUrl,
  archive,
}) {
  const [dropdownData, setDropdownData] = useState({
    dropdownData1: [],
  });

  const {
    control,
    handleSubmit,
    // setValue,
    reset,
    // register,
    // watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      apiGroupId: "",
      menuPath: "",
      remark: "",
      reportFilterFlag: false,
    },
  });

  // const { openModal } = useModal();
  const { data } = state;

  // useEffect(() => {
  //   console.log("data:", data);
  //   console.log("dropdownData:", dropdownData);
  // }, [data, dropdownData]);

  // useEffect(() => {
  //   const subscription = watch((values, { name, type }) => console.log(values));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // UseEffect Hooks
  // Dropdown list 1 Data
  useEffect(() => {
    Hooks.getData(`${API_ENV}/ApiGroup`, (data) => {
      // console.log(data);
      setDropdownData((prevData) => ({
        ...prevData,
        dropdownData1: data,
      }));
    });
  }, []);

  // Fill form via state
  useEffect(() => {
    if (data) {
      reset({
        // ID here is needed for PUT call in handleSubmitForm
        id: data.id,
        name: data.name || "",
        apiGroupId: data.apiGroup.id || "",
        menuPath: data.menuPath || "",
        remark: data.remark || "",
        reportFilterFlag: data.reportFilterFlag,
      });
    }
  }, [data, reset]);

  // HANDLE Functions
  const handleSubmitForm = useCallback(
    async (data) => {
      function thenFunction(response) {
        refreshTable();
        cleanURL();
      }

      console.log("apiUrl", apiUrl);
      Hooks.sendData(
        data,
        `${API_ENV}/${apiUrl}/${data.id}`,
        "PUT",
        thenFunction
      );
    },
    [refreshTable, cleanURL, apiUrl]
  );

  const handleArchive = useCallback(() => {
    archive();
  }, [archive]);

  // const handleOpenModal = useCallback(async () => {
  //   openModal("Example");
  // }, [openModal]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Submit Form", click: handleSubmit(handleSubmitForm) },
      { text: "Archive", click: handleArchive },
      // { text: "Open Modal", click: handleSubmit(handleOpenModal) },
      { text: "Close", click: closeFunction },
    ]);
  }, [
    setFooterState,
    closeFunction,
    handleSubmit,
    handleSubmitForm,
    handleArchive,
    // handleOpenModal,
  ]);

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        <SidePanelFormRow>
          <Input label={"Name"} name="name" control={control} errors={errors} />
        </SidePanelFormRow>

        <SidePanelFormRow>
          <DropdownList
            label={"API Group"}
            name="apiGroupId"
            control={control}
            errors={errors}
            dropdownData={dropdownData.dropdownData1}
            optionName="groupName"
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Menu Path"}
            name="menuPath"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Remark"}
            name="remark"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <Input
            label={"Report Filter?"}
            name="reportFilterFlag"
            type="checkbox"
            control={control}
            errors={errors}
          />
        </SidePanelFormRow>
      </div>

      {/* <Modal name="Example" title={"Title"}>
        
      </Modal> */}
    </Form>
  );
}
