import API_ENV from "@config";
import React, { useState, useEffect, useCallback } from "react";
import Hooks from "../../../../Hooks";
import InputOldNonRHF from "../../../../ui/InputOldNonRHF";
import ContractPartListStandalone from "./ContractPartListStandalone";
import SidePanelFormRow from "../../../../ui/SidePanelFormRow";
import Form from "../../../../ui/Form";
import Swal from "sweetalert2";
import "../../../../styles/features/Forms.scss";

const initialFormValues = {
  description: "",
  partId: "",
  partNo: "",
  quantity: "",
  searchQuery: "",
  mainCategory: "",
  subcategory1: "",
  subcategory2: "",
  subcategory3: "",
  subcategory4: "",
};

export default function ContractPartsForm({
  setFooterState,
  cleanURL,
  refreshTable,
}) {
  const [formData, setFormData] = useState(initialFormValues);
  const [partsViaSearch, setPartsViaSearch] = useState([]);
  const [contractParts, setContractParts] = useState([]);

  // useEffect(() => {
  //   // console.log("formData:", formData);
  //   // console.log("partsViaSearch:", partsViaSearch);
  //   console.log("contractParts:", contractParts);
  // }, [contractParts]);

  useEffect(() => {
    Hooks.getData(
      `${API_ENV}/ContractAndParts/?contractIds=${
        Hooks.getSidePanelData()?.id
      }&showarchive=false`,
      (data) => {
        // console.log("API Data:", data);
        setContractParts(data);
      }
    );
  }, []);

  // HANDLE Functions
  const handleAddContractPart = useCallback(async () => {
    let dataToSend = {
      contractId: Hooks.getSidePanelData()?.id,
      partsId: formData.partId,
      qty: formData.quantity,
    };
    console.log("dataToSend:", dataToSend);

    Hooks.sendData(
      dataToSend,
      `${API_ENV}/ContractAndParts`,
      "POST",
      (response) => {
        // console.log(response);
      }
    );
  }, [formData.partId, formData.quantity]);

  const closeFunction = useCallback(() => {
    cleanURL();
  }, [cleanURL]);

  // Were setting the Footer Buttons here instead of SidePanel.js....
  useEffect(() => {
    setFooterState([
      { text: "Add Part", click: handleAddContractPart },
      { text: "Close", click: closeFunction },
    ]);
  }, [setFooterState, closeFunction, handleAddContractPart]);

  const handleSearch = useCallback(async (searchQuery) => {
    if (searchQuery.length < 2) return; // Optional: Prevent API call for too short queries

    try {
      Hooks.getData(
        `${API_ENV}/parts/search?searchstring=${searchQuery}`,
        (data) => {
          // console.log("Search Data:", data);
          const partsList = data.parts.map((part) => {
            return {
              id: part.id,
              partno: part.partno,
              description: part.description,
            };
          });
          setPartsViaSearch(partsList);
        }
      );
    } catch (error) {
      console.error("Search API error:", error);
    }
  }, []);

  function deleteClick(part) {
    Swal.fire({
      title: "Unassign item?",
      text: "Are you sure you wish to remove this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        function thenFunction(response) {
          if (response.ok) {
            document.querySelector(".currentTab").click();
          }
        }

        // console.log("part:", part);

        Hooks.sendData(
          {},
          `${API_ENV}/ContractAndParts/${part.partsId}/${
            Hooks.getSidePanelData()?.id
          }`,
          "DELETE",
          thenFunction
        );
      }
    });
  }

  return (
    <Form>
      <div className="sidePanelFormRowsContainer">
        {contractParts.length > 0 &&
          contractParts.map((part, index) => (
            <div id="assignedSets" key={`${"part"}-${index}`}>
              <div className="assignedRow" key={`${"part"}-${index}`}>
                <div className="assignedName">{`Part: ${part.parts.partno}`}</div>
                <div
                  className="assignedDelete"
                  onClick={(e) => {
                    deleteClick(part);
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}

        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Part Search"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            onSearch={handleSearch}
            customKeyName={"searchQuery"}
          />
        </SidePanelFormRow>

        {/* Existing search bar component */}
        {/* <PartSearch /> */}

        {/* Standalone partList */}
        <ContractPartListStandalone
          parentSetFormData={setFormData}
          parentFormData={formData}
          partsViaSearch={partsViaSearch}
        />

        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Part No"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Description"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Quantity"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Main Category"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Subcategory 1"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Subcategory 2"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Subcategory 3"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
        <SidePanelFormRow>
          <InputOldNonRHF
            label={"Subcategory 4"}
            type={"text"}
            size="extraSmall"
            formData={formData}
            setFormData={setFormData}
            readOnly
          />
        </SidePanelFormRow>
      </div>
    </Form>
  );
}
