import API_ENV from "@config";
import React, { useState, useEffect } from "react";
import NewDropdown from "../../generic/NewDropdown";

export default function JobTransitionDropdown({
  jobTypeValue,
  jobStateValue,
  nextJobStateValue,
}) {
  const [jobStateUrl, updateStateUrl] = useState("");
  const [nextJobStateUrl, updateNextStateUrl] = useState("");

  useEffect(() => {
    const jobType = jobTypeValue.value;
    const currentState = jobStateValue.value;
    const nextState = nextJobStateValue.value;

    updateStateUrl(
      `${API_ENV}/jobState/jobType/${jobType}/${currentState}`
    );
    updateNextStateUrl(
      `${API_ENV}/jobState/jobType/${jobType}/${nextState}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTypeValue]);

  // commenting out as it's not used & to remove unused-vars build warning
  // function typeChanged() {
  //     setTimeout(() => {
  //         const jobType = document.querySelector('#jobtypeInputContainer').getAttribute('data');
  //         let currentStateId = 0;

  //         try {
  //             currentStateId = Hooks.getSidePanelData().currJobStateId;
  //         } catch (e) { }

  //         if (jobType) {
  //             updateStateUrl(`${API_ENV}/jobState/jobType/${jobType}/${currentStateId}`);
  //         };
  //     }, 1000);
  // };

  function currentStateChanged() {
    setTimeout(() => {
      const jobType = document
        .querySelector("#jobtypeDropdown")
        .getAttribute("data");
      const currentStateId = document
        .querySelector("#jobstateDropdown")
        .getAttribute("data");

      updateNextStateUrl(
        `${API_ENV}/jobState/jobType/${jobType}/${currentStateId}`
      );
    }, 250);
  }

  return (
    <>
      <div className="sidePanelRow">
        <div className="sidePanelKey">Job Type</div>
        <div className="rowInputContainer">
          <NewDropdown
            type="jobtype"
            key={jobTypeValue.label}
            topValue={{ label: jobTypeValue.label, value: jobTypeValue.value }}
            onChange={currentStateChanged}
          />
        </div>
      </div>
      <div className="sidePanelRow">
        <div className="sidePanelKey">Current Job State</div>
        <div className="rowInputContainer">
          <NewDropdown
            type="jobstate"
            key={jobStateValue.label}
            topValue={{
              label: jobStateValue.label,
              value: jobStateValue.value,
            }}
            customUrl={jobStateUrl}
          />
        </div>
      </div>
      <div className="sidePanelRow">
        <div className="sidePanelKey">Next Job State</div>
        <div className="rowInputContainer">
          <NewDropdown
            type="jobstate"
            key={nextJobStateValue.label}
            topValue={{
              label: nextJobStateValue.label,
              value: nextJobStateValue.value,
            }}
            customUrl={nextJobStateUrl}
          />
        </div>
      </div>
    </>
  );
}
