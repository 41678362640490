import API_ENV from "@config";
import {
  createContext,
  useCallback,
  useContext,
  // useEffect,
  useState,
} from "react";
import Hooks from "../Hooks";

const TableDataContext = createContext();

const initialContextFormData = {
  apiGroup: "",
  reportId: "",
  reportTypeId: "",
  outputFormat: "",
  orderBy: "",
  searchFilter: "",
  runReportFilterId: "",
};

function TableDataProvider({ children }) {
  const [tableData, setTableData] = useState([]);
  const [tableContextFormData, setTableContextFormData] = useState(
    initialContextFormData
  );

  const [tableHeadings, setTableHeadings] = useState([]);
  const [tableKeysData, setTableKeysData] = useState([]);
  const [headingToKeyMapping, setHeadingToKeyMapping] = useState([]);
  const [tableContextError, setTableContextError] = useState("");

  // useEffect(() => {
  //   // console.log("tableData in Context:", tableData);
  //   // console.log("tableKeysData in Context:", tableKeysData);
  //   // console.log("tableHeadings in Context:", tableHeadings);
  //   console.log("tableContextFormData in Context:", tableContextFormData);
  //   // console.log("headingToKeyMapping in Context:", headingToKeyMapping);
  //   // console.log("tableContextError:", tableContextError);
  // }, [tableContextFormData]);

  function fetchTable() {
    try {
      setTableData([]);
      setTableContextError("");

      if (
        tableContextFormData.runReportFilterId &&
        tableContextFormData.reportId
      ) {
        Hooks.getData(
          // `${API_ENV}/runReportList/runonline/runnow/${tableContextFormData.reportId}?Page=1&Size=100`,
          `${API_ENV}/runReportList/runonline/runnow/${tableContextFormData.reportId}?Page=1&Size=100&runReportFilterId=${tableContextFormData.runReportFilterId}`,
          (data) => {
            // console.log("fetchTable:", data);

            if (
              Array.isArray(data) &&
              data.length > 0 &&
              typeof data !== "string"
            ) {
              // console.log("yes");
              setTableData(data);
            } else {
              if (Array.isArray(data)) {
                // console.log("no");
                setTableContextError(data);
              } else if (data.message || typeof data === "string") {
                setTableContextError(
                  `fetchTable Error: ${data.message || data}`
                );
              } else {
                setTableContextError(
                  "Something went wrong with fetchTable() in TableDataContext"
                );
              }
            }
          }
        );
      } else {
        setTableContextError("Please select a report & filter!");
      }
      console.log("tableContextError:", tableContextError);
    } catch (e) {
      console.log(e);
    }
  }

  // Generate Table Headings
  const generateTableHeadings = useCallback(() => {
    // console.log(tableContextformData);
    // setTableContextError("");
    try {
      Hooks.getData(
        `${API_ENV}/runReportColumnField/GetRunonlineColumn/${tableContextFormData.reportId}`,
        (data) => {
          // console.log("generateTableHeadings:", data);

          if (data.length > 0) {
            let headingNames = data.map((item) => item.keyName);
            let keyNames = data.map((item) => item.mappedField);

            // Sort the headings based on displayOrder
            headingNames.sort((a, b) => {
              const orderA = parseInt(
                data.find((item) => item.keyName === a)?.displayOrder,
                10
              );
              const orderB = parseInt(
                data.find((item) => item.keyName === b)?.displayOrder,
                10
              );
              // Compare and sort by displayOrder
              return orderA - orderB;
            });
            setTableHeadings(headingNames);

            // Sort the keyNames based on displayOrder
            keyNames.sort((a, b) => {
              const orderA = parseInt(
                data.find((item) => item.mappedField === a)?.displayOrder,
                10
              );
              const orderB = parseInt(
                data.find((item) => item.mappedField === b)?.displayOrder,
                10
              );
              return orderA - orderB;
            });

            setTableKeysData(keyNames);

            // Fix for table heading sorting for dyanmic table
            const newHeadingToKeyMapping = {};

            data.forEach((item) => {
              // Use item.mappedField as the key and item.keyName as the value
              // newHeadingToKeyMapping[item.mappedField] = item.keyName;
              const modifiedMappedField = Hooks.conditionalString(
                item.mappedField
              );
              const modifiedKeyName = Hooks.conditionalString(item.keyName);
              newHeadingToKeyMapping[modifiedMappedField] = modifiedKeyName;
            });

            // Set the headingToKeyMapping state with the new mapping
            setHeadingToKeyMapping(newHeadingToKeyMapping);
          } else {
            setTableHeadings([]);
            setTableKeysData([]);
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  }, [tableContextFormData, setTableHeadings, setTableKeysData]);

  return (
    <TableDataContext.Provider
      value={{
        fetchTable,
        generateTableHeadings,
        tableData,
        setTableData,
        tableHeadings,
        setTableHeadings,
        tableKeysData,
        tableContextFormData,
        setTableContextFormData,
        headingToKeyMapping,
        tableContextError,
        setTableContextError,
      }}
    >
      {children}
    </TableDataContext.Provider>
  );
}

function useTableData() {
  const context = useContext(TableDataContext);
  if (context === undefined)
    throw new Error("TableDataContext was used outside of TableDataProvider");
  return context;
}

export { TableDataProvider, useTableData };
