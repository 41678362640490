import API_ENV from "@config";
import "./inspectionQuestions.scss";
import Question from "./Question";
import QuestionPopup from "./QuestionPopup";
import React, { useEffect, useState } from "react";
import Hooks from "../../../../Hooks";

export default function InspectionQuestions({ questions }) {
  const [content, updateContent] = useState([]);
  const [popupData, updatePopup] = useState({});

  // useEffect(() => {
  //   console.log("content", content);
  // }, [content]);

  useEffect(() => {
    if (questions && questions.length) {
      // console.log("test");
      let array = [];
      questions.forEach((question, index) => {
        array.push(
          <Question
            key={question.id}
            data={question}
            modifyClicked={modifyClicked}
            index={index + 1}
          />
        );
      });
      updateContent(array);
    } else {
      updateContent([]);
    }
  }, [questions]);

  function modifyClicked(target) {
    const popup = document.querySelector("#questionPopup");

    if (
      popup &&
      (popup.style.display === "none" || popup.style.display === "")
    ) {
      updatePopup(
        JSON.parse(target.parentElement.parentElement.getAttribute("data"))
      );

      setTimeout(() => {
        popup.style.display = "flex";
      }, 100);
    }
  }

  function popupApplyClicked(data) {
    function getAnswers() {
      const selectedIndex = document.querySelector("#typeSelect").selectedIndex;
      const type = Hooks.conditionalString(
        document.querySelector("#typeSelect").querySelectorAll("option")[
          selectedIndex
        ].textContent
      );
      if (type === "yes/no") {
        return {
          1: "yes",
          2: "no",
        };
      } else if (type === "tickbox" || type === "dropdownlist") {
        const answers = {};
        document.querySelectorAll(".answerInput").forEach((answer, index) => {
          answers["additionalProp" + index] = answer.value || null;
        });
        return answers;
      } else {
        return {};
      }
    }

    const question = {
      id: parseInt(data.id),
      question: document.querySelector("#popupQuestion").value,
      inspectionSetId: parseInt(data.inspectionSetId),
      answerTypeRefId: parseInt(document.querySelector("#typeSelect").value),
      answerChoice: getAnswers(),
    };

    function thenFunction(response) {
      if (response.ok) {
        document.querySelector(".selectedRow").click();
        document.querySelector("#questionPopup").style.display = "none";
      }
    }

    Hooks.sendData(
      question,
      `${API_ENV}/inspectionQuestion/${data.id}`,
      "PUT",
      thenFunction
    );
  }

  return (
    <div id="questionsContainer">
      <QuestionPopup data={popupData} applyClicked={popupApplyClicked} />

      <div id="questionsHeader">
        <h4>Set Questions</h4>
      </div>

      {content.length > 0 ? (
        <div id="questionsContent">{content}</div>
      ) : (
        <p>
          No questions for this inspection set. Click "Add Question" to create
          some questions.
        </p>
      )}
    </div>
  );
}
